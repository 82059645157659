import { postMethodCall } from '../index';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';
import { decrypt } from 'utils/crypto';

export const getMicroFlowDependencyList = (path, widgetData, count) => {
    const { currentPageNumber:pageNumber, recordsPerPage, sort, searchString } = widgetData;
    const variables = {
        pagination:{
            pageNumber: pageNumber,
            limit: recordsPerPage
        },
        sort: {
            workflowName: sort
        },
        count: count ? count : false,
        search: searchString
    };
    const reqObj = {
        query: `query ( $pagination: paginationType!,$count:Boolean, $search:String) {
            microFlowDependencyList(pagination: $pagination,count:$count,search:$search) {
            data {
            microflowName,
            microflowId,
            microflowVersion
            version,
            createdBy,
            modifiedTS,
            workflowName,
            stepName,
            sectionName,
            componentName,
            mappedComponentName,
            versions,
            workflows,
            steps,
            sections,
            components,
            mappedComponents,
            sectionId,
            componentId,
            isProcess
            }
            count
        }
        }
        `,
        variables: variables,
        fetchPolicy: 'no-cache',
    };
    return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const getWorkFlowDependencyList = (path, widgetData, count) => {
    const { currentPageNumber:pageNumber, recordsPerPage, sort, searchString } = widgetData;
    const variables = {
        pagination:{
            pageNumber: pageNumber,
            limit: recordsPerPage
        },
        sort: {
            microflowName: sort
        },
        count: count ? count : false,
        search: searchString
    };
    const reqObj = {
        query: `query ($pagination:paginationType, $workflowName:String, $count :Boolean,$search:String) {
            workFlowDependencyList(
             pagination:$pagination,
              workflowName:$workflowName,
              search:$search,
              count: $count
            ) {
              data {
                microflowName,
                microflowId,
                microflowVersion,
                version,
                createdBy,
                modifiedTS,
                workflowName,
                stepName,
                sectionName,
                componentName,
                mappedComponentName,
                versions,
                workflows,
                steps,
                sections,
                components,
                mappedComponents,
                microflows
              }
              count
            }
          }`,
        variables: variables,
        fetchPolicy: 'no-cache',
    };
    return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const fetchConnectorData = (path, status, connectorId) => {
  const variables = {
    "status": status,
    "connectorId": connectorId
  }
  const reqObj = {
    query: `query ( $status:[String], $connectorId:[String]) {
      connectorDetails(
        connectorId:$connectorId,
        status:$status
      ) {
        data {
            connectorId
          name
          providerName
          type
          httpMethod
          description
          version
          createdTS
          modifiedTS
          departments
          teams
          endpoint
          directCall
          enableCache
          numberOfRetries
          timeGap
          validity
          iconUrl
          requestMediation
          responseMediation
          timeout
          status
        }   
      }
    }`,
    variables: variables,
    fetchPolicy: 'no-cache'
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const fetchConnectorVersionHistoryDetails = (path, connectorId) => {
  const variables = {
    "connectorId": connectorId
  }
  const reqObj = {
    query: `query (  $connectorId:String) {
      connectorVersionHistory(
        connectorId:$connectorId,
      ) {
        data {
          version
          modifiedTS
          createdBy
        }   
      }
    }`,
    variables: variables,
    fetchPolicy: 'no-cache'
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const fetchMicroflowVersionHistoryDetails = (path, microflowId) => {
  const variables = {
    "microflowId": microflowId
  }
  const reqObj = {
    query: `query($microflowId:String){
      microflowVersionHistory(
      microflowId:$microflowId
      ){
      data{
      name,
      microflowId,
      version,
      description,
      isAsync,
      modifiedBy,
      createdTS,
      modifiedTS,
      createdBy,
      modifiedBy,
      status,
      assignedTo,
      departments,
      teams,
      modifiedBy,
      steps,
      consumerNotification,
      enablePolling,
      screenBlocking,
      errorMessage,
      initiateMessage,
      successMessage,
      linkedMicroflow,
      excludeResponseMap,
      callbackWebhookUrl,
      saveToDB,
      excludeResponse,
      userNotification,
      popupHeading
      
      }
      }
      }`,
    variables: variables,
    fetchPolicy: 'no-cache'
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const fetchConnectorAudits = (path, connectorId, pageNumber) => {
  const variables = {
    "count": false,
    "connectorId": connectorId,
    "pagination": {
      "pageNumber": pageNumber,
      "limit": 10
    },
  }
  const reqObj = {
    query: `query ( $pagination: paginationType!,$count:Boolean,$connectorId:[String]) {
      connectorAudits (
        pagination: $pagination,
        count:$count,
        connectorId:$connectorId, 
      ) {
        data {
        name
        data
        action
        createdBy
        createdTS
        connectorId
        version
        referenceData
        auditId
        }
        count   
      }
    }`,
    variables: variables,
    fetchPolicy: 'no-cache'
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const fetchMicroflowAudits = (path, microflowId, pageNumber) => {
  const variables = {
    "count": false,
    "connectorId": microflowId,
    "pagination": {
      "pageNumber": pageNumber,
      "limit": 10
    },
  }
  const reqObj = {
    query: `query ( $pagination: paginationType!,$count:Boolean, $microflowId:String,$auditId:[String]) {
      microflowAudits(
        pagination: $pagination,
        count:$count,
        microflowId:$microflowId,
        auditId:$auditId
      ) {
        data {
            action
          microflowId
          auditId
          name
          version
          data
          referenceData
          createdBy
          createdTS
        
    
        
        }
        count   
      }
    }`,
    variables: variables,
    fetchPolicy: 'no-cache'
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const getConnectorDependencyList = (path, widgetData, count) => {
    const { currentPageNumber:pageNumber, recordsPerPage, sort, searchString } = widgetData;
    const variables = {
        pagination:{
            pageNumber: pageNumber,
            limit: recordsPerPage
        },
        sort: {
            connectorName: sort
        },
        count: count ? count : false,
        search: searchString
    };
    const reqObj = {
        query: `query ($pagination:paginationType,$count :Boolean,$search:String) {
            connectorDependencyList(
             pagination:$pagination,
             search:$search
              count: $count
            ) {
              data {
               microflowName,
               microflowVersion,
               connectorId,
               connectorName,
               connectorVersion,
               createdBy,
               modifiedTS,
               microflows,
               microflowVersions,
               connectorVersions
              }
              count
            }
        }`,
        variables: variables,
        fetchPolicy: 'no-cache',
    };
    return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const getWorkFlowDataBasedOnMicroflow = (path, workFlowData, pageNumber, limit, searchString, count) => {
    const { microflowId, version } = workFlowData;
    const variables = {
        pagination:{
            pageNumber: pageNumber || 1,
            limit: limit || 1000
        },
        count: count?true:false,
        microflowId: microflowId,
        version: version,
        search: searchString?searchString:""
    };
    const reqObj = {
        query: `query ( $microflowId:String!, $version: Int,$count:Boolean, $pagination:paginationType,$search:String) {
            microFlowWorkFlowDependencyList(
              microflowId:$microflowId,
              version:$version,
              count:$count,
              pagination:$pagination,
              search:$search
            ) {
              data {
                workflowName,
                flowType,
                isProcess
                stepName,
                triggerPoint,
                version
                stepName
                sectionName
                sectionId
                mappedComponentName
                mappedComponentId
                containerName
                containerId
                type
                value
                key
                componentName
                componentId
                fieldId
                microflowId
                microflowName
                flowId
                microflowVersion
              }
              count
            }
        }`,
        variables: variables,
        fetchPolicy: 'no-cache',
    };
    return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const getMicroflowDetailsBasedOnFlow = (path, microflowData) => {
    const { microflowId, version, workflowName } = microflowData;
    const variables = {
        pagination:{
            pageNumber: 1,
            limit: 1000
        },
        count: false,
        microflowId: microflowId,
        version: version,
        search: workflowName
    };
    const reqObj = {
        query: `query ( $workflowName:String, $microflowId:String,$version:Int) {
            microFlowWorkFlowDependency(
              workflowName:$workflowName,
             microflowId:$microflowId,
             version:$version
            ) {
              data { 
               workflowName,
               createdBy,
               modifiedTS,
               isProcess,
               microflowName,
               sectionName,
               stepName,
               sectionId,
               componentName,
               componentId,
               mappedComponentName,
               mappedComponentId,
               triggerPoint,
               key,
               value,
               type
              }
              count
            }
        }`,
        variables: variables,
        fetchPolicy: 'no-cache',
    };
    return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const getWorkflowDetails = (path, workFlowData) => {
    const { workflowName, isProcess } = workFlowData;
    const variables = {
        pagination:{
            pageNumber: 1,
            limit: 1000
        },
        count: false,
        workflowName: workflowName,
        isProcess: isProcess,
        search: ""
    };
    const reqObj = {
        query: `query ($workflowName:String,$isProcess:Boolean,$search:String) {
            microFlowWorkFlowDependency(workflowName:$workflowName, isProcess:$isProcess, search:$search) {
              data {
               stepName,
               sectionName,
               sectionId,
               componentName,
               componentId,
               mappedComponentName,
               mappedComponentId,
               triggerPoint,
               key,
               value,
               microflowName,
               version
              }
              count
            }
          }
          `,
        variables: variables,
        fetchPolicy: 'no-cache',
    };
    return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const getConnectorList = (path, connectorData) => {
    const { microflowId, version } = connectorData;
    const variables = {
        microflowVersion : version,
        microflowId: microflowId
    }
    const reqObj = {
        query: `query ( $microflowVersion:Int,$microflowId:String) {
            connectorsList(microflowId:$microflowId, microflowVersion:$microflowVersion) {
              data {
                connectorId,
                connectorName,
                connectorVersion
              }
              count
            }
          }
        `,
        variables: variables,
        fetchPolicy: 'no-cache',
    };
    return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const getMicroFlowDependencyBasedOnParams = (path, microflowData) => {
    const { version, microflowId, stepName, workflowName, componentId, sectionId } = microflowData?.value;
    const variables = {
        version : version ?? '',
        microflowId: microflowId ?? '',
        workflowName: workflowName ?? '',
        stepName: stepName ?? '',
        sectionId: sectionId ?? '',
        componentId: componentId ?? ''
    }
    const reqObj = {
        query: `query ( $microflowId:String!, $version: Int,$workflowName:String,$stepName:String, $sectionId:String,$componentId:String) {
            microFlowDependencyBasedOnParams(
              microflowId:$microflowId,version:$version,workflowName:$workflowName,stepName:$stepName,sectionId:$sectionId,componentId:$componentId) {
              data {
                microflowName,
                microflowId,
                version,
                createdBy,
                modifiedTS,
                workflowName,
                stepName,
                sectionName,
                componentName,
                mappedComponentName,
                versions,
                workflows,
                steps,
                sections,
                components,
                mappedComponents,
                sectionId,
                componentId,
                isProcess
              }
              count
            }
          }
        `,
        variables: variables,
        fetchPolicy: 'no-cache',
    };
    return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const getWorkFlowDependencyBasedOnParams = (path, workflowData) => {
    const { microflowId, stepName, workflowName, sectionId, componentId } = workflowData?.value;
    const variables = {
        microflowId: microflowId ?? '',
        workflowName: workflowName ?? '',
        stepName: stepName ?? '',
        componentId: componentId ?? '',
        sectionId : sectionId ?? '',
    }
    const reqObj = {
        query: `query ($workflowName:String, $microflowId:String,$stepName:String,$sectionId:String, $componentId:String) {
            workFlowDependencyBasedOnParams(workflowName:$workflowName,microflowId:$microflowId, stepName:$stepName, sectionId:$sectionId, componentId:$componentId) {
              data {
                microflowName,
                microflowId,
                version,
                createdBy,
                modifiedTS,
                workflowName,
                stepName,
                sectionName,
                componentName,
                mappedComponentName,
                versions,
                workflows,
                steps,
                sections,
                components,
                mappedComponents,
                microflows
              }
              count
            }
        }`,
        variables: variables,
        fetchPolicy: 'no-cache',
    };
    return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const getConnectorDependencyBasedOnParams = (path, connectorData) => {
    const { connectorId, connectorVersion } = connectorData?.value;
    const variables = {
        connectorId: connectorId,
        connectorVersion: connectorVersion,
        microflowId: '' 
    }
    const reqObj = {
        query: `query ( $connectorId:String!,$connectorVersion:Int,$microflowId:String) {
            connectorDependencyBasedOnParams(connectorId:$connectorId,connectorVersion:$connectorVersion,microflowId:$microflowId) {
              data {
                microflowName,
                microflowVersion,
                connectorId,
                connectorName,
                connectorVersion,
                createdBy,
                modifiedTS,
                microflows,
                microflowVersions,
              }
              count
            }
          }
          `,
        variables: variables,
        fetchPolicy: 'no-cache',
    };
    return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const getMicroflowListingData = (path, widgetData, selectedTab, count) => {
    const { currentPageNumber, recordsPerPage, searchString, sort } = widgetData;
    const variables = {
      pagination: {
        pageNumber: currentPageNumber,
        limit: recordsPerPage,
      },
      sort: sort,
      count: count ? count : false,
      search: searchString,
      status: (selectedTab === "Draft")?["Draft","Rework"]:(selectedTab==="Published"?["Published"]:["Pending","Submitted"]),
    };
    const userData = decrypt(localStorage.getItem('_ud'));
    if(selectedTab === "Published"){
      variables["teams"] = {
        departmentId: [userData?.departmentList?.[0]?.id],
        teamId: [userData?.teamList?.[0]?.id]
      }
    } else {
      variables["userIds"] = [userData?.userId];
    }
    const reqObj = {
        query: `query ( $pagination: paginationType!,$count:Boolean, $userIds:[String], $status: [String],$search: String, $sort: sortCriteriaType) {
            microflowConfigs(
              pagination: $pagination,
              count:$count,
              userIds:$userIds,
              status: $status,
              search: $search
              sort: $sort
            ) {
              data {
                microflowId
                name
                version
                isAsync
                description
                status
                modifiedBy
                audits 
                createdTS
                modifiedTS
                assignedTo
                departments
                teams
                steps
                consumerNotification
                userNotification
                popupHeading
                enablePolling
                screenBlocking
                errorMessage
                initiateMessage
                successMessage
                linkedMicroflow
                saveToDB
                saveToDBMap
                excludeResponseMap
                excludeResponse
                callbackWebhookUrl               
              }
              count  
            }
          }
        `,
        variables: variables,
        fetchPolicy: 'no-cache',
    };
    return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const getConnectorListingData = (path, widgetData, selectedTab, count) => {
  const { currentPageNumber, recordsPerPage, searchString, sort } = widgetData;
  const variables = {
    pagination: {
      pageNumber: currentPageNumber,
      limit: recordsPerPage,
    },
    sort: sort,
    count: count ? count : false,
    search: searchString,
    status: (selectedTab === "Draft")?["Draft","Rework"]:(selectedTab==="Published"?["Published"]:["Pending","Submitted"]),
  };
  const userData = decrypt(localStorage.getItem('_ud'));
  if(selectedTab === "Published"){
    variables["teams"] = {
      departmentId: [userData?.departmentList?.[0]?.id],
      teamId: [userData?.teamList?.[0]?.id]
    }
  } else {
    variables["userIds"] = [userData?.userId];
  }
  const reqObj = {
      query: `query ( $pagination: paginationType!,$count:Boolean, $userIds:[String], $status: [String],$search: String, $sort: sortCriteriaType) {
        connector(
            pagination: $pagination,
            count:$count,
            userIds:$userIds,
            status: $status,
            search: $search
            sort: $sort
          ) {
            data {
              connectorId,
              version,
              name,
              description,
              modifiedTS,
              modifiedBy,
              providerName
              httpMethod
              teams
              status
              assignedTo
              createdTS
              createdBy
              departments
              directCall
              enableCache
              requestType
              endpoint
              timeGap
              validity
              timeout
              headers
              parameterMap
              requestMap
              responseMap
              requestMediation
              responseMediation
              iconUrl
              type
            }
            count
          }
        }`,
      variables: variables,
      fetchPolicy: 'no-cache',
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const getProductFields = (path) => {
  const variables = {
    "count": false,
    "pagination": {
        "pageNumber": 1,
        "limit": 1000
    },
    "status": "Published",
    "search": ""
  };
  const reqObj = {
    query:`query ( $pagination: paginationType!,$count:Boolean,$status:[String], $userIds:[String],$search:String) {\r\n  field(\r\n    pagination: $pagination,\r\n    count:$count,\r\n    userIds:$userIds,\r\n    search: $search\r\n    status:$status\r\n   \r\n  ) {\r\n    data {\r\n     name\r\n      regex\r\n      type\r\n version key errorMessage    }\r\n    count   \r\n  }\r\n}`,
    variables:variables,
    fetchPolicy:'no-cache'
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const fetchRules = (path, configIds) => {
  const variables = {
    configIds: configIds
  };
  const reqObj = {
    query:`query ($configIds: [String]!) {\n  rules(configIds: $configIds) {\n    data {\n      configId\n      flowId\n      stepId\n      taskId\n      rule\n    }\n  }\n}`,
    variables:variables,
    fetchPolicy:'no-cache'
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const fetchMicroflowDetails = (path, microflowId, microflowVersion, status) => {
  const variables = {
    status: [status],
    microflowId: microflowId,
    version: microflowVersion
  }
  const reqObj = {
    query: `query ( $microflowId:String, $status: [String], $version:String) {
      microflowConfigsDetails(
        microflowId:$microflowId,
        status: $status,
        version:$version
      ) {
        data {
             name,
            microflowId,
           version,
            description,
            isAsync,
                                    modifiedBy,
                                    createdTS,
                                    modifiedTS,
                                    createdBy,
                                    modifiedBy,
                                    status,
                                    assignedTo,
                                    departments,
                                    teams,
                                    modifiedBy,
                                    steps,
                                    consumerNotification,
                                    enablePolling,
                                    screenBlocking,
                                    errorMessage,
                                    initiateMessage,
                                    successMessage,
                                    linkedMicroflow,
                                    excludeResponseMap,
                                    callbackWebhookUrl,
                                    saveToDB,
                                    excludeResponse,
                                    userNotification,
                                    popupHeading
        
        }
      }
    }`,
    variables: variables,
    fetchPolicy: 'no-cache'
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const fetchNodeDetails = (path, microflowId, microflowVersion, nodeId) => {
  const variables = {
    nodeId: nodeId,
    microflowId: microflowId,
    microflowVersion: microflowVersion,
    allNodes: true
  }
  const reqObj = {
    query: `query ( $microflowId:String, $microflowVersion:String, $nodeId:String,$allNodes:Boolean) {
      microflowConfigsDetails(
        microflowId:$microflowId,
        microflowVersion:$microflowVersion,
        nodeId:$nodeId,
        allNodes:$allNodes
      ) {
        data {
            microflowId
            nodeId
            microflowVersion
            requestMap
            responseMap
            parameterMap
            responseData
        
        }
      }
    }`,
    variables: variables,
    fetchPolicy: 'no-cache'
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const fetchPublishedMicroflows = (path) => {
  const variables = {
    status: ["Published"]
  }
  const reqObj = {
    query: `query ($status: [String]) {
      microflowConfigsDetails(
        status: $status,
      ) {
        data {
             name,
            microflowId,
           version,
            description,
            isAsync,
            modifiedBy,
            createdTS,
            modifiedTS,
            createdBy,
            modifiedBy,
            status,
            assignedTo,
            departments,
            teams,
            modifiedBy,
            steps,
            consumerNotification,
            enablePolling,
            screenBlocking,
            errorMessage,
            initiateMessage,
            successMessage,
            linkedMicroflow,
            excludeResponseMap,
            callbackWebhookUrl,
            excludeResponse,
            userNotification,
            popupHeading
        
        }
      }
    }`,
    variables: variables,
    fetchPolicy: 'no-cache'
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const fetchMicroflowResponseMap = (path, microflowId) => {
  const variables = {
    microflowId: microflowId,
    allNodes: true,
    parameter: "responseMap"
  }
  const reqObj = {
    query: `query ( $microflowId:String, $microflowVersion:String, $nodeId:String,$allNodes:Boolean, $parameter:String) {
      microflowConfigsDetails(
        microflowId:$microflowId,
        microflowVersion:$microflowVersion,
        nodeId:$nodeId,
        allNodes:$allNodes,
        parameter:$parameter
      ) {
        data {
            responseData
        }
      }
    }`,
    variables: variables,
    fetchPolicy: 'no-cache'
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}

export const fetchPublishedConnectors = (path) => {
  const variables = {
    status: "Published",
  }
  const reqObj = {
    query: `query ( $status:[String]) {
      connectorDetails(
        status:$status
      ) {
        data {
           connectorId
          name
          providerName
          type
          httpMethod
          description
          version
          createdTS
          modifiedTS
          departments
          teams
          endpoint
          directCall
          enableCache
          numberOfRetries
          timeGap
          validity
          iconUrl
          requestMediation
          responseMediation
          timeout
          status
        }   
      }
    }`,
    variables: variables,
    fetchPolicy: 'no-cache'
  };
  return postMethodCall(ADMIN_API_AXIOS,path,reqObj);
}