import Dashboard from './components/dashboard';
import MicroflowListing from './components/listing';
import { useState } from 'react';
import { TABS_LIST } from 'config';
import './style.scss';
import { MicroflowSetup } from './components/microflow-setup';
import { MicroflowContext } from 'providers';

function MicroflowPage(){
    const [ showMicroflowDetails, setShowMicroflowDetails ] = useState(false);
    const [ selectedMicroflowDetail, setSelectedMicroflowDetail ] = useState({});
    const [ selectedTab, setSelectedTab ] = useState(TABS_LIST[0]);
    const [ showDashboard, setShowDashboard ] = useState(true);
    
    return (
        <MicroflowContext.Provider value={{setShowMicroflowDetails,selectedMicroflowDetail, setSelectedMicroflowDetail, setShowDashboard}}>
            { showDashboard && <Dashboard/> }
            { !showDashboard && !showMicroflowDetails && <MicroflowListing selectedTab={selectedTab} setSelectedTab={setSelectedTab}/> }
            {/* <Canvas/> */}
            { !showDashboard && showMicroflowDetails && <MicroflowSetup handleNavigateToListing={()=>{setShowMicroflowDetails(false)}}/> }
        </MicroflowContext.Provider>
    )
}

export default MicroflowPage;