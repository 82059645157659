import './style.scss';
import Select from 'react-select';
import { isEmpty, isNumber } from 'lodash';

export default function SelectMenu(props){

    const { value, options, id, onChange, label, mandatory, error, errorMessage, placeholder, onSelectionChange, isMulti, isDisabled } = props;

    const getOptions = (e) => {
        if((e || isNumber(e)) && !isEmpty(e))  return { label: e, value: e };
        else return '';
    };

    const getSelectedOptions = (e) => e?.map(name => ({ label: name, value: name }));

    const CustomStyle = {
        option: (base, state) => ({
            ...base,
            color: '#000000',
            background: '#FFFFFF',
            padding: '2px',
            fontSize: '12px',
            fontWeight: '500',
            "&:hover": {
                cursor: 'pointer',
                background: '#F6ECFF'
            }
        })
    }

    const handleEventChange = (e) => {
        // const a = isMulti ? map(e, el => el?.value) : e?.value;
        onChange && onChange(e?.value);
        onSelectionChange && onSelectionChange(e);
    }

    return (
        <div className="selectmenu">
            { label && <label>{label}{label && mandatory && <sup>*</sup>}</label> }
            <Select classNamePrefix="select-child" components={{ IndicatorSeparator:() => null }} styles={CustomStyle} id={id} value={isMulti?getSelectedOptions(value):getOptions(value)}  options={options} maxMenuHeight={250} isSearchable={true} isMulti={isMulti} isDisabled={isDisabled } onChange={(e) => handleEventChange(e)}  placeholder={placeholder ? placeholder : 'Select'}/>
            { error && <span className="selectmenu__error">{errorMessage}</span>}
        </div>
    )
}