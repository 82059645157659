import './style.scss';
import { Images } from 'assets/Images';
import { map } from 'lodash';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getConnectorList } from 'actions/graphql';
import * as config from 'config';
import { truncate } from 'utils';
import { Tooltip } from 'components/generic';

export default function Popup(props){
    const { header, data, overviewDetails, closePopUp, viewSelectedRow, viewWorkFlowDetails, enableBackAction, navigatePreviousPage } = props;
    const [ showConnectorList, setShowConnectorList ] = useState(false);
    const [ hoverData, setHoverData ] = useState(null);

    const { data: connectorData } = useQuery(['FETCH_CONNECTOR_LIST'], ()=> getConnectorList('/v2/graphql', overviewDetails), {
        refetchOnWindowFocus: false,
    })
    
    return (
        <div className="microflow-popup">
            <header>
                <div>
                    { enableBackAction && <img src={Images.backicon} alt="" onClick={navigatePreviousPage} className="microflow-popup__backicon"/>}
                    <span className="microflow-popup__header">{config?.[overviewDetails?.header]?.label}</span>
                </div>
                <span><img src={Images.close} alt="" onClick={closePopUp}/></span>
            </header>
            <div className="microflow-popup__main">
                <div className="microflow-popup__details">
                    <div className="microflow-popup__details--section">
                        { config?.[overviewDetails?.header]?.value && map(config?.[overviewDetails?.header]?.value, (el,i)=>
                            <div className="microflow-popup__details--group" key={i}>
                                <div className="microflow-popup__details--key">{el?.label} :</div>
                                <div className="microflow-popup__details--value">
                                    { el?.key && overviewDetails?.[el?.key]}
                                    { el?.type === 'isProcess' && (overviewDetails?.[el?.type]?'Process':'Product')}
                                </div>
                            </div>
                        )}
                        { overviewDetails?.header==='MICROFLOW_DETAILS_POPUP' && <div className="microflow-popup__details--group">
                                <div className="microflow-popup__details--key">No. of Connectors :</div>
                                <div className="microflow-popup__details--value">{connectorData?.data?.connectorsList?.data?.length}</div>
                        </div> }
                    </div>
                    { overviewDetails?.header==='MICROFLOW_DETAILS_POPUP' && <span className="microflow-popup__details--view" onClick={viewWorkFlowDetails}>Click to view workflow details</span>}
                </div>
                <div className="microflow-popup__section">
                    <div className="microflow-popup__tablesection">
                            <div className="microflow-popup__tableheader">
                                {map(header,(el,ind)=>
                                    <div className="microflow-popup__tableheader--col" key={ind}>{el?.label}</div>
                                )}
                            </div>
                            {map(data,(el, ind)=>
                                <div className="microflow-popup__tablebody" key={ind} onClick={()=>viewSelectedRow(el)} style={{cursor:overviewDetails?.header==='MICROFLOW_WORKFLOW_POPUP'?'pointer':'',pointerEvents:overviewDetails?.header==='MICROFLOW_WORKFLOW_POPUP'?'auto':''}}>
                                    {map(header,(headerkey, i)=>
                                        <div className="microflow-popup__tablebody--content" key={i} onMouseEnter={()=>headerkey?.key&&setHoverData(headerkey?.key+ind)} onMouseLeave={()=>headerkey?.key&&setHoverData(null)}>
                                                <>
                                                    { headerkey?.key && <span className="microflow-popup__tablebody--col">{(truncate(el[headerkey?.key],18))??'-'}</span> }
                                                    { headerkey?.type==='number' && <span className="microflow-popup__listing">{ind+1}.</span> }
                                                    { headerkey?.type==='isProcess' && <span className="microflow-popup__tablebody--col">{el[headerkey?.type]?'Process':'Product'}</span>}
                                                    { hoverData===(headerkey?.key+ind) && el[headerkey?.key]?.length>18 && <span className="microflow-popup__tooltip"><Tooltip value={el[headerkey?.key]}/></span> }
                                                </>
                                        </div>
                                    )}

                                </div>
                            )}
                    </div>
                    { overviewDetails?.header==='MICROFLOW_DETAILS_POPUP' && <div className="microflow-popup__btn" onClick={()=>setShowConnectorList(!showConnectorList)}>
                        <button>View all connectors</button>
                        <span className="microflow-popup__btn--img"><img src={Images.listingdropdown} alt=""/></span>
                        { showConnectorList && <div className="microflow-popup__dropdown">
                            {map(connectorData?.data?.connectorsList?.data, el=>{
                                <span>{el?.connectorName}({el?.connectorVersion})</span>
                            })}
                        </div> }
                    </div> }
                </div>
            </div>
        </div>
    )
}