import { Link } from 'react-router-dom';
import './style.scss';
import { Images } from 'assets/Images';
import { MODULES } from 'config';
import { map } from 'lodash';
import React from 'react';
import { decrypt } from 'utils/crypto';

export default function LandingPage()  {

    const attributes = decrypt(localStorage.getItem('_ud'))?.attributes;

    return (
        <div className="landingpage">
            <header>
                <img src={Images.menubar} alt=""/>
                <span>My workspace</span>
            </header>
            <div className="landingpage__body">
                {map(MODULES, (module,ind)=> 
                    <React.Fragment key={ind}>
                        { attributes?.modules?.includes(module.role) && <Link to={{ pathname: module.path }} className="landingpage__link" key={ind}>
                            <div className="landingpage__container">
                                <span className="landingpage__container--img"><img src={module.icon} alt=""/></span>
                                <span className="landingpage__container--text">{module.name}</span>
                                <div className="landingpage__container--description">{module.description}</div>
                            </div>
                        </Link> }
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}