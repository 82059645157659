import "./style.scss";
import { Images } from "assets/Images";
import { Button, Checkbox, SelectMenu, TabsListing } from "components/generic";
import { ACTION_TABS } from "../../config";
import { useState } from "react";

export function ActionSettings({ closeOverlay }){

    const [ selectedTab, setSelectedTab ] = useState(ACTION_TABS[0]);

    const handleSaveData = () => {
    
    }

    return (
        <div className="action-settings">
            <header>
                <span className="action-settings__text">Settings for “Approve Deviation”</span>
                <div className="action-settings__save">
                    { <><Button label="SAVE" secondary={true} handleClick={handleSaveData}></Button>
                    <span className="action-settings__seperator"></span> </>}
                    <span><img src={Images.close} alt="" onClick={closeOverlay}/></span>
                </div>
            </header>
            <div className="action-settings__main">
                <TabsListing tabList={ACTION_TABS} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
                { selectedTab === ACTION_TABS[0] && <>
                    <div className="action-settings__row">
                        <div className="action-settings__col">
                            <div className="action-settings__info">
                                <span><img src={Images.info} alt=""/></span>
                                <span>Configure notifications when this action occurs. This notifications can be used to inform users or other systems about the action taken.</span>
                            </div>
                            <div className="action-settings__header">
                                <span><img src={Images.documentIcon} alt=""/></span>
                                <span>In-App notifications</span>
                            </div>
                            <div className="action-settings__section">
                                <div className="action-settings__group">
                                    <Checkbox label="Send to the user who performs this action"/>
                                    <span className="action-settings__description">Send notification to user’s browser in case user has registered the browser to receive notifications</span>
                                </div>
                                <div className="action-settings__select">
                                    <SelectMenu label="Template" placeholder="Select a template"/>
                                </div>
                            </div>
                            <div className="action-settings__header">
                                <span><img src={Images.documentIcon} alt=""/></span>
                                <span>Web notifications</span>
                            </div>
                            <div className="action-settings__section">
                                <div className="action-settings__group">
                                    <Checkbox label="Send to all users who follow this application"/>
                                    <span className="action-settings__description">Send notification within user portal to the user using in-built notification page</span>
                                </div>
                                <div className="action-settings__select">
                                    <SelectMenu label="Template" placeholder="Select a template"/>
                                </div>
                            </div>
                            <div className="action-settings__header">
                                <span><img src={Images.documentIcon} alt=""/></span>
                                <span>Email notifications</span>
                            </div>
                            <div className="action-settings__section">
                                <div className="action-settings__group">
                                    <Checkbox label="Send to customer associated with the case"/>
                                    <span className="action-settings__description">Send notification via email to the intended recepient. The configuration of the mobile will be as per the flow</span>
                                </div>
                                <div className="action-settings__select">
                                    <SelectMenu label="Template" placeholder="Select a template"/>
                                </div>
                            </div>
                            <div className="action-settings__header">
                                <span><img src={Images.documentIcon} alt=""/></span>
                                <span>SMS notifications</span>
                            </div>
                            <div className="action-settings__section">
                                <div className="action-settings__group">
                                    <Checkbox label="Send to the user who performs this action"/>
                                    <span className="action-settings__description">Send SMS to notify the customer’s registered mobile. The configuration of the mobile will be as per the flow</span>
                                </div>
                                <div className="action-settings__select">
                                    <SelectMenu label="Template" placeholder="Select a template"/>
                                </div>
                            </div>
                        </div>
                        <div className="action-settings__col">
                            <span><img src={Images.actionconfig} alt=""/></span>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    )
}