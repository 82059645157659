import { MicroflowAction } from '../model/microflow-action.model.js';
import { MicroflowActionTypes } from '../enum/microflow-action.enum.js';

export const MicroflowActions = [
  new MicroflowAction("Goto", "Goto action", MicroflowActionTypes.GOTO, 
  [
    {
      key: 'Connector/Action',
      value: '',
      static: false
    }
  ]),
  new MicroflowAction("Rule", "Decision rule", MicroflowActionTypes.RULE),
  new MicroflowAction("Preview File", "Show file preview", MicroflowActionTypes.PREVIEW_FILE,
    [
      {
        key: 'file',
        value: '',
        static: false
      }
    ],
    []),
    new MicroflowAction("Additional Fields", "Additional fields", MicroflowActionTypes.ADDITIONAL_ATTRIBUTES,
    [],
    []),
    new MicroflowAction("Get Value", "Get value of a field key", MicroflowActionTypes.GET_VALUE,
    [
      {
        key: 'taskId',
        value: '',
        static: false
      },
      {
        key: 'fieldKey',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: '<fieldKey>',
        static: false
      }
    ]),
  new MicroflowAction("Generate Pdf", "Generate Pdf using template", MicroflowActionTypes.GENERATE_PDF,
    [
      {
        key: 'application_id',
        value: '',
        static: false
      },
      {
        key: 'category',
        value: '',
        static: false
      },
      {
        key: 'subCategory',
        value: '',
        static: false
      },
      {
        key: 'preview',
        value: '',
        static: false
      },
      {
        key: 'formulaForRefId',
        value: '',
        static: false
      },
      {
        key: 'templateName',
        value: '',
        static: false
      },
      {
        key: 'templateId',
        value: '',
        static: false
      },
      {
        key: 'version',
        value: '',
        static: false
      },
      {
        key: 'templateType',
        value: '',
        static: false
      },
      {
        key: 'taskId',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'file',
        static: false
      }
    ]),
  new MicroflowAction("Generate Pdf V4", "Generate Pdf using template", MicroflowActionTypes.GENERATE_PDF_V4,
    [
      {
        key: 'application_id',
        value: '',
        static: false
      },
      {
        key: 'type',
        value: '',
        static: false
      },
      {
        key: 'preview',
        value: '',
        static: false
      },
      {
        key: 'templateName',
        value: '',
        static: false
      },
      {
        key: 'templateId',
        value: '',
        static: false
      },
      {
        key: 'version',
        value: '',
        static: false
      },
      {
        key: 'templateType',
        value: '',
        static: false
      },
      {
        key: 'taskId',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'file',
        static: false
      }
    ]),
  new MicroflowAction("Upload-Base64", "Uploads base64", MicroflowActionTypes.UPLOAD_BASE64,
    [
      {
        key: 'application_id',
        value: '',
        static: false
      },
      {
        key: 'taskId',
        value: '',
        static: false
      },
      {
        key: 'type',
        value: '',
        static: false
      },
      {
        key: 'files',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'uploadedDocuments',
        static: false
      },
      {
        key: '',
        value: 'latestDocuments',
        static: false
      }
    ]),
  new MicroflowAction("Download-Base64", "Downloads base64", MicroflowActionTypes.DOWNLOAD_BASE64,
    [
      {
        key: 'application_id',
        value: '',
        static: false
      },
      {
        key: 'taskId',
        value: '',
        static: false
      },
      {
        key: 'type',
        value: '',
        static: false
      },
      {
        key: 'filename',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'downloadedDocument',
        static: false
      }
    ]),
  new MicroflowAction("Fetch Applications", "Fetch Applications", MicroflowActionTypes.FETCH_APPLICATIONS,
    [
      {
        key: 'productCode',
        value: '',
        static: false
      },
      {
        key: 'applicantType',
        value: '',
        static: false
      },
      {
        key: 'applicationStage',
        value: '',
        static: false
      },
      {
        key: 'project',
        value: '',
        static: false
      },
      {
        key: 'pagination',
        value: '',
        static: false
      },
      {
        key: 'applicationStatus',
        value: '',
        static: false
      },
      {
        key: 'filter',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'applications',
        static: false
      },
      {
        key: '',
        value: 'applicationIds',
        static: false
      }
    ]),
  new MicroflowAction("Generate Report", "Generate Report", MicroflowActionTypes.GENERATE_REPORT,
    [
      {
        key: 'applications',
        value: '',
        static: false
      },
      {
        key: 'columns',
        value: '',
        static: false
      },
      {
        key: 'fileName',
        value: '',
        static: false
      },
      {
        key: 'category',
        value: '',
        static: false
      },
      {
        key: 'subCategory',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'file',
        static: false
      }
    ]),
  new MicroflowAction("Send Mail", "Send Mail", MicroflowActionTypes.SEND_MAIL,
    [{
      key: 'fileDetails',
      value: '',
      static: false
    },
    {
      key: 'template_name',
      value: '',
      static: false
    },
    {
      key: 'emailIds',
      value: '',
      static: false
    }
    ],
    [
      {
        key: '',
        value: 'message',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      }
    ]),
  new MicroflowAction("Update Applications", "Update Applications", MicroflowActionTypes.UPDATE_APPLICATIONS,
    [
      {
        key: 'applicationIds',
        value: '',
        static: false
      },
      {
        key: 'update',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'message',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      }
    ]),
  new MicroflowAction("Lookup", "Execute Lookup", MicroflowActionTypes.ACTION_LOOKUP),
  new MicroflowAction("Import Cases", "Import Cases microflow", MicroflowActionTypes.IMPORT_CASES_EXCEL, [
    {
      key: 'targetProduct',
      value: '',
      static: false
    }, {
      key: 'inputCategory',
      value: '',
      static: true
    },
    {
      key: 'inputSubCategory',
      value: '',
      static: true
    },
    {
      key: 'reportCategory',
      value: '',
      static: true
    },
    {
      key: 'reportSubCategory',
      value: '',
      static: true
    }
  ], [{
    key: '',
    value: 'recordsSubmitted',
    static: false
  }, {
    key: '',
    value: 'recordsProcessed',
    static: false
  }, {
    key: '',
    value: 'recordsImported',
    static: false
  }, {
    key: '',
    value: 'recordsFailed',
    static: false
  }]),
  new MicroflowAction("Import Dataset", "Import dataset microflow", MicroflowActionTypes.IMPORT_DATASET_EXCEL, [
    {
      key: 'targetProduct',
      value: '',
      static: false
    }, {
      key: 'targetEntity',
      value: '',
      static: false
    }, {
      key: 'inputCategory',
      value: '',
      static: true
    },
    {
      key: 'inputSubCategory',
      value: '',
      static: true
    },
    {
      key: 'reportCategory',
      value: '',
      static: true
    },
    {
      key: 'reportSubCategory',
      value: '',
      static: true
    }
  ], [{
    key: '',
    value: 'importResults',
    static: false
  }]),
  new MicroflowAction("Download Flow Data Template", "Download Flow Data Template", MicroflowActionTypes.DOWNLOAD_FLOW_DATA_TEMPLATE,
    [
      {
        key: 'productCode',
        value: '',
        static: false
      }
    ],
    [{
      key: '',
      value: 'fileBuffer',
      static: false
    }, {
      key: '',
      value: 'fileName',
      static: false
    }, {
      key: '',
      value: 'fileType',
      static: false
    }]),
  new MicroflowAction("Fetch Tasks", "Fetch Tasks", MicroflowActionTypes.FETCH_TASKS,
    [
      {
        key: 'applicationIds',
        value: '',
        static: false
      },
      {
        key: 'productCode',
        value: '',
        static: false
      },
      {
        key: 'stageName',
        value: '',
        static: false
      },
      {
        key: 'taskName',
        value: '',
        static: false
      },
      {
        key: 'taskStatus',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'taskIds',
        static: false
      },
      {
        key: '',
        value: 'task',
        static: false
      }
    ]),
  new MicroflowAction("Mark As Complete", "Mark As Complete", MicroflowActionTypes.MARK_AS_COMPLETE,
    [
      {
        key: 'taskIds',
        value: '',
        static: false
      }
    ],
    []),
  new MicroflowAction("Get application details ", "Get application details", MicroflowActionTypes.GET_APPLICATION_DETAILS,
    [
      {
        key: 'application_id',
        value: '',
        static: false
      },
      {
        key: 'productCode',
        value: '',
        static: false
      },
      {
        key: 'keyPrefix',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'applicantData',
        static: false
      },
      {
        key: '',
        value: 'status',
        static: false
      }
    ]),
  new MicroflowAction("Create application", "Create application", MicroflowActionTypes.CREATE_APPLICATION,
    [
      {
        key: 'applicantData',
        value: '',
        static: false
      },
      {
        key: 'applicantType',
        value: '',
        static: false
      },
      {
        key: 'assignedTeam',
        value: '',
        static: false
      },
      {
        key: 'assignedTo',
        value: '',
        static: false
      },
      {
        key: 'productCode',
        value: '',
        static: false
      },
      {
        key: 'outputField',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'new_application_id',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      }
    ]),
  new MicroflowAction("Create application V4", "Create application", MicroflowActionTypes.CREATE_APPLICATION_V4,
    [
      {
        key: 'applicantData',
        value: '',
        static: false
      },
      {
        key: 'applicantType',
        value: '',
        static: false
      },
      {
        key: 'productCode',
        value: '',
        static: false
      },
      {
        key: 'outputField',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'new_application_id',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      }
    ]),
  new MicroflowAction("Complete Step", "Complete Step", MicroflowActionTypes.COMPLETE_STEP,
    [
      {
        key: 'application_id',
        value: '',
        static: false
      },
      {
        key: 'task_id',
        value: '',
        static: false
      },
      {
        key: 'task_name',
        value: '',
        static: false
      },
      {
        key: 'stage_name',
        value: '',
        static: false
      },
      {
        key: 'superUser',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'applicationDetails',
        static: false
      },
      {
        key: '',
        value: 'isStepCompleted',
        static: false
      },
      {
        key: '',
        value: 'taskValidations',
        static: false
      },
      {
        key: '',
        value: 'approvalChecklist',
        static: false
      },
      {
        key: '',
        value: 'message',
        static: false
      },
      {
        key: '',
        value: 'error',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      }
    ]),
  new MicroflowAction("Application Cancel", "Application Cancel", MicroflowActionTypes.APPLICATION_CANCEL,
    [
      {
        key: 'application_id',
        value: '',
        static: false
      },
      {
        key: 'task_id',
        value: '',
        static: false
      },
      {
        key: 'superUser',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'isApplicationCancelled',
        static: false
      },
      {
        key: '',
        value: 'error',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      }
    ]),
  new MicroflowAction("Generate OTP", "Generate OTP", MicroflowActionTypes.GENERATE_OTP,
    [
      {
        key: 'numberOfChars',
        value: 'numberOfChars',
        static: false
      },
      {
        key: 'maxTimeout',
        value: 'maxTimeout',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'otpSecret',
        static: false
      }
    ]),
  new MicroflowAction("Send OTP", "Send OTP", MicroflowActionTypes.SEND_OTP,
    [
      {
        key: 'otpType',
        value: 'otpType',
        static: false
      },
      {
        key: 'recipient',
        value: 'recipient',
        static: false
      },
      {
        key: 'template',
        value: 'template',
        static: false
      },
      {
        key: 'maxTimeout',
        value: 'maxTimeout',
        static: false
      },
      {
        key: 'template_id',
        value: 'template_id',
        static: false
      },
      {
        key: 'version',
        value: 'version',
        static: false
      }
    ], []),

  new MicroflowAction("Send SMS", "Send SMS", MicroflowActionTypes.SEND_SMS,
    [
      {
        key: 'application_id',
        value: 'application_id',
        static: false
      },
      {
        key: 'mobile',
        value: 'mobile',
        static: false
      },
      {
        key: 'template',
        value: 'template',
        static: false
      },
      {
        key: 'template_id',
        value: 'template_id',
        static: false
      },
      {
        key: 'version',
        value: 'version',
        static: false
      }
    ],
    [
      {
      key: 'message',
      value: 'message',
      static: false
      }
    ]),

  new MicroflowAction("Verify OTP", "Verify OTP", MicroflowActionTypes.VERIFY_OTP,
    [
      {
        key: 'otp',
        value: 'otp',
        static: false
      },
      {
        key: 'otpSecret',
        value: 'otpSecret',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'isValid',
        static: false
      },
      {
        key: '',
        value: 'otpSecret',
        static: false
      }
    ]),
  new MicroflowAction("Merge Document", "Merge Multiple Documents to one", MicroflowActionTypes.MERGE_DOCUMENT,
    [
      {
        key: 'application_id',
        value: '',
        static: false
      },
      {
        key: 'sourceFiles',
        value: '',
        static: false
      },
      {
        key: 'category',
        value: '',
        static: false
      },
      {
        key: 'subCategory',
        value: '',
        static: false
      },
    ],
    [
      {
        key: '',
        value: 'file',
        static: false
      }
    ]),
  new MicroflowAction("Merge Document V4", "Merge Multiple Documents to one", MicroflowActionTypes.MERGE_DOCUMENT_V4,
    [
      {
        key: 'application_id',
        value: '',
        static: false
      },
      {
        key: 'taskId',
        value: '',
        static: false
      },
      {
        key: 'sourceFiles',
        value: '',
        static: false
      },
      {
        key: 'type',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'file',
        static: false
      }
    ]),
  new MicroflowAction("Document Copying", "Document Copying to specific category", MicroflowActionTypes.DOCUMENT_COPY,
    [
      {
        key: 'application_id',
        value: '',
        static: false
      },
      {
        key: 'sourceCategory',
        value: '',
        static: false
      },
      {
        key: 'targetCategory',
        value: '',
        static: false
      },
      {
        key: 'sourceSubCategory',
        value: '',
        static: false
      },
    ],
    [
      {
        key: '',
        value: 'file',
        static: false
      }
    ]),
  new MicroflowAction("Fetch This Form Data", "fetch current form data", MicroflowActionTypes.AGGREGATE_FIELDS,
    [
      {
        key: 'form_fields',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'data',
        static: false
      },
    ]),
  new MicroflowAction("Merge Applicant Data", "Merge Applicant Data", MicroflowActionTypes.MERGE_DATA,
    [
      {
        key: 'fields',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'data',
        static: false
      },
    ]),
  new MicroflowAction("Dynamic Resume URL Generator", "Dynamic Resume URL Generator", MicroflowActionTypes.DYNAMIC_RESUME_URL_GENERATOR,
    [
      {
        key: 'dynamicAppId',
        value: '',
        static: false
      },
      {
        key: 'productCode',
        value: '',
        static: false
      },
      {
        key: 'mobileField',
        value: '',
        static: true
      },
      {
        key: 'emailField',
        value: '',
        static: true
      },
      {
        key: 'enableExpiry',
        value: 'true',
        static: true
      },
      {
        key: 'expiryInHours',
        value: '',
        static: true
      },
    ],
    [
      {
        key: '',
        value: 'dynamicURL',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: 'message',
        static: false
      }
    ]),
  new MicroflowAction("UAM Add User", "UAM Add User", MicroflowActionTypes.UAM_ADD_USER,
    // username, firstName, lastName, email, roles, groups, mobile, password
    [
      {
        key: 'username',
        value: '',
        static: false
      },
      {
        key: 'firstName',
        value: '',
        static: false
      },
      {
        key: 'lastName',
        value: '',
        static: false
      },
      {
        key: 'email',
        value: '',
        static: false
      },
      {
        key: 'roles',
        value: '',
        static: false
      },
      {
        key: 'groups',
        value: '',
        static: false
      },
      {
        key: 'mobile',
        value: '',
        static: false
      },
      {
        key: 'password',
        value: '',
        static: false
      },
      {
        key: 'applications',
        value: 'CUSTOMER_PORTAL',
        static: true
      },
      {
        key: 'USER_TYPE',
        value: 'CUSTOMER',
        static: true
      }
    ],
    [
      {
        key: '',
        value: 'userId',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: 'message',
        static: false
      }
    ]),
  new MicroflowAction("Name Match", "Name Match", MicroflowActionTypes.NAME_MATCH,
    [
      {
        key: 'inputName',
        value: '',
        static: false
      },
      {
        key: 'matchName',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'matchPercentage',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: 'message',
        static: false
      }
    ]),
  new MicroflowAction("Address Match", "Address Match", MicroflowActionTypes.ADDRESS_MATCH,
    [
      {
        key: 'inputAddress',
        value: '',
        static: false
      },
      {
        key: 'matchAddress',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'matchPercentage',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: 'message',
        static: false
      }
    ]),
  new MicroflowAction("UAM Get Groups By Name", "UAM Get Groups By Name", MicroflowActionTypes.UAM_GET_GROUPS_BY_NAME,
    [
      {
        key: 'name',
        value: '',
        static: true
      }
    ],
    [
      {
        key: '',
        value: 'groupIds',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: 'message',
        static: false
      }
    ]),
  new MicroflowAction("UAM Get User Details", "UAM Get User Details", MicroflowActionTypes.UAM_GET_USER_DETAILS,
    [
      {
        key: 'userName',
        value: '',
        static: false
      },
      {
        key: 'userId',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'teams',
        static: false
      },
      {
        key: '',
        value: 'userDetails',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: 'message',
        static: false
      }
    ]),
  new MicroflowAction("Bulk Application Conversion from one product to another", "Bulk Application Conversion from one product to another", MicroflowActionTypes.BULK_CONVERT_APPN_FROM_PRODUCT_TO_PRODUCT,
    [
      {
        key: 'productCodeFrom',
        value: '',
        static: false
      },
      {
        key: 'productCodeTo',
        value: '',
        static: false
      },
      {
        key: 'appnSelectionKey',
        value: '',
        static: false
      },
      {
        key: 'appnSelectionValue',
        value: '',
        static: false
      },
      {
        key: 'assignedTo',
        value: '',
        static: false
      },
      {
        key: 'assignedTeam',
        value: '',
        static: false
      },
      {
        key: 'createChildApplications',
        value: '',
        static: false
      },
      {
        key: 'applicantType',
        value: '',
        static: false
      },
      {
        key: '_customerId',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'count',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: 'message',
        static: false
      }
    ]),
  new MicroflowAction("UAM Update User Details", "UAM Update User Details", MicroflowActionTypes.UAM_UPDATE_USER_DETAILS,
    [
      {
        key: 'userId',
        value: '',
        static: false
      },
      {
        key: 'userName',
        value: '',
        static: false
      },
      {
        key: 'firstName',
        value: '',
        static: false
      },
      {
        key: 'lastName',
        value: '',
        static: false
      },
      {
        key: 'email',
        value: '',
        static: false
      },
      {
        key: 'mobile',
        value: '',
        static: false
      },
      {
        key: 'applications',
        value: 'CUSTOMER_PORTAL',
        static: true
      },
      {
        key: 'USER_TYPE',
        value: 'CUSTOMER',
        static: true
      }
    ],
    [
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: 'message',
        static: false
      }
    ]),
  new MicroflowAction("Fetch Customer Application count", "Fetch Customer Application count", MicroflowActionTypes.FETCH_CUSTOMER_APPLICATION_COUNT,
    [
      {
        key: 'productCode',
        value: '',
        static: false
      },
      {
        key: 'stepName',
        value: '',
        static: false
      },
      {
        key: 'progress',
        value: '',
        static: false
      },
      {
        key: 'applicationStatus',
        value: '',
        static: false
      },
      {
        key: 'customerId',
        value: '',
        static: false
      },
      {
        key: 'email',
        value: '',
        static: false
      },
      {
        key: 'mobile',
        value: '',
        static: false
      },
      {
        key: 'filter',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'count',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: 'message',
        static: false
      }
    ]),
  new MicroflowAction("Fetch customer query count", "Fetch customer query count", MicroflowActionTypes.FETCH_CUSTOMER_QUERIES_COUNT,
    [
      {
        key: 'productCode',
        value: '',
        static: false
      },
      {
        key: 'stepName',
        value: '',
        static: false
      },
      {
        key: 'progress',
        value: '',
        static: false
      },
      {
        key: 'applicationStatus',
        value: '',
        static: false
      },
      {
        key: 'customerId',
        value: '',
        static: false
      },
      {
        key: 'email',
        value: '',
        static: false
      },
      {
        key: 'mobile',
        value: '',
        static: false
      },
      {
        key: 'filter',
        value: '',
        static: false
      }
    ],
    [
      {
        key: '',
        value: 'count',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: 'message',
        static: false
      }
    ]),
  new MicroflowAction("Reject application", "Reject application", MicroflowActionTypes.REJECT_APPLICATION,
    [
      {
        key: 'application_id',
        value: '',
        static: false
      },
      {
        key: '_rejectCode',
        value: '',
        static: false
      },
      {
        key: '_rejectReason',
        value: '',
        static: false
      }
    ],
    []),
  new MicroflowAction("Fetch Files", "Fetch Files", MicroflowActionTypes.FETCH_FILES,
    [
      {
        key: "application_id",
        value: "",
        static: false,
      },
      {
        key: "type",
        value: "",
        static: false,
      },
    ],
    [
      {
        key: "",
        value: "files",
        static: false,
      },
      {
        key: "",
        value: "fileName",
        static: false,
      },
    ]
  ),
  new MicroflowAction("Save to DB", "Save to DB", MicroflowActionTypes.SAVE_TO_DB,
    [
      {
        key: "application_id",
        value: "",
        static: false,
      },
      {
        key: "fields",
        value: "",
        static: false,
      }
    ],
    [
      {
        key: "",
        value: "success",
        static: false,
      }
    ]
  ),
  new MicroflowAction("Populate co-applicant data into primary", "Populate co-applicant data into primary", MicroflowActionTypes.POPULATE_COAPPLICANT_DATA_TO_PRIMARY,
    [
      {
        key: "application_id",
        value: "",
        static: false,
      },
      {
        key: "fields",
        value: "",
        static: false,
      }
    ],
    [
      {
        key: "",
        value: "count",
        static: false,
      }
    ]
  ),

  new MicroflowAction("Populate Co-Applicant data", "Populate Co-Applicant data into primary v2", MicroflowActionTypes.POPULATE_COAPPLICANT_DATA,
  [
    {
      key: "filterCoApplicant",
      value: "",
      static: true
    },
    {
      key: "fieldsToBeCopied",
      value: "",
      static: true
    },
  ],
  [
    {
      key: "",
      value: "Message",
      static: false
    },
    {
      key: "",
      value: "Success",
      static: false
    } 
  ]
),

new MicroflowAction("Get aplcnts. data in multidata", "Get aplcnts. data in multidata", MicroflowActionTypes.POPULATE_COAPPLICANT_DATA_IN_MULTIDATA,
[
  {
    key: "filterCoApplicant",
    value: "",
    static: true
  },
  {
    key: "fieldsToBeCopied",
    value: "",
    static: true
  },
  {
    key: "multiDataRefKey",
    value: "multiDataRefKey",
    static: true,
  },
  {
    key: 'templateId',
    value: 'templateId',
    static: true
  },
  {
    key: 'incremental',
    value: '',
    static: true,
    required: true,
  },
],
[
  {
    key: "",
    value: "Message",
    static: false
  },
  {
    key: "",
    value: "Success",
    static: false
  },
  {
    key: "",
    value: "multiDataKey",
    static: false
  }  
]
),

  new MicroflowAction("Set Date", "Set Date", MicroflowActionTypes.SET_DATE,
    [
      {
        key: "application_id",
        value: "",
        static: false,
      },
      {
        key: "field",
        value: "",
        static: false,
      }
    ],
    [
      {
        key: "",
        value: "<fieldKey>",
        static: false,
      },
      {
        key: "",
        value: "success",
        static: false,
      }
    ]
  ),

  new MicroflowAction("Epoch to UTC Converter", "Epoch To UTC Converter", MicroflowActionTypes.EPOCH_TO_UTC,
    [
      {
        key: "application_id",
        value: "",
        static: false,
      },
      {
        key: "field1",
        value: "",
        static: false,
      },
      {
        key: "field2",
        value: "",
        static: false,
      },
      {
        key: "field3",
        value: "",
        static: false,
      },
      {
        key: "field4",
        value: "",
        static: false,
      },
      {
        key: "field5",
        value: "",
        static: false,
      }
    ],
    [
      {
        key: "",
        value: "field1",
        static: false,
      },
      {
        key: "",
        value: "field2",
        static: false,
      },
      {
        key: "",
        value: "field3",
        static: false,
      },
      {
        key: "",
        value: "field4",
        static: false,
      },
      {
        key: "",
        value: "field5",
        static: false,
      }
    ]
  ),

  new MicroflowAction("COVERT_ACCORDION_TO_MULTIDATA", "COVERT_ACCORDION_TO_MULTIDATA", MicroflowActionTypes.COVERT_ACCORDION_TO_MULTIDATA,
    [
      {
        key: 'fieldKey',
        value: '',
        static: false
      },
      {
        key: 'Overwrite',
        value: '',
        static: false
      },
      {
        key: 'application_id',
        value: '',
        static: false
      },
    ],
    [
      {
        key: '',
        value: 'message',
        static: false
      },
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: 'suppressConvUI',
        static: false
      },
    ]),
    new MicroflowAction("Call Webhook", "Call Webhook", MicroflowActionTypes.CALL_WEBHOOK,
    [
      {
        key: "application_id",
        value: "",
        static: false,
       },
       {
        key: "taskID",
        value: "",
        static: false,
        },
       {
            key: "Body",
            value: "",
            static: false,
        }
    ],
    []),
  new MicroflowAction("Short URL Generator", "Short URL Generator", MicroflowActionTypes.SHORT_URL_GENERATOR,
      [
        {
          key: 'url',
          value: '',
          static: false
        },
        {
          key: 'application_id',
          value: '',
          static: false
        },
      ],
      [
        {
          key: '',
          value: 'shortenurl',
          static: false
        },
        {
          key: '',
          value: 'success',
          static: false
        },
        {
          key: '',
          value: 'message',
          static: false
        }
    ]),
    new MicroflowAction("Get Application Details V4", "Get Application Details V4", MicroflowActionTypes.GET_APPLICATION_DETAILS_V4,
    [
      {
        key: "applicantType",
        value: "",
        static: true,
        required: true,
       },
       {
        key: "multiDataRefKey",
        value: "multiDataRefKey",
        static: false,
       },
       {
        key: "dataArray",
        value: "",
        static: true,
        },
       {
        key: "documentArray",
        value: "",  
        static: true,
        }
    ],
    [ {
      key: "",
      value: "applicantData",
      static: false,
     },
     {
      key: "",
      value: "message",
      static: false,
     },

    ]),
    new MicroflowAction("Create Child Application V4", "Create Child Application V4", MicroflowActionTypes.CREATE_CHILD_APPLICATION_V4,
    [
      {
        key: "targetProduct",
        value: "",
        static: true,
        required: true
       },           
       {
        key: "applicantData",
        value: "",
        static: false,
        },
       {
            key: "referenceId",
            value: "",
            static: true,
        },
        {
          key: "completeConsumerStep",
          value: "false",
          static: true,
      }
    ],
    [ {   
      key: "",
      value: "childApplicationId",
      static: false,
     },
     {
      key: "",
      value: "message",
      static: false,
    },

    ]),
    new MicroflowAction("Update Applications V4", "Update Applications V4", MicroflowActionTypes.UPDATE_APPLICATIONS_V4,
    [
      {
        key: "targetApplicationId",
        value: "",
        static: false,
        required: true
       },
       {
        key: "applicantData",
        value: "",
        static: false,
       },
       {
        key: "multiDataKeysArray",
        value: "",
        static: true,
        },
    ],
    [ {
      key: "",
      value: "message",
      static: false,
     },
     {
      key: "",
      value: "success",
      static: false
    }
    ]),new MicroflowAction("Lock Multidata", "Lock Multidata", MicroflowActionTypes.LOCK_MULTIDATA,
    [
      {
        key: "templateDetails",
        value: "",
        static: true,
        required: true
       },
       {
        key: "multiDataFieldKey",
        value: "",
        static: true,
        required: true
        },
    ],
    [ {
        key: "",
        value: "data",
        static: false,
      },
      {
      key: "",
      value: "message",
      static: false,
     },
     {
      key: "",
      value: "success",
      static: false,
      },
    ]),

    new MicroflowAction("Populate Multidata", "Populate Multidata", MicroflowActionTypes.POPULATE_MULTIDATA,
    [
      {
        key: "multiDataFieldKey",
        value: "",
        static: true,
        required: true
       },
       {
        key: "templateId",
        value: "",
        static: true,
        required: true
        },
        {
          key: "object",
          value: "",
          static: true,
          required: true
          },
    ],
    [ {
      key: "",
      value: "data",
      static: false,
    },{
      key: "",
      value: "message",
      static: false,
     },
     {
      key: "",
      value: "success",
      static: false,
      },
    ]),

    new MicroflowAction("Get Application Details V4", "Get Application Details V4", MicroflowActionTypes.GET_APPLICATION_DETAILS_V4,
    [
      {
        key: "applicantType",
        value: "",
        static: true,
        required: true,
       },
       {
        key: "multiDataRefKey",
        value: "multiDataRefKey",
        static: false,
       },
       {
        key: "dataArray",
        value: "",
        static: true,
        },
       {
        key: "documentArray",
        value: "",  
        static: true,
        }
    ],
    [ {
      key: "",
      value: "applicantData",
      static: false,
     },
     {
      key: "",
      value: "message",
      static: false,
     },

    ]),
    new MicroflowAction("Create Child Application V4", "Create Child Application V4", MicroflowActionTypes.CREATE_CHILD_APPLICATION_V4,
    [
      {
        key: "targetProduct",
        value: "",
        static: true,
        required: true
       },           
       {
        key: "applicantData",
        value: "",
        static: false,
        },
       {
            key: "referenceId",
            value: "",
            static: true,
        },
        {
          key: "completeConsumerStep",
          value: "false",
          static: true,
      }
    ],
    [ {   
      key: "",
      value: "childApplicationId",
      static: false,
     },
     {
      key: "",
      value: "message",
      static: false,
    },

    ]),
    new MicroflowAction("Prioritize Task", "To Prioritize the assigned Task", MicroflowActionTypes.PRIORITIZE_TASKS,
    [
      {
        key: "taskName",
        value: "",
        static: true,
        required: true
       },           
       {
        key: "priority",
        value: "",
        static: true,
        required: true
        },
        {
          key: "application_id",
          value: "",
          static: false,
          required: true
          },
    ],
    [ {   
      key: "",
      value: "success",
      static: false,
     },
     {
      key: "",
      value: "message",
      static: false,
    },
    ]),
    new MicroflowAction("Save to Multidata", "Save to Multidata", MicroflowActionTypes.SAVE_TO_MULTIDATA,
    [
      {
        key: "fields",
        value: "",
        static: true,
        required: true
       },           
       {
        key: "multiDataRefKey",
        value: "",
        static: false,
        required: true
        }
    ],
    [ {   
      key: "",
      value: "success",
      static: false,
     },
     {
      key: "",
      value: "message",
      static: false,
    },
    {
      key: "",
      value: "data",
      static: false
    }
    ]),
    new MicroflowAction("Fill PDF", "Write Pdf V4", MicroflowActionTypes.WRITE_PDF_V4,
    [
      {
        key: 'application_id',
        value: '',
        static: false
      },
      {
        key: 'fileName',
        value: '',
        static: false
      },
      {
        key: 'methordName',
        value: '',
        static: false
      },
      {
        key: 'category',
        value: '',
        static: false
      },
      {
        key: 'subCategory',
        value: '',
        static: false
      },
      {
        key: 'fileId',
        value: '',
        static: false
      },
      {
        key: 'fieldsToInclude',
        value: '',
        static: false
      },
      {
        key: 'includeCoApplicantDetails',
        value: '',
        static: false
      },
      {
        key: 'split',
        value: '',
        static: false
      },
      {
        key: 'editable',
        value: '',
        static: false
      },
      {
        key: 'numberFieldsToExclude',
        value: '',
        static: false
      },
      {
        key: 'discardZeroForFormulaFields',
        value: '',
        static: false
      },
    ],
    [
      {
        key: '',
        value: 'success',
        static: false
      },
      {
        key: '',
        value: 'message',
        static: false
      },
      {
        key: '',
        value: 'base64',
        static: false
      }
    ]),
    new MicroflowAction("Extract multidata fields", "Extract multidata fields", MicroflowActionTypes.EXTRACT_MULTIDATA_FIELDS,
    [
      {
        key: "templateId",
        value: "",
        static: false,
        required: true,
       },
       {
        key: "multiDataRefKey",
        value: "",
        static: false,
        required: true,
       },
       {
        key: "fields",
        value: "",  
        static: false,
        required: true,
        }
    ],
    [ {
      key: "",
      value: "data",
      static: false,
     },
     {
      key: "",
      value: "success",
      static: false,
     },
     {
      key: "",
      value: "message",
      static: false,
     },

    ]),
    new MicroflowAction("Send back child application", "Send back child application", MicroflowActionTypes.SEND_BACK_CHILD_APPLICATION,
    [
      {
        key: "childApplicationId",
        value: "",
        static: false,
        required: true,
       },

    ],
    [ {
      key: "",
      value: "data",
      static: false,
     },
     {
      key: "",
      value: "success",
      static: false,
     },
     {
      key: "",
      value: "message",
      static: false,
     },

    ]),
    new MicroflowAction("Minimum valuation", "Save to Multidata", MicroflowActionTypes.MINIMUM_VALUATION,
    [
      {
        key: "prefix",
        value: "",
        static: true,
        required: true
       }, 
       {
        key: "fields",
        value: "",
        static: true,
        required: true
       },           
       {
        key: "multiDataRefKey",
        value: "",
        static: false,
        required: true
        },
        {
          key: "mapFields",
          value: "",
          static: false,
          required: true
          }
    ],
    [ {   
      key: "",
      value: "success",
      static: false,
     },
     {
      key: "",
      value: "message",
      static: false,
    },
    ]),
];