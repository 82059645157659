import { useEffect, useState } from 'react';
import Button from '../button';
import './style.scss';
import { Images } from 'assets/Images';
import { SelectMenu, TextBox } from "../index";
import { isObject } from 'lodash';

export default function ConfirmationPopUp({ label, isInProgress, inProgressLabel, isCompleted, completedLabel, handleClose, handleSubmit, showDropdown, optionList, placeholder, showTextBox }){

    const [ selectedValue, setSelectedValue ] = useState('');

    useEffect(()=>{
        if(isCompleted){
            setTimeout(()=>{
                handleClose();
            },1000);
        }
    },[isCompleted]);

    const handleSelectedValue = (data) => {
        if(!data?.label){
            setSelectedValue(data?.target?.value);
        } else {
            setSelectedValue(data);
        }
    }


    return (
        <div className="confirm-popup">
            <div className="confirm-popup__container">
                {!isInProgress && !isCompleted && <> 
                    <h4>{label}</h4>
                    { showTextBox && <div><TextBox type="text" placeholder="Enter your comments" value={selectedValue} onChange={(event)=>{handleSelectedValue(event)}}/></div>}
                    { showDropdown && <div><SelectMenu value={selectedValue?.label} options={optionList} placeholder={placeholder} onSelectionChange={(event)=>{handleSelectedValue(event)}}/></div> }
                    <div className="confirm-popup__actions">
                        <Button label="NO" handleClick={handleClose}/>
                        <Button label="YES" secondary={true} handleClick={()=>{handleSubmit(selectedValue)}} disabled={(!((!showDropdown&&!showTextBox) || (showDropdown&&selectedValue) || (showTextBox&&selectedValue)))}/>
                    </div>
                </>}
                { isInProgress && !isCompleted && <>
                    <div className="confirm-popup__group">
                        <img src={Images.shortloader} alt="" className="confirm-popup__loader"/>
                        <h5>{inProgressLabel}</h5>
                    </div>
                </> }
                { !isInProgress && isCompleted && <>
                    <div className="confirm-popup__group">
                        <img src={Images.successtick} alt=""/>
                        <h5>{completedLabel}</h5>
                    </div>
                </> }
            </div>
        </div>
    )
}