import './style.scss';

export default function TextBox(props){
    const { label, placeholder, value, type, mandatory, maxlength, minlength, onChange, onBlur, id, error, errorMessage, min, max } = props;
    return (
        <div className="textbox">
            { label && <label>{label}{label && mandatory && <sup>*</sup>}</label> }
            <input type={type} placeholder={placeholder} value={value} maxLength={maxlength} minLength={minlength} min={min} max={max} autoComplete="off"  onChange={onChange} onBlur={onBlur} id={id} name={id} style={{borderBottom: error?'1px solid #ED0000':''}}/>
            { (error || maxlength ) && <span className="textbox__group">
                <span className="textbox__error">{ error && <>{errorMessage}</> }</span>
                { maxlength && <span className="textbox__description">{ maxlength-(value?(value?.length):0) } characters left</span> }
            </span> }
        </div>
    )
}