import { map } from 'lodash';
import { useEffect, useState } from 'react';
import { decrypt } from 'utils/crypto';
import './style.scss';
import { PROFILE_COLORS } from 'config';
import { Images } from 'assets/Images';
import { useQuery } from '@tanstack/react-query';
import * as api from 'actions';
import { useNavigate } from 'react-router-dom';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';

export default function Profile() {
    const [ userDetails, setUserDetails ] = useState(null);
    const navigate = useNavigate();

    useEffect(()=>{
        const userData = decrypt(localStorage.getItem('_ud'));
        setUserDetails(userData);
    },[])

    const handleLogoutOnSuccess = () => {
        localStorage.clear(); 
        navigate("/", { replace: true });
    }

    const { refetch: handleLogout } = useQuery(['LOGOUT'], ()=> api.postMethodCall(ADMIN_API_AXIOS,'/v2/auth/logout',{})
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleLogoutOnSuccess
    })
    return (
        <div className="profile-box">
            <div className="profile-box__triangle-up"></div>
            <div className="profile-box__picture">
                <span>
                    <div id="profile" className="profile-box__item1__photo"></div>
                    <h2 className="profile-box__profiledetails--username">{userDetails?.attributes?.enteredUser?.[0]}</h2>
                </span>
            </div>
            <div className="profile-box__departments">
                <span className="profile-box__departments--label">Your Department</span>
                <ul>
                    {map(userDetails?.departmentList,(el,i)=>
                        <li key={i}>
                            <p className="profile-box__departments--char" style={{background:PROFILE_COLORS[i % PROFILE_COLORS.length]}}>{el?.name?.charAt(0)}</p>
                            <span className="profile-box__departments--teams">{el?.name}</span>
                        </li>
                    )}
                </ul>
            </div>
            <div className="profile-box__departments">
                <span className="profile-box__departments--label">Your Team</span>
                <ul>
                    {map(userDetails?.teamList,(el,i)=>
                        <li key={i}>
                            <p className="profile-box__departments--char" style={{background:PROFILE_COLORS[i % PROFILE_COLORS.length]}}>{el?.name?.charAt(0)}</p>
                            <span className="profile-box__departments--teams">{el?.name}</span>
                        </li>
                    )}
                </ul>
            </div>
            <div className="profile-box__logout" onClick={handleLogout}>
                <span><img src={Images.logout2} alt=""/></span>
                <span className="profile-box__departments--teams">Logout</span>
            </div>
        </div>
    )
}