import { Images } from 'assets/Images';
import classNames from 'classnames';
import { map } from 'lodash';
import { useState } from 'react';
import './style.scss';
import React from 'react';

export default function Button({ label, secondary, handleClick, isMultiple, optionList, disabled, handleSelectedOption, showIcon }){

    const [ showOptions, setShowOptions ] = useState(false);

    const handleOpenList = (event) => {
        setShowOptions(!showOptions);
        event?.stopPropagation();
    }

    return (
        <>
            { !isMultiple && <div className={classNames({"btn":true,"btn--secondary":secondary,disabled})} onClick={()=>handleClick(label)} >
                <label>{label}</label>
                { showIcon && <span><img src={Images.forwardBtn} alt="" loading='lazy'/></span> }
            </div> }
            { isMultiple && <div className="btn__multi">
                <div className={classNames({"btn":true,"btn--secondary":secondary,"btn--multiple":isMultiple})} onClick={()=>{handleClick(label)}}>
                    <span className="btn--label">{label}</span>
                    <span onClick={(event)=>{handleOpenList(event)}} className="btn--arrow"><img src={Images.whitearrow} alt=""/></span>
                </div>
                { showOptions && <div className="btn__list">
                    {map(optionList, (option,ind)=><React.Fragment key={ind}>
                        { option!==label && <span onClick={()=>{handleSelectedOption(option);setShowOptions(false);}}>{option}</span> }
                    </React.Fragment>
                    )}
                </div> }
            </div> }
        </>

    )
}