import { Table, Search, Button } from 'components/generic';
import './style.scss';
import { useQuery } from '@tanstack/react-query';
import { MICROFLOW_TABLE_HEADERS, MICROFLOW_TABLE_HEADERS_EXPANDED_VIEW, WORKFLOW_TABLE_HEADERS, WORKFLOW_TABLE_HEADERS_EXPANDED_VIEW, MICROFLOW_WORKFLOW_HEADERS, MICROFLOW_DETAILS_HEADERS, WORKFLOW_HEADERS, CONNECTOR_TABLE_HEADERS, CONNECTOR_TABLE_HEADERS_EXPANDED_VIEW } from '../../config';
import * as api from 'actions/graphql';
import { useState, useRef, useEffect, useContext } from 'react';
import Popup from '../popup';
import { MicroflowContext } from 'providers';

export default function Dashboard(){
    const selectedTableDataRef = useRef(null);
    const paramData = useRef(null);
    const [ microflowWidgetData, setMicroflowWidgetData ] = useState({
        currentPageNumber: 1,
        searchString: '',
        recordsPerPage: 3,
        sort: -1
    });
    const [ workflowWidgetData, setWorkflowWidgetData ] = useState({
        currentPageNumber: 1,
        searchString: '',
        recordsPerPage: 3,
        sort: -1
    });
    const [ connectorWidgetData, setConnectorWidgetData ] = useState({
        currentPageNumber: 1,
        searchString: '',
        recordsPerPage: 3,
        sort: -1
    });
    const [ selectedWidget, setSelectedWidget ] = useState(null);
    const [ expandedView, setExpandedView ] = useState(false);
    //widgets states
    const [ microflowHeaders, setMicroflowHeaders ] = useState(MICROFLOW_TABLE_HEADERS);
    const [ workFlowHeaders, setWorkFlowHeaders ] = useState(WORKFLOW_TABLE_HEADERS);
    const [ connectorHeaders, setConnectorHeaders ] = useState(CONNECTOR_TABLE_HEADERS);
    const [ microflowData, setMicroflowData ] = useState(null);
    const [ workflowData, setWorkFlowData ] = useState(null);
    const [connectorData, setConnectorData ] = useState(null);
    //popup states
    const [ selectedRowData, setSelectedRowData ] = useState(null);
    const [ selectedTableData, setSelectedTableData ] = useState(null);
    const [ selectedTableHeaders, setSelectedTableHeaders ] = useState(null);
    const [ enableBackAction, setEnableBackAction ] = useState(false);
    const microflowContext = useContext(MicroflowContext);

    useEffect(()=>{
        if(window.innerWidth>1400){
            setMicroflowWidgetData({...microflowWidgetData, recordsPerPage: 7});
            setWorkflowWidgetData({...workflowWidgetData, recordsPerPage: 7});
            setConnectorWidgetData({...connectorWidgetData, recordsPerPage: 7});
        }
    },[])

    const handleExpandEvent = (event, widgetName) => {
        let recordsPerPage;
        recordsPerPage = event?7:3;
        if(window.innerWidth>1400){
            recordsPerPage = event?16:7;
        }
        switch(widgetName){
            case 'MICROFLOW':
                event?setMicroflowHeaders(MICROFLOW_TABLE_HEADERS_EXPANDED_VIEW):setMicroflowHeaders(MICROFLOW_TABLE_HEADERS);
                event?setMicroflowWidgetData({...microflowWidgetData, recordsPerPage: recordsPerPage}):setMicroflowWidgetData({...microflowWidgetData, recordsPerPage: 3});
                break;
            case 'WORKFLOW':
                event?setWorkFlowHeaders(WORKFLOW_TABLE_HEADERS_EXPANDED_VIEW):setWorkFlowHeaders(WORKFLOW_TABLE_HEADERS);
                event?setWorkflowWidgetData({ ...workflowWidgetData, recordsPerPage: recordsPerPage}):setWorkflowWidgetData({ workflowWidgetData, recordsPerPage: 3});
                break;
            case 'CONNECTOR':
                event?setConnectorHeaders(CONNECTOR_TABLE_HEADERS_EXPANDED_VIEW):setConnectorHeaders(CONNECTOR_TABLE_HEADERS);
                event?setConnectorWidgetData({ ...connectorWidgetData, recordsPerPage: recordsPerPage}):setConnectorWidgetData({ connectorWidgetData, recordsPerPage: 3});
                break;
            default:
                break;
        }
        event?setSelectedWidget(widgetName):setSelectedWidget(null);
        setExpandedView(event);
    }

    const handlePagination = (pageNumber, widgetName) => {
        switch(widgetName){
            case 'MICROFLOW':
                setMicroflowWidgetData({...microflowWidgetData, currentPageNumber: pageNumber});
                break;
            case 'WORKFLOW':
                setWorkflowWidgetData({...workflowWidgetData, currentPageNumber: pageNumber});
                break;
            case 'CONNECTOR':
                setConnectorWidgetData({...connectorWidgetData, currentPageNumber: pageNumber});
                break;
            default:
                break;
        }
    }

    const handleSort = (widgetName) => {
        switch(widgetName){
            case 'MICROFLOW':
                setMicroflowWidgetData({...microflowWidgetData, sort: -microflowWidgetData.sort});
                break;
            case 'WORKFLOW':
                setWorkflowWidgetData({...workflowWidgetData, sort: -workflowWidgetData?.sort});
                break;
            case 'CONNECTOR':
                setConnectorWidgetData({...connectorWidgetData, sort: -connectorWidgetData?.sort});
                break;
            default:
                break;
        }
    }

    const  handleSearch = (e, widgetName) => {
        switch(widgetName){
            case 'MICROFLOW':
                setMicroflowWidgetData({...microflowWidgetData, currentPageNumber: 1, searchString: e?.toLowerCase()});
                break;
            case 'WORKFLOW':
                setWorkflowWidgetData({...workflowWidgetData, currentPageNumber: 1, searchString: e?.toLowerCase()});
                break;
            case 'CONNECTOR':
                setConnectorWidgetData({...connectorWidgetData, currentPageNumber: 1, searchString: e?.toLowerCase()});
                break;
            default:
                break;
        }
    }

    const handleSelectedRow = (rowData, widgetName) => {
        setSelectedWidget(widgetName);
        setEnableBackAction(false);
        switch(widgetName) {
            case 'MICROFLOW':
                rowData.header = 'MICROFLOW_WORKFLOW_POPUP';
                setSelectedTableHeaders(MICROFLOW_WORKFLOW_HEADERS);
                selectedTableDataRef.current = rowData;
                fetchWorkFlowDetailsBasedOnMicroflow();
                break;
            case 'WORKFLOW':
                rowData.header = 'WORKFLOW_POPUP';
                setSelectedTableHeaders(WORKFLOW_HEADERS);
                selectedTableDataRef.current = rowData;
                fetchWorkflowDetails();
                break;
            default:
                break;
        }
        setSelectedRowData(rowData);
    }

    const populateTableData = (response) => {
        setSelectedTableData(response?.data?.[Object.keys?.(response?.data)?.[0]]?.data);
    }

    const updateTableData = (response) => {
        let data;
        switch(paramData?.current?.widgetName){
            case 'MICROFLOW':
                data = [ ...microflowData ];
                data[paramData?.current?.rowIndex] = {...data[paramData?.current?.rowIndex], ...response?.data?.microFlowDependencyBasedOnParams?.data?.[0]};
                setMicroflowData(data);
                break;
            case 'WORKFLOW':
                data = [ ...workflowData ];
                data[paramData?.current?.rowIndex] = {...data[paramData?.current?.rowIndex],...response?.data?.workFlowDependencyBasedOnParams?.data?.[0]};
                setWorkFlowData(data);
                break;
            case 'CONNECTOR':
                data = [ ...connectorData ];
                data[paramData?.current?.rowIndex] = {...data[paramData?.current?.rowIndex],...response?.data?.connectorDependencyBasedOnParams?.data?.[0]};
                setConnectorData(data);
                break;
            default:
                break;
        }
    }

    const closePopUp = () => {
        selectedTableDataRef.current = null;
        setSelectedRowData(null);
        setSelectedTableData(null);
    }

    const navigatePreviousPage = () => {
        switch(selectedTableDataRef.current?.header){
            case 'WORKFLOW_POPUP':
                selectedTableDataRef.current = { ...selectedTableDataRef.current, header: 'MICROFLOW_DETAILS_POPUP' };
                setSelectedTableHeaders(MICROFLOW_DETAILS_HEADERS);
                setSelectedRowData(selectedTableDataRef.current);
                fetchMicroflowDetailsBasedOnFlow();
                break;
            case 'MICROFLOW_DETAILS_POPUP':
                setEnableBackAction(false);
                selectedTableDataRef.current = { ...selectedTableDataRef.current, header: 'MICROFLOW_WORKFLOW_POPUP' };
                setSelectedTableHeaders(MICROFLOW_WORKFLOW_HEADERS);
                setSelectedRowData(selectedTableDataRef.current);
                fetchWorkFlowDetailsBasedOnMicroflow();
                break;
            default:
                break;
        }
    }

    const viewSelectedRow = (rowData) => {
        setEnableBackAction(true);
        switch(selectedWidget){
            case 'MICROFLOW':
                selectedTableDataRef.current = { ...selectedTableDataRef.current, workflowName: rowData?.workflowName, header: 'MICROFLOW_DETAILS_POPUP' };
                setSelectedTableHeaders(MICROFLOW_DETAILS_HEADERS);
                setSelectedRowData(selectedTableDataRef.current);
                fetchMicroflowDetailsBasedOnFlow();
                break;
            default:
                break;
        }
    }

    const viewWorkFlowDetails = () => {
        selectedTableDataRef.current = { ...selectedTableDataRef.current, header: 'WORKFLOW_POPUP' };
        setSelectedRowData(selectedTableDataRef.current);
        setSelectedTableHeaders(WORKFLOW_HEADERS);
        fetchWorkflowDetails();
    }

    const frameRequestData = (reqData, tableHeader, data) => {
        for(let header of tableHeader){
            if(header.key !== Object.keys(data.value)?.[0]) {
                if(data?.rowData[header?.fieldkey]) {
                    reqData[header.fieldkey] = data?.rowData[header?.fieldkey];
                } else if(data?.rowData[header?.key]) {
                    reqData[header.key] = data?.rowData[header?.key];
                }
            } else {
                break;
            }
        }
    }

    const onDropdownChange = (data) => {
        const reqData = { ...data.value, microflowId: data?.rowData['microflowId'] };
        switch(data?.widgetName) {
            case 'MICROFLOW':
                frameRequestData(reqData, MICROFLOW_TABLE_HEADERS_EXPANDED_VIEW, data);
                data.value = reqData;
                paramData.current = data;
                fetchMicroFlowDependencyBasedOnParams();
                break;
            case 'WORKFLOW':
                frameRequestData(reqData, WORKFLOW_TABLE_HEADERS_EXPANDED_VIEW, data);
                data.value = reqData;
                paramData.current = data;
                fetchWorkFlowDependencyBasedOnParams();
                break;
            case 'CONNECTOR':
                data.value['connectorId'] = data?.rowData['connectorId'];
                paramData.current = data;
                fetchConnectorDependencyBasedOnParams();
                break;
            default:
                break;
        }
    }

    const populateWidgetwData = (response, widgetName) => {
        switch(widgetName){
            case 'MICROFLOW':
                setMicroflowData(response?.data?.microFlowDependencyList?.data);
                break;
            case 'WORKFLOW':
                setWorkFlowData(response?.data?.workFlowDependencyList?.data);
                break;
            case 'CONNECTOR':
                setConnectorData(response?.data?.connectorDependencyList?.data);
                break;
            default:
                break;
        }
    }

    const handleNavigate = (routeName) => {
        switch(routeName){
            case "MICROFLOW":
                microflowContext.setShowDashboard(false);
                break;
            default:
                break;
        }
    }
    
    useQuery(['FETCH_MICROFLOW_DATA', microflowWidgetData], ()=> api.getMicroFlowDependencyList('/v2/graphql',microflowWidgetData), { refetchOnWindowFocus: false, onSuccess: (data)=>populateWidgetwData(data,'MICROFLOW') })

    const { data: microflowListingTotalCount } = useQuery(['FETCH_MICROFLOW_COUNT', microflowWidgetData], ()=> api.getMicroFlowDependencyList('/v2/graphql',microflowWidgetData, true), { refetchOnWindowFocus: false })

    useQuery(['FETCH_WORKFLOW_DATA', workflowWidgetData], ()=> api.getWorkFlowDependencyList('/v2/graphql',workflowWidgetData), { refetchOnWindowFocus: false, onSuccess: (data)=>populateWidgetwData(data,'WORKFLOW') })

    const { data: workflowListingTotalCount } = useQuery(['FETCH_WORKFLOW_COUNT', workflowWidgetData], ()=> api.getWorkFlowDependencyList('/v2/graphql',workflowWidgetData, true), { refetchOnWindowFocus: false })

    useQuery(['FETCH_CONNECTOR_DATA', connectorWidgetData], ()=> api.getConnectorDependencyList('/v2/graphql',connectorWidgetData), { refetchOnWindowFocus: false, onSuccess: (data)=>populateWidgetwData(data,'CONNECTOR') })

    const { data: connectorListingTotalCount } = useQuery(['FETCH_CONNECTOR_COUNT', connectorWidgetData], ()=> api.getConnectorDependencyList('/v2/graphql',connectorWidgetData, true), { refetchOnWindowFocus: false })

    const { refetch: fetchWorkFlowDetailsBasedOnMicroflow } = useQuery(['FETCH_MICROFLOW_WORKFLOW_DETAILS'], ()=> api.getWorkFlowDataBasedOnMicroflow('/v2/graphql', selectedTableDataRef.current), {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: populateTableData
    })

    const { refetch: fetchMicroflowDetailsBasedOnFlow } = useQuery(['FETCH_MICROFLOW_DETAILS'], ()=> api.getMicroflowDetailsBasedOnFlow('/v2/graphql', selectedTableDataRef.current), {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: populateTableData
    })

    const { refetch: fetchWorkflowDetails } = useQuery(['FETCH_WORKFLOW_DETAILS'], ()=> api.getWorkflowDetails('/v2/graphql', selectedTableDataRef.current), {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: populateTableData
    })

    const { refetch: fetchMicroFlowDependencyBasedOnParams } = useQuery(['UPDATE_MICROFLOW_DETAILS'], ()=> api.getMicroFlowDependencyBasedOnParams('/v2/graphql', paramData.current), {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: updateTableData
    })

    const { refetch: fetchWorkFlowDependencyBasedOnParams } = useQuery(['UPDATE_WORKFLOW_DETAILS'], ()=> api.getWorkFlowDependencyBasedOnParams('/v2/graphql', paramData.current), {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: updateTableData
    })
    
    const { refetch: fetchConnectorDependencyBasedOnParams } = useQuery(['UPDATE_CONNECTOR_DETAILS'], ()=> api.getConnectorDependencyBasedOnParams('/v2/graphql', paramData.current), {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: updateTableData
    })

    return (
        <div className="dashboard">
            { (!expandedView || ['MICROFLOW','WORKFLOW',null].includes(selectedWidget)) && <div className="dashboard__row">
                { (!expandedView || (selectedWidget==='MICROFLOW' && expandedView)) && <div className="dashboard__col">
                    <header style={{justifyContent: "space-between"}}>
                        <span>Microflow</span>
                        <div className="dashboard__col--group">
                            <Search widgetName="MICROFLOW" searchFunction={handleSearch}/>
                            <Button label="WORKSPACE" secondary={true} showIcon={true} handleClick={()=>{handleNavigate("MICROFLOW")}}></Button>
                        </div>
                    </header>
                    <div className="dashboard__table"><Table widgetName="MICROFLOW"  widgetData={microflowWidgetData}  header={microflowHeaders} data={microflowData} totalRecords={microflowListingTotalCount?.data?.microFlowDependencyList?.count} handleExpandEvent={handleExpandEvent} handlePagination={handlePagination} handleSort={handleSort} rowClick={handleSelectedRow} onDropdownChange={onDropdownChange}/></div>
                </div> }
                { (!expandedView || (selectedWidget==='WORKFLOW' && expandedView)) && <div className="dashboard__col">
                    <header>
                        <span>Workflow</span>
                        <Search widgetName="WORKFLOW" searchFunction={handleSearch}/>
                    </header>
                    <div className="dashboard__table"><Table widgetName="WORKFLOW" widgetData={workflowWidgetData} header={workFlowHeaders} data={workflowData} totalRecords={workflowListingTotalCount?.data?.workFlowDependencyList?.count} handleExpandEvent={handleExpandEvent} handlePagination={handlePagination} handleSort={handleSort} rowClick={handleSelectedRow}  onDropdownChange={onDropdownChange}/></div>
                </div> }
            </div> }
            { (!expandedView || ['CONNECTOR','CHART',null].includes(selectedWidget)) && <div className="dashboard__row">
                { (!expandedView || (selectedWidget==='CONNECTOR' && expandedView)) && <div className="dashboard__col">
                    <header>
                        <span>Connector</span>
                        <Search widgetName="CONNECTOR" searchFunction={handleSearch}/>
                    </header>
                    <div className="dashboard__table"><Table widgetName="CONNECTOR"  widgetData={connectorWidgetData}  header={connectorHeaders} data={connectorData} totalRecords={connectorListingTotalCount?.data?.connectorDependencyList?.count} handleExpandEvent={handleExpandEvent} handlePagination={handlePagination} handleSort={handleSort} rowClick={handleSelectedRow}  onDropdownChange={onDropdownChange}/></div>
                </div> }
                { (!expandedView || (selectedWidget==='CHART' && expandedView)) && <div className="dashboard__col">
                </div> }
            </div> }
            { selectedTableData && <div className="dashboard__overlay">
                <div className="dashboard__overlay--content">
                    <Popup overviewDetails={selectedRowData} data={selectedTableData} header={selectedTableHeaders} viewSelectedRow={viewSelectedRow} viewWorkFlowDetails={viewWorkFlowDetails} navigatePreviousPage={navigatePreviousPage} closePopUp={closePopUp} enableBackAction={enableBackAction}/>
                </div>
            </div> }
        </div>
    )
}