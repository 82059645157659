import "./style.scss";
import { Images } from "assets/Images";
import { Pagination, Search, ImageContainer, TabsListing, ConfirmationPopUp } from 'components/generic';
import { useContext, useState, useRef } from 'react';
import Table from '../listing-table/index.js';
import { useQuery } from '@tanstack/react-query';
import { MICROFLOW_DRAFT_LISTING_HEADERS, MICROFLOW_TABLE_ACTIONS, MICROFLOW_TABLE_PUBLISHED_ACTIONS, MICROFLOW_TABLE_REVIEW_ACTION } from "../../config";
import { TABS_LIST } from "config";
import AddMicroflowPopup from "../add-microflow-popup";
import * as api from 'actions/graphql';
import * as req from 'actions/index.js';
import { useDispatch } from 'react-redux';
import { MICROFLOW_LISTING_HEADERS } from "../../config";
import { MicroflowContext } from 'providers';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';
import { toasterReducer } from 'redux/reducers/toaster.reducer';
import { decrypt } from 'utils/crypto';

export default function MicroflowListing({selectedTab, setSelectedTab}){
    const [ widgetData, setWidgetData ] = useState((window.screen.height>1500)?{
        currentPageNumber: 1,
        searchString: '',
        recordsPerPage: 12,
        sort: null
    }:((window.screen.height>1000)?{
        currentPageNumber: 1,
        searchString: '',
        recordsPerPage: 6,
        sort: null
    }:{
        currentPageNumber: 1,
        searchString: '',
        recordsPerPage: 6,
        sort: null
    }));
    const microflowContext = useContext(MicroflowContext);
    const [ totalRecords, setTotalRecords ] = useState(0);
    const [ showConfirmationPopup, setShowConfirmationPopup ] = useState(false);
    const [ microflowListingData, setMicroflowListingData ] = useState([]);
    const [ showAddMicroflowPopUp, setShowAddMicroflowPopUp ] = useState(false);
    const [ completeDeleteAction, setCompleteDeleteAction ] = useState(false);
    const roles = decrypt(localStorage.getItem('_ud'))?.roles;
    const dispatch = useDispatch();
    const actionRef = useRef("");

    const handlePagination = (pageNumber) => {
        setWidgetData({...widgetData, currentPageNumber: pageNumber})
    }
    const handleSearch = (searchString) => {
        setWidgetData({...widgetData, searchString: searchString, currentPageNumber: 1});
    }
    const handleSort = () => {
        setWidgetData({...widgetData, sort: { name: widgetData?.sort?.name?-widgetData?.sort?.name:-1}})
    }
    const handleSelectedAction = (actionName, index) => {
        switch(actionName){
            case "EDIT":
                microflowContext.setSelectedMicroflowDetail(microflowListingData[index]);
                microflowContext.setShowMicroflowDetails(true);
                break;
            case "DELETE":
                microflowContext.setSelectedMicroflowDetail(microflowListingData[index]);
                setShowConfirmationPopup(true);
                break;
            case "COPY":
                actionRef.current = "COPY";
                microflowContext.setSelectedMicroflowDetail(microflowListingData[index]);
                setShowAddMicroflowPopUp(true);
                break;
            default:
                break;
        }
    }

    const handleCloseAddMicroflowPopup = () => {
        setShowAddMicroflowPopUp(false);
        microflowContext.setSelectedMicroflowDetail(null);
        getMicroflowListingData();
        getMicroflowListingCount();
        actionRef.current="";
    }

    const handleDeleteOnSuccess = (response) => {
        if(response.code === 200){
            setCompleteDeleteAction(true);
            getMicroflowListingData();
            getMicroflowListingCount();
            dispatch(toasterReducer({showToaster: true, message: response?.message, isError: false}));
        } else {
            setShowConfirmationPopup(false);
            dispatch(toasterReducer({showToaster: true, message: response?.message, isError: true}));
        }
    }

    const populateMicroflowData = (response) => {
        setMicroflowListingData(response?.data?.microflowConfigs?.data ?? []);
    }

    const populateMicroflowCount = (response) => {
        setTotalRecords(response?.data?.microflowConfigs?.count ?? 0);
    }

    const handleSelectedTab = (tab) => {
        setSelectedTab(tab);
        setWidgetData({...widgetData, currentPageNumber:1});
    }

    const { refetch: getMicroflowListingData } = useQuery(['FETCH_MICROFLOW_LISTING_DATA', widgetData, selectedTab], ()=> api.getMicroflowListingData('/v2/graphql',widgetData, selectedTab, false), { refetchOnWindowFocus: false, onSuccess: (data)=>populateMicroflowData(data) })

    const { refetch: getMicroflowListingCount } = useQuery(['FETCH_MICROFLOW_LISTING_COUNT', widgetData, selectedTab], ()=> api.getMicroflowListingData('/v2/graphql',widgetData, selectedTab, true), { refetchOnWindowFocus: false, onSuccess: (data)=>populateMicroflowCount(data) })

    const { refetch: handleDelete, isDeleteActionFetching } = useQuery(['DELETE_MICROFLOW'], ()=> req.deleteMethodCall(ADMIN_API_AXIOS,`/v2/microflow/${microflowContext?.selectedMicroflowDetail?.microflowId}/${microflowContext?.selectedMicroflowDetail?.version}/delete`), { refetchOnWindowFocus: false, enabled:false, onSuccess: handleDeleteOnSuccess });

    return (
        <div className="microflow-listing">
            <header>
                <div className="microflow-listing__group">
                    <span><img src={Images.backicon} alt="" onClick={()=>{microflowContext.setShowDashboard(true)}}/></span>          
                    <h4>Microflow</h4>
                </div>         
                <Search searchFunction={handleSearch}/>           
            </header>            
            <div className="microflow-listing__body">
                <div>
                    <TabsListing tabList={TABS_LIST} selectedTab={selectedTab} setSelectedTab={handleSelectedTab} actionsList={[]} handleSelectedAction={handleSelectedAction}/>              
                    { totalRecords>0 && <>
                        <div className="microflow-listing__table"><Table headers={selectedTab==='Draft'?MICROFLOW_DRAFT_LISTING_HEADERS:MICROFLOW_LISTING_HEADERS} actionsList={selectedTab==='Review'?MICROFLOW_TABLE_REVIEW_ACTION:(selectedTab==='Published'?MICROFLOW_TABLE_PUBLISHED_ACTIONS:MICROFLOW_TABLE_ACTIONS)} handleSelectedAction={handleSelectedAction} data={microflowListingData} selectedTab={selectedTab} handleSort={handleSort} widgetData={widgetData}/></div>                   
                    </> }
                </div>          
                { totalRecords===0 && <ImageContainer label={widgetData?.searchString?'Currently, there are no microflow with this name.':'Currently, there are no microflow for you. Click on add button to create new one.'} placeholderImg={Images.nodata} enableCursor={true}/> } 
                { totalRecords>0 && <Pagination {...widgetData} totalRecords={totalRecords} handlePagination={handlePagination}/> }
            </div>            
            <span className="microflow-listing__addbtn" onClick={()=>{setShowAddMicroflowPopUp(true);microflowContext.setSelectedMicroflowDetail(null);}}><img src={Images.addBtn} alt=""/></span> 
            {showAddMicroflowPopUp && <div className="microflow-listing__popup">                
                <div className="microflow-listing__popup--content">    
                    <AddMicroflowPopup  actionType={actionRef?.current} handleClosePopUp={()=>{handleCloseAddMicroflowPopup()}}/>                
                </div>          
            </div>}
            { showConfirmationPopup && <ConfirmationPopUp label="Are you sure you want to delete the microflow?" isCompleted={completeDeleteAction} isInProgress={isDeleteActionFetching} inProgressLabel="Delete in progress" completedLabel="Deleted successfully" handleClose={()=>{setShowConfirmationPopup(false);setCompleteDeleteAction(false)}}  handleSubmit={()=>{handleDelete()}}/> }
        </div>    )
}