import SimpleCrypto from "simple-crypto-js"


let _secretKey = '0859915e391472af7c0f36ae43c6a393';
let simpleCrypto = new SimpleCrypto(_secretKey);


export const encrypt = (data) => {
   return simpleCrypto.encrypt(data);
}

export const decrypt = (data) => {
   return data && simpleCrypto.decrypt(data);
}
