import "./style.scss";
import { useState } from "react";
import { ConfigTask } from "../config-task";
import { ConfigSettings } from "../config-settings";

export function FlowConfig(){
    const [ configTask, setConfigTask ] = useState(false);
    const [ configSettings, setConfigSettings ] = useState(true);

    return (
        <div className="flow-config">
            <div className="flow-config__body">
                { configTask && <div className="flow-config__main"><ConfigTask/></div> }
                { configSettings && <div className="flow-config__main"><ConfigSettings/></div> }
            </div>
        </div>
    )
}