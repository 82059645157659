export const Images = {
    layoutBackGround: '/images/gradient.svg',
    profileIcon: '/images/header/profile.svg',
    logo: '/images/header/logo.svg',
    indesign: '/images/sidebar/indesign.svg',
    designer: '/images/landing/indesign.svg',
    flow: '/images/sidebar/flow.svg',
    helptext: '/images/sidebar/helptext.svg',
    logout: '/images/sidebar/logout.svg',
    menubar: '/images/landing/menubar.svg',
    microflow: '/images/landing/microflow.svg',
    connector: '/images/landing/connector.svg',
    login: '/images/Process.svg',
    search: '/images/dashboard/search.svg',
    maximize: '/images/dashboard/maximize.svg',
    minimize: '/images/dashboard/minimize.svg',
    sort: '/images/dashboard/sort.svg',
    burstIcon: '/images/dashboard/burst.svg',
    backArrow: '/images/dashboard/left-arrow.svg',
    forwardArrow: '/images/dashboard/right-arrow.svg',
    close: '/images/dashboard/close.svg',
    listingdropdown: '/images/dashboard/listingdropdown.svg',
    dropdownArrow: '/images/sidebar/dropdownarrow.svg',
    loadMoreArrow: '/images/dropdownarrow.svg',
    product: '/images/sidebar/docx.svg',
    backicon: '/images/dashboard/backicon.svg',
    success: '/images/success.svg',
    error: '/images/error.svg',
    crossicon: '/images/close-small.svg',
    closearrow: '/images/landing/close.svg',
    connectorIcon: '/images/connector/connector.svg',
    download: '/images/connector/download.svg',
    upload: '/images/connector/upload.svg',
    filter: '/images/connector/filter.svg',
    addBtn: '/images/connector/addbtn.svg',
    edit: '/images/connector/edit.svg',
    delete: '/images/connector/delete.svg',
    download2: '/images/connector/download-2.svg',
    copy: '/images/connector/copy.svg',
    addrow: '/images/connector/addrow.svg',
    deleterow:'/images/connector/deleterow.svg',
    worklogs: '/images/connector/worklogs.svg',
    audit: '/images/connector/audit.svg',
    version: '/images/connector/version.svg',
    versionHistory: '/images/connector/versionicon.svg',
    audit2: '/images/connector/audit-2.svg',
    tick: '/images/connector/tick.svg',
    successtick: '/images/connector/successtick.svg',
    home: '/images/home.svg',
    whitearrow: '/images/white-arrow.svg',
    close2: '/images/connector/close-2.svg',
    filter2: '/images/connector/filter-2.svg',
    shortloader: '/images/Loading_icon_cropped.gif',
    nodata: '/images/No Files.svg',
    logout2: '/images/logout.svg',
    readonly: '/images/readonly.svg',
    versionIcon: '/images/microflow/versionicon.svg',
    burstIcon2: '/images/microflow/burstIcon2.svg',
    description: '/images/connector/description.svg',
    addNew: '/images/microflow/createnew.svg',
    addnewrow: '/images/microflow/add.svg',
    rectangle: '/images/microflow/rectangle.svg',
    blueadd: '/images/microflow/blueadd.svg',
    settings: '/images/microflow/settings.svg',
    forward: '/images/microflow/forward.svg',
    globalSettings: '/images/microflow/settings-2.svg',
    microflowIcon: '/images/microflow/microflowIcon.svg',
    diamond: '/images/microflow/diamondd.svg',
    backward: '/images/microflow/backward.svg',
    addgroup: '/images/microflow/addgroup.svg',
    close3: '/images/microflow/close-2.svg',
    addnew: '/images/microflow/addnew.svg',
    plus: '/images/microflow/plus.svg',
    minus: '/images/microflow/minus.svg',
    rule: '/images/microflow/rule.svg',
    connectorsymbol: '/images/microflow/connectoricon.svg',
    forwardBtn: '/images/forwardBtn.svg',
    shape1: '/images/microflow/shape1.svg',
    shape2: '/images/microflow/shape2.svg',
    shape3: '/images/microflow/shape3.svg',
    shape4: '/images/microflow/shape4.svg',
    shape5: '/images/microflow/shape5.svg',
    shape6: '/images/microflow/shape6.svg',
    info: '/images/microflow/info.svg',
    department: '/images/microflow/department.svg',
    team: '/images/microflow/team.svg',
    collapse: '/images/microflow/collapse.svg',
    noworkflows: '/images/microflow/noworkflows.svg',
    gridBackground: '/images/BG layer.svg',
    whiteBackarrow: '/images/indesign/white-backarrow.svg',
    documentIcon: '/images/indesign/documentIcon.svg',
    documentPlaceholder: '/images/indesign/documentplaceholder.svg',
    query: '/images/indesign/query.svg',
    sla: '/images/indesign/sla.svg',
    addaction: '/images/indesign/addaction.svg',
    clearbtn: '/images/indesign/clearbtn.svg',
    settingsoption: '/images/indesign/settingsoption.svg',
    actionconfig: '/images/indesign/action-config.svg',
    workspacesettings: '/images/indesign/workspace-settings.svg',
    workspacesummary: '/images/indesign/workspace-summary.svg',
    sectionSubsection: '/images/indesign/Section Sub-section.svg',
    tabLayout: '/images/indesign/Tab Layout.svg',
    default: '/images/indesign/Default.svg',
    reinitiate: '/images/indesign/reinitiate.svg',
    linksection: '/images/indesign/linksection.svg',
    auditAdd: '/images/audit/audit-add.svg',
    auditReorder: '/images/audit/audit-reorder.svg',
    auditDelete: '/images/audit/audit-delete.svg',
    auditEdit: '/images/audit/audit-edit.svg',
    auditApproved: '/images/audit/audit-approved.svg',
    auditCopy: '/images/audit/copy.svg',
    auditSubmit: '/images/audit/submit.svg',
    auditBaseline: '/images/audit/Baseline.svg',
    auditInsert: '/images/audit/Insert.svg',
    auditReview: '/images/audit/Review.svg',
    auditReject: '/images/audit/Reject.svg',
    auditSendback: '/images/audit/Sendback.svg',
    noAudit: '/images/audit/no-audit.svg'
};