import "./style.scss";
import { Images } from "assets/Images";
import { ToggleSwitch } from "components/generic";

export function Reinitiate(){
    return (
        <div className="reinitiate">
            <div className="reinitiate__group">
                <div>
                    <span><img src={Images.reinitiate} alt=""/></span>
                    <span className="reinitiate__text">Re-initiate child application</span>
                </div>
                <span className="reinitiate__description">This setting allow you to send back the child application</span>
            </div>
            <div className="reinitiate__row">
                <ToggleSwitch label="Enable re-initiate"/>
            </div>
        </div>
    )
}