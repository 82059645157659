import Routers from './routers';
import { ErrorBoundary } from 'react-error-boundary';

import './scss/index.scss';

function App() {
  return (
    <>
      <ErrorBoundary>
          <Routers />
      </ErrorBoundary>
    </>
  )
}

export default App

