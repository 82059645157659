import "./style.scss";
import { Button } from "components/generic";
import { CONFIG_SETTINGS_TABS_LIST } from "../../config";
import { map } from "lodash";
import classNames from 'classnames';
import { Images } from "assets/Images";
import { useState } from "react";
import { DocumentConfig } from "../document-config";
import { SectionConfig } from "../section-config";
import { QueryConfig } from "../query-config";
import { SlaConfig } from "../sla-config";
import { ActionConfig } from "../action-config";

export function ConfigSettings(){

    const [ selectedTab, setSelectedTab ] = useState(CONFIG_SETTINGS_TABS_LIST[0]);

    return (
        <div className="config-settings">
            <div className="config-settings__row">
                <div className="config-settings__group">
                    <div className="config-settings__header">
                        <span><img src={Images.whiteBackarrow} alt=""/></span>
                        <span>Task Name</span>
                    </div>
                    <div className="config-settings__tabs">
                        { map(CONFIG_SETTINGS_TABS_LIST, (tab,index)=> 
                            <span key={index} className={classNames({"config-settings__tabs--highlight":selectedTab===tab})} onClick={()=>{setSelectedTab(tab)}}>{tab}</span>
                        )}
                    </div>
                </div>
                <div>
                    <Button label="SAVE" secondary={true}/>
                </div>
            </div>
            <div className="config-settings__section">
                { selectedTab === "Document" && <DocumentConfig/> }
                { selectedTab === "Screens" && <SectionConfig/> }
                { selectedTab === "Queries" && <QueryConfig/> }
                { selectedTab === "SLA" && <SlaConfig/> }
                { selectedTab === "Actions" && <ActionConfig/> }
            </div>
        </div>
    )
}