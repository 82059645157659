import "./style.scss";
import { Images } from "assets/Images";
import { Button, SelectMenu, TextBox } from "components/generic";
import { APPLICANT_TYPES_FOR_RULES, OPERATOR_TYPES, ASSIGNMENT_TYPES, FIELD_TYPES, USER_PROPERTIES } from "../../config";
import { useQuery } from '@tanstack/react-query';
import * as api from 'actions';
import * as gqlApi from 'actions/graphql';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';
import { toasterReducer } from 'redux/reducers/toaster.reducer';
import { useDispatch } from 'react-redux';
import { map } from "lodash";
import React,{ useEffect, useRef, useState } from "react";
import { TypeField } from "../../model/datafields.model";

export function MicroflowRules({ closeOverlay , rulesData, saveRule, disabled}){

    const [ rules, setRules ] = useState([]);
    const [ ruleOptions, setRuleOptions ] = useState([]);
    const [ name, setName ] = useState('');
    const [ description, setDescription ] = useState('');
    const reqBodyRef = useRef(null);
    const ruleIdRef = useRef(null);
    const [ productFields, setProductFields ] = useState([]);
    const [ documentsList, setDocumentsList ] = useState([]);
    const [ selectedOperator, setSelectedOperator ] = useState("");
    const [ lookupList, setLookupList ] = useState([]);
    const [ selectedTypeList, setSelectedTypeList ] = useState([]);

    const initialData = {
        deleted: false,
        excute_when: "",
        isEnabled: true,
        rule_priority: "",
        ruleOptions: [],
        condition: {  },
        event: { type: "actions", param: []},
        ruleDescription: "",
        ruleName: ""
    }
    const dispatch = useDispatch();

    useEffect(()=>{
        if(rulesData?.entities?.[0]?.id) {
            ruleIdRef.current = [rulesData?.entities?.[0]?.id]
            fetchRules();
        }
    },[]);

    const handleRules = (response) => {
        const rulesConfig = response?.data?.rules?.data?.[0]?.rule;
        setName(rulesConfig.ruleName);
        setDescription(rulesConfig.ruleDescription);
        let operatorType;
        if(rulesConfig){
            operatorType = Object.keys(rulesConfig.condition)[0];
        }
        if(operatorType === "any"){
            setSelectedOperator("OR");
        } else {
            setSelectedOperator("AND");
        }
        setRules(rulesConfig?.condition?.[operatorType]);
    }

    const populateDocuments = (response) => {
        setDocumentsList(response?.data?.result);
    }

    const populateLookup = (response) => {
        // setLookupList(response?.data);
        const lookupConfigs = response['data'];
        if (lookupConfigs && Array.isArray(lookupConfigs) && lookupConfigs.length > 0) {
          formatLookups(lookupConfigs);
        }
    }

    const formatLookups = (lookupConfigs) => {
        const lookups = new TypeField('lookups', [], []);
        if (lookupConfigs && Array.isArray(lookupConfigs) && lookupConfigs.length > 0) {
          for (const lookupConfig of lookupConfigs) {
            const lookupCategory = lookupConfig.setupCategory;
            const subCategoriesConfigs = lookupConfig.configs;
            const lookupSubCategoryConfigs = [];
            for (const subCategoryConfig of subCategoriesConfigs) {
              const lookupSubCategoryOutputFields = [];
              const lookupSubCategory = subCategoryConfig.setupSubCategory;
              const lookupFields = subCategoryConfig.fields || [];
              const lookupInputFields = [];
              const lookupOutputFields = [];
              for (const field of lookupFields) {
                const fieldKey = field.key;
                const fieldLabel = field.value;
                const fieldType = field.field_type_details ? field.field_type_details.type : '';
                if (field && field.field_type_details && field.field_type_details.lookupEnabled) {
                  lookupOutputFields.push({
                    key: fieldKey,
                    value: fieldLabel,
                    type: fieldType
                  });
                } else {
                  lookupInputFields.push({
                    key: fieldKey,
                    value: fieldLabel,
                    type: fieldType
                  });
                }
              }
              if (lookupOutputFields.length > 0) {
                for (const lookupOutputField of lookupOutputFields) {
                  lookupSubCategoryOutputFields.push({
                    type: 'LOOKUP',
                    value: lookupOutputField.value,
                    key: lookupOutputField.key,
                    category: lookupCategory,
                    subCategory: lookupSubCategory,
                    inputFields: lookupInputFields,
                    outputFields: lookupOutputFields
                  });
                }
              }
              if (lookupSubCategoryOutputFields.length > 0) {
                const newLookupSubCategory = new TypeField(lookupSubCategory, lookupSubCategoryOutputFields,[]);
                lookupSubCategoryConfigs.push(newLookupSubCategory);
              }
            }
            if (lookupSubCategoryConfigs.length > 0) {
              const newLookupCategory = new TypeField(lookupCategory, lookupSubCategoryConfigs,[]);
              lookups.fields.push(newLookupCategory);
            }
          }
        }
      }

    const handleProductFields = (response) => {
        const productFieldsArray = response?.data?.field?.data;
        setProductFields(productFieldsArray);
    }

    const { refetch: fetchRules } = useQuery(['FETCH_RULES'], ()=> gqlApi.fetchRules('v2/graphql',ruleIdRef?.current)
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleRules
    })

    useQuery(['FETCH_PRODUCT_FIELDS'], ()=> gqlApi.getProductFields('/v2/graphql'), {
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: handleProductFields
    })

    useQuery(['FETCH_DOCUMENTS_LISTING_DATA'], ()=> api.getMethodCall(ADMIN_API_AXIOS,`v2/documents/published?page=1&limit=1000`), { refetchOnWindowFocus: false,  enabled: true,onSuccess: (data)=>populateDocuments(data) });

    useQuery(['FETCH_LOOKUP_LISTING_DATA'], ()=> api.getMethodCall(ADMIN_API_AXIOS,`v2/component/setup-configs/LOOKUP?page=1&limit=100`), { refetchOnWindowFocus: false,  enabled: true,onSuccess: (data)=>populateLookup(data) });

    const handleSelectedValue = (event,type,ruleData) => {
        const responseArray = [];
        if(type === "ruleOption"){
            const array = [];
            event?.forEach(val=>{
                array.push(val.value);
            })
            setRuleOptions(array);
        } else if(type === "type"){
            switch(event?.label){
                case "Documents":
                    documentsList.forEach(property=>{
                        responseArray.push({value: property?.document_id,label: property?.type});
                    })
                    setSelectedTypeList(responseArray);
                    break;
                case "Product Fields":
                    productFields.forEach(property=>{
                        responseArray.push({value: property?.name,label: property?.name});
                    })
                    setSelectedTypeList(responseArray);
                    break;
                case "User Properties":
                    USER_PROPERTIES.forEach(property=>{
                        responseArray.push({value: property?.value,label: property?.value});
                    })
                    setSelectedTypeList(responseArray);
                    break;
                case "Lookups":
                    setSelectedTypeList(lookupList);
                    break;
                default:
                    break;
            }
            ruleData[type] = event?.value || event?.target?.value;
            ruleData["fact"] = "";
            setRules(structuredClone(rules));
        } else {
            ruleData[type] = event?.label || event?.value || event?.target?.value;
            setRules(structuredClone(rules));
        }
    }

    const handleCreateRow = () => {
        const ruleValue = structuredClone(rules);
        ruleValue.push({ any: [{ type:"", fact:"", operator:"", value: ""}] });
        setRules(ruleValue);
    }

    const handleAddNewRow = (ruleRow) => {
        ruleRow.push({ type:"", fact:"", operator:"", value: ""});
        setRules(structuredClone(rules));
    }

    const handleSaveAction = () => {
        if(!name || !description) {
            dispatch(toasterReducer({showToaster:true, message: "Name and description are mandatory", isError: true}));
            return ;
        }
        const reqBody = structuredClone(initialData);
        reqBody.ruleName = name;
        reqBody.ruleDescription = name;
        if(selectedOperator === "AND"){
            reqBody.condition = {"all": rules};
        } else {
            reqBody.condition = {"any":rules};
        }
        reqBodyRef.current = { rule: reqBody };
        handleSave();
    }

    const handleDelete = (ruleRow,index) => {
        ruleRow.splice(index,1);
        setRules(structuredClone(rules));
    }

    const handleOperatorSelection = (event) => {
        setSelectedOperator(event?.value);
    }

    const handleSaveOnSuccess = (response) => {
        saveRule({ configId: response?.data?.configId, name: name, description: description});
        closeOverlay();
        dispatch(toasterReducer({showToaster: true, message: response?.message, isError: response?.code!==200}));
    }

    const { refetch: handleSave } = useQuery(['SAVE_RULE'], ()=> api.postMethodCall(ADMIN_API_AXIOS,'/v2/rule',reqBodyRef.current)
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleSaveOnSuccess
    })

    return (
        <div className="microflow-rules">
           <header>
            <span className="microflow-rules__text">Rules</span>
            <div className="microflow-rules__save">
                { !disabled && <><Button label="SAVE" secondary={true} handleClick={handleSaveAction}></Button>
                <span className="microflow-rules__seperator"></span>
                </> }
                <span><img src={Images.close} alt="" onClick={closeOverlay}/></span>
            </div>
        </header>
        <section>
            <div className="microflow-rules__row" style={{pointerEvents:disabled?"none":""}}>
                <div className="microflow-rules__col">
                    <TextBox type="text" label="Rule Name" mandatory={true} value={name} onChange={(event)=>{setName(event?.target?.value)}} maxlength="50"/>
                </div>
                <div className="microflow-rules__col">
                    <TextBox type="text" label="Description" mandatory={true} value={description} onChange={(event)=>{setDescription(event?.target?.value)}} maxlength="100"/>
                </div>
            </div>
            <div className="microflow-rules__row" style={{pointerEvents:disabled?"none":""}}>
                <div className="microflow-rules__col">
                    <SelectMenu label="Choose applicant" value={ruleOptions} options={APPLICANT_TYPES_FOR_RULES} isMulti={true} onSelectionChange={($event)=>{handleSelectedValue($event,"ruleOption")}}/>
                </div>
                <div className="microflow-rules__col">
                    <SelectMenu label="Choose operator" value={selectedOperator} options={OPERATOR_TYPES} onSelectionChange={(event)=>{handleOperatorSelection(event)}}/>
                </div>
                <div>
                    <span><img src={Images.addgroup} alt="" onClick={handleCreateRow}/></span>
                </div>
            </div>
            {map(rules, (rule,ind)=>
                <div className="microflow-rules__group" key={ind} style={{background:(ind%2===0)?'#0038B70D':'#F1F1F1',pointerEvents:disabled?"none":""}}>
                    <div className="microflow-rules__group--header">
                        <div className="microflow-rules__group--col">{ind+1}.</div>
                        <div className="microflow-rules__group--col">Operator</div>
                        <div className="microflow-rules__group--col">Type</div>
                        <div className="microflow-rules__group--col">Field</div>
                        <div className="microflow-rules__group--col">Operator</div>
                        <div className="microflow-rules__group--col">Value</div>
                    </div>
                    {map(rule?.any,(ruleRow,index)=>
                        <React.Fragment key={index}>
                            <div className="microflow-rules__group--row">
                                <span className="microflow-rules__group--col">
                                    {/* <span className="microflow-rules__group--img"><img src={Images.addnew} alt=""/></span> */}
                                    <span><img src={Images.close3} alt="" onClick={()=>{handleDelete(rule?.any,index)}}/></span>
                                </span>
                                <div className="microflow-rules__group--col" style={{pointerEvents:"none"}}>
                                    <SelectMenu value="OR" options={OPERATOR_TYPES}/>
                                </div>
                                <div className="microflow-rules__group--col">
                                    <SelectMenu value={ruleRow.type} options={FIELD_TYPES} onSelectionChange={(event)=>{handleSelectedValue(event,"type",ruleRow)}}/>
                                </div>
                                <div className="microflow-rules__group--col">
                                    <SelectMenu value={ruleRow.fact} options={selectedTypeList} onSelectionChange={(event)=>{handleSelectedValue(event,"fact",ruleRow)}}/>
                                </div>
                                <div className="microflow-rules__group--col">
                                    <SelectMenu value={ruleRow.operator} options={ASSIGNMENT_TYPES} onSelectionChange={(event)=>{handleSelectedValue(event,"operator",ruleRow)}}/>
                                </div>
                                <div className="microflow-rules__group--col" style={{marginTop:"7px"}}>
                                    <TextBox type="text" value={ruleRow.value} onChange={(event)=>{handleSelectedValue(event,"value",ruleRow)}}/>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <div className="microflow-rules__group--addnew" onClick={()=>{handleAddNewRow(rule?.any)}}>
                        <span className="microflow-rules__group--img"><img src={Images.addnew} alt=""/></span>
                        <span>Add Clauses</span>
                    </div>
                </div>
            )}
        </section>
        </div>
    )
}


// import "./style.scss";
// import { Images } from "assets/Images";
// import { Button, SelectMenu, TextBox } from "components/generic";
// import { APPLICANT_TYPES_FOR_RULES, OPERATOR_TYPES, ASSIGNMENT_TYPES, FIELD_TYPES, USER_PROPERTIES } from "../../config";
// import { useQuery } from '@tanstack/react-query';
// import * as api from 'actions';
// import * as gqlApi from 'actions/graphql';
// import { OLD_ADMIN_API_AXIOS, ADMIN_API_AXIOS } from 'utils/axios-interceptor';
// import { toasterReducer } from 'redux/reducers/toaster.reducer';
// import { useDispatch } from 'react-redux';
// import { map } from "lodash";
// import React,{ useEffect, useRef, useState } from "react";
// import { TypeField } from "../../model/datafields.model";

// export function MicroflowRules({ closeOverlay , rulesData, saveRule, disabled}){

//     const [ rules, setRules ] = useState([]);
//     const [ ruleOptions, setRuleOptions ] = useState([]);
//     const [ name, setName ] = useState('');
//     const [ description, setDescription ] = useState('');
//     const reqBodyRef = useRef(null);
//     const ruleIdRef = useRef(null);
//     const [ productFields, setProductFields ] = useState([]);
//     const [ documentsList, setDocumentsList ] = useState([]);
//     const [ selectedOperator, setSelectedOperator ] = useState("");
//     const [ lookupList, setLookupList ] = useState([]);
//     const [ selectedTypeList, setSelectedTypeList ] = useState([]);

//     const initialData = {
//         deleted: false,
//         excute_when: "",
//         isEnabled: true,
//         product: "home loan",
//         rule_priority: "",
//         setupCategory: "Rule",
//         setupSubCategory: "forms",
//         ruleOptions: [],
//         rule: {
//             condition: {  },
//             event: { type: "actions", param: []}
//         },
//         rule_description: "",
//         rule_id: ""
//     }
//     const dispatch = useDispatch();

//     useEffect(()=>{
//         if(rulesData?.entities?.[0]?.id) {
//             ruleIdRef.current = {
//                 configId: [rulesData?.entities?.[0]?.id]
//             }
//             fetchRules();
//         }
//     },[]);

//     const handleRules = (response) => {
//         const rulesConfig = response.data.result?.[0];
//         setName(rulesConfig.rule_id);
//         setDescription(rulesConfig.rule_description);
//         const operatorType = Object.keys(rulesConfig.rule.condition)[0];
//         if(operatorType === "any"){
//             setSelectedOperator("OR");
//         } else {
//             setSelectedOperator("AND");
//         }
//         setRules(rulesConfig.rule.condition[operatorType]);
//     }

//     const populateDocuments = (response) => {
//         setDocumentsList(response?.data?.result);
//     }

//     const populateLookup = (response) => {
//         // setLookupList(response?.data);
//         const lookupConfigs = response['data'];
//         if (lookupConfigs && Array.isArray(lookupConfigs) && lookupConfigs.length > 0) {
//           formatLookups(lookupConfigs);
//         }
//     }

//     const formatLookups = (lookupConfigs) => {
//         const lookups = new TypeField('lookups', [], []);
//         if (lookupConfigs && Array.isArray(lookupConfigs) && lookupConfigs.length > 0) {
//           for (const lookupConfig of lookupConfigs) {
//             const lookupCategory = lookupConfig.setupCategory;
//             const subCategoriesConfigs = lookupConfig.configs;
//             const lookupSubCategoryConfigs = [];
//             for (const subCategoryConfig of subCategoriesConfigs) {
//               const lookupSubCategoryOutputFields = [];
//               const lookupSubCategory = subCategoryConfig.setupSubCategory;
//               const lookupFields = subCategoryConfig.fields || [];
//               const lookupInputFields = [];
//               const lookupOutputFields = [];
//               for (const field of lookupFields) {
//                 const fieldKey = field.key;
//                 const fieldLabel = field.value;
//                 const fieldType = field.field_type_details ? field.field_type_details.type : '';
//                 if (field && field.field_type_details && field.field_type_details.lookupEnabled) {
//                   lookupOutputFields.push({
//                     key: fieldKey,
//                     value: fieldLabel,
//                     type: fieldType
//                   });
//                 } else {
//                   lookupInputFields.push({
//                     key: fieldKey,
//                     value: fieldLabel,
//                     type: fieldType
//                   });
//                 }
//               }
//               if (lookupOutputFields.length > 0) {
//                 for (const lookupOutputField of lookupOutputFields) {
//                   lookupSubCategoryOutputFields.push({
//                     type: 'LOOKUP',
//                     value: lookupOutputField.value,
//                     key: lookupOutputField.key,
//                     category: lookupCategory,
//                     subCategory: lookupSubCategory,
//                     inputFields: lookupInputFields,
//                     outputFields: lookupOutputFields
//                   });
//                 }
//               }
//               if (lookupSubCategoryOutputFields.length > 0) {
//                 const newLookupSubCategory = new TypeField(lookupSubCategory, lookupSubCategoryOutputFields,[]);
//                 lookupSubCategoryConfigs.push(newLookupSubCategory);
//               }
//             }
//             if (lookupSubCategoryConfigs.length > 0) {
//               const newLookupCategory = new TypeField(lookupCategory, lookupSubCategoryConfigs,[]);
//               lookups.fields.push(newLookupCategory);
//             }
//           }
//         }
//       }

//     const handleProductFields = (response) => {
//         const productFieldsArray = response?.data?.field?.data;
//         setProductFields(productFieldsArray);
//     }

//     const { refetch: fetchRules } = useQuery(['FETCH_RULES'], ()=> api.postMethodCall(OLD_ADMIN_API_AXIOS,'/setup/configs/Rule/forms',ruleIdRef?.current)
//     , {
//         enabled: false,
//         refetchOnWindowFocus: false,
//         onSuccess: handleRules
//     })

//     useQuery(['FETCH_PRODUCT_FIELDS'], ()=> gqlApi.getProductFields('/v2/graphql'), {
//         enabled: true,
//         refetchOnWindowFocus: false,
//         onSuccess: handleProductFields
//     })

//     useQuery(['FETCH_DOCUMENTS_LISTING_DATA'], ()=> api.getMethodCall(ADMIN_API_AXIOS,`v2/documents/published?page=1&limit=1000`), { refetchOnWindowFocus: false,  enabled: true,onSuccess: (data)=>populateDocuments(data) });

//     useQuery(['FETCH_LOOKUP_LISTING_DATA'], ()=> api.getMethodCall(ADMIN_API_AXIOS,`v2/component/setup-configs/LOOKUP?page=1&limit=100`), { refetchOnWindowFocus: false,  enabled: true,onSuccess: (data)=>populateLookup(data) });

//     const handleSelectedValue = (event,type,ruleData) => {
//         const responseArray = [];
//         if(type === "ruleOption"){
//             const array = [];
//             event?.forEach(val=>{
//                 array.push(val.value);
//             })
//             setRuleOptions(array);
//         } else if(type === "type"){
//             switch(event?.label){
//                 case "Documents":
//                     documentsList.forEach(property=>{
//                         responseArray.push({value: property?.document_id,label: property?.type});
//                     })
//                     setSelectedTypeList(responseArray);
//                     break;
//                 case "Product Fields":
//                     productFields.forEach(property=>{
//                         responseArray.push({value: property?.name,label: property?.name});
//                     })
//                     setSelectedTypeList(responseArray);
//                     break;
//                 case "User Properties":
//                     USER_PROPERTIES.forEach(property=>{
//                         responseArray.push({value: property?.value,label: property?.value});
//                     })
//                     setSelectedTypeList(responseArray);
//                     break;
//                 case "Lookups":
//                     setSelectedTypeList(lookupList);
//                     break;
//                 default:
//                     break;
//             }
//             ruleData[type] = event?.value || event?.target?.value;
//             ruleData["fact"] = "";
//             setRules(structuredClone(rules));
//         } else {
//             ruleData[type] = event?.label || event?.value || event?.target?.value;
//             setRules(structuredClone(rules));
//         }
//     }

//     const handleCreateRow = () => {
//         const ruleValue = structuredClone(rules);
//         ruleValue.push({ any: [{ type:"", fact:"", operator:"", value: ""}] });
//         setRules(ruleValue);
//     }

//     const handleAddNewRow = (ruleRow) => {
//         ruleRow.push({ type:"", fact:"", operator:"", value: ""});
//         setRules(structuredClone(rules));
//     }

//     const handleSaveAction = () => {
//         if(!name || !description) {
//             dispatch(toasterReducer({showToaster:true, message: "Name and description are mandatory", isError: true}));
//             return ;
//         }
//         const reqBody = structuredClone(initialData);
//         reqBody.rule_id = name;
//         reqBody.rule_description = name;
//         if(selectedOperator === "AND"){
//             reqBody.rule.condition["any"] = rules;
//         } else {
//             reqBody.rule.condition["all"] = rules;
//         }
//         reqBodyRef.current = reqBody;
//         handleSave();
//     }

//     const handleDelete = (ruleRow,index) => {
//         ruleRow.splice(index,1);
//         setRules(structuredClone(rules));
//     }

//     const handleOperatorSelection = (event) => {
//         setSelectedOperator(event?.value);
//     }

//     const handleSaveOnSuccess = (response) => {
//         saveRule({ configId: response?.configId, name: name, description: description});
//         closeOverlay();
//         dispatch(toasterReducer({showToaster: true, message: response?.message, isError: false}));
//     }

//     const { refetch: handleSave } = useQuery(['SAVE_RULE'], ()=> api.postMethodCall(OLD_ADMIN_API_AXIOS,'/setup/save_or_update',reqBodyRef.current)
//     , {
//         enabled: false,
//         refetchOnWindowFocus: false,
//         onSuccess: handleSaveOnSuccess
//     })

//     return (
//         <div className="microflow-rules">
//            <header>
//             <span className="microflow-rules__text">Rules</span>
//             <div className="microflow-rules__save">
//                 { !disabled && <><Button label="SAVE" secondary={true} handleClick={handleSaveAction}></Button>
//                 <span className="microflow-rules__seperator"></span>
//                 </> }
//                 <span><img src={Images.close} alt="" onClick={closeOverlay}/></span>
//             </div>
//         </header>
//         <section>
//             <div className="microflow-rules__row" style={{pointerEvents:disabled?"none":""}}>
//                 <div className="microflow-rules__col">
//                     <TextBox type="text" label="Rule Name" mandatory={true} value={name} onChange={(event)=>{setName(event?.target?.value)}} maxlength="50"/>
//                 </div>
//                 <div className="microflow-rules__col">
//                     <TextBox type="text" label="Description" mandatory={true} value={description} onChange={(event)=>{setDescription(event?.target?.value)}} maxlength="100"/>
//                 </div>
//             </div>
//             <div className="microflow-rules__row" style={{pointerEvents:disabled?"none":""}}>
//                 <div className="microflow-rules__col">
//                     <SelectMenu label="Choose applicant" value={ruleOptions} options={APPLICANT_TYPES_FOR_RULES} isMulti={true} onSelectionChange={($event)=>{handleSelectedValue($event,"ruleOption")}}/>
//                 </div>
//                 <div className="microflow-rules__col">
//                     <SelectMenu label="Choose operator" value={selectedOperator} options={OPERATOR_TYPES} onSelectionChange={(event)=>{handleOperatorSelection(event)}}/>
//                 </div>
//                 <div>
//                     <span><img src={Images.addgroup} alt="" onClick={handleCreateRow}/></span>
//                 </div>
//             </div>
//             {map(rules, (rule,ind)=>
//                 <div className="microflow-rules__group" key={ind} style={{background:(ind%2===0)?'#0038B70D':'#F1F1F1',pointerEvents:disabled?"none":""}}>
//                     <div className="microflow-rules__group--header">
//                         <div className="microflow-rules__group--col">{ind+1}.</div>
//                         <div className="microflow-rules__group--col">Operator</div>
//                         <div className="microflow-rules__group--col">Type</div>
//                         <div className="microflow-rules__group--col">Field</div>
//                         <div className="microflow-rules__group--col">Operator</div>
//                         <div className="microflow-rules__group--col">Value</div>
//                     </div>
//                     {map(rule?.any,(ruleRow,index)=>
//                         <React.Fragment key={index}>
//                             <div className="microflow-rules__group--row">
//                                 <span className="microflow-rules__group--col">
//                                     {/* <span className="microflow-rules__group--img"><img src={Images.addnew} alt=""/></span> */}
//                                     <span><img src={Images.close3} alt="" onClick={()=>{handleDelete(rule?.any,index)}}/></span>
//                                 </span>
//                                 <div className="microflow-rules__group--col" style={{pointerEvents:"none"}}>
//                                     <SelectMenu value="OR" options={OPERATOR_TYPES}/>
//                                 </div>
//                                 <div className="microflow-rules__group--col">
//                                     <SelectMenu value={ruleRow.type} options={FIELD_TYPES} onSelectionChange={(event)=>{handleSelectedValue(event,"type",ruleRow)}}/>
//                                 </div>
//                                 <div className="microflow-rules__group--col">
//                                     <SelectMenu value={ruleRow.fact} options={selectedTypeList} onSelectionChange={(event)=>{handleSelectedValue(event,"fact",ruleRow)}}/>
//                                 </div>
//                                 <div className="microflow-rules__group--col">
//                                     <SelectMenu value={ruleRow.operator} options={ASSIGNMENT_TYPES} onSelectionChange={(event)=>{handleSelectedValue(event,"operator",ruleRow)}}/>
//                                 </div>
//                                 <div className="microflow-rules__group--col" style={{marginTop:"7px"}}>
//                                     <TextBox type="text" value={ruleRow.value} onChange={(event)=>{handleSelectedValue(event,"value",ruleRow)}}/>
//                                 </div>
//                             </div>
//                         </React.Fragment>
//                     )}
//                     <div className="microflow-rules__group--addnew" onClick={()=>{handleAddNewRow(rule?.any)}}>
//                         <span className="microflow-rules__group--img"><img src={Images.addnew} alt=""/></span>
//                         <span>Add Clauses</span>
//                     </div>
//                 </div>
//             )}
//         </section>
//         </div>
//     )
// }