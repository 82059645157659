import { Images } from 'assets/Images';
import './style.scss';
import { Button, SelectMenu } from 'components/generic';


export default function Filter(){
    return (
        <div className="filter">
            <header>
                <span><img src={Images.filter2} alt=""/></span>
                <h4>Filter</h4>
            </header>
            <div className="filter__actions">
                <SelectMenu label="Status"/>
                <SelectMenu label="Type"/>
                <SelectMenu label="Method"/>
            </div>
            <div className="filter__actions">
                <div className="filter__actions--btn">
                    <Button secondary={true} label="APPLY"/>
                </div>
            </div>
        </div>
    )
}
