import './style.scss';
import { map } from 'lodash';

export default function Radio(props){
    const { inputList,handleInput, name, label, mandatory, error, errorMessage, value } = props;    

    return (
        <div className="radio">
            { label && <label>{label}{mandatory && <sup>*</sup>}</label> }
            { map(inputList,(data,ind)=>
                (<div key={ind} className="radio__list">
                    <input type="radio" id={(data?.value)+ind} onChange={(event)=>{handleInput(event)}} name={name} value={data?.value} checked={data?.value===value}/>
                    <label htmlFor={(data?.value)+ind}>{data?.label}</label>
                </div>)
            )}
            { error && <span className="radio__error">
                {errorMessage}
            </span> }
        </div>
    );
}