import ConnectorListing from './components/listing';
import './style.scss';
import { ConnectorContext } from 'providers';
import { useState } from 'react';
import ConnectorSetup from './components/connector-setup';
import { TABS_LIST } from 'config';

export default function ConnectorPage(){

    const [ showConnectorDetails, setShowConnectorDetails ] = useState(false);
    const [ selectedConnectorDetail, setSelectedConnectorDetail ] = useState({});
    const [ selectedTab, setSelectedTab ] = useState(TABS_LIST[0]);

    return (
        <ConnectorContext.Provider value={{setShowConnectorDetails,selectedConnectorDetail, setSelectedConnectorDetail}}>
            { !showConnectorDetails && <ConnectorListing selectedTab={selectedTab} setSelectedTab={setSelectedTab}/> }
            { showConnectorDetails && <ConnectorSetup/> }
        </ConnectorContext.Provider>
    )
}
