import { Button, SelectMenu, TextBox } from "components/generic";
import { isObject, map } from "lodash";
import { useEffect, useState } from "react";
import React from "react";
import { Images } from "assets/Images";

export function FilterCoapplicantsPopup({ requestField, closeOverlay }){

    const [ formValue, setFormValue ] = useState((requestField?.value));

    useEffect(()=>{
        if(!requestField?.value) {
            setFormValue([{type:"", key:"", operator: "", value:""}]);
        } else {
            setFormValue(JSON.parse(formValue));
        }
    },[]);

    const handleSelectedValue = (event, key, value) => {
        event = isObject(event)?event?.target?.value:event;
        value[key] = event;
        setFormValue(structuredClone(formValue));
    }

    const handleSave = () => {
        requestField.value = JSON.stringify(formValue);
        closeOverlay();
    }

    const handleAddNewRow = () => {
        const value = structuredClone(formValue);
        if(value?.length<=10){
            value.push({type:"", key:"", operator: "", value:""});
            setFormValue(value);
        }
    }

    const handleDeleteRow = (index) => {
        const value = structuredClone(formValue);
        value.splice(index,1);
        setFormValue(structuredClone(value));
    }

    return (
        <div className="action-popup">
            <div className="action-popup__main">
                <header>FILTER CO-APPLICANTS</header>
                { <section>
                    <div className="action-popup__rowgroup">
                        <div className="action-popup__rowgroup action-popup__innerbox"></div>
                        <div className="action-popup__heading action-popup__box">And/Or</div>
                        <div className="action-popup__heading action-popup__box">Field</div>
                        <div className="action-popup__heading action-popup__box">Operator</div>
                        <div className="action-popup__heading action-popup__box">Value</div>
                    </div>
                    {map(formValue,((field,index)=>
                        <React.Fragment key={index}>
                            <div className="action-popup__rowgroup">
                                <div className="action-popup__innerbox">
                                    <span><img src={Images.close3} alt="" onClick={()=>{handleDeleteRow(index)}}/></span>
                                </div>
                                {<div className="action-popup__box">
                                    { index!==0 && <SelectMenu value={field["type"]} options={[{label:"And",value:"And"},{label:"Or",value:"Or"}]} onChange={(event)=>{handleSelectedValue(event,"type",field)}}/> }
                                </div> }
                                <div className="action-popup__box"><TextBox type="text" value={field["key"]} onChange={(event)=>{handleSelectedValue(event,"key",field)}}/></div>
                                <div className="action-popup__box">
                                    <SelectMenu value={field["operator"]} options={[{label:"=",value:"="},{label:"!=",value:"!="}]} onChange={(event)=>{handleSelectedValue(event,"operator",field)}}/>
                                </div>
                                <div className="action-popup__box"><TextBox type="text" value={field["value"]} onChange={(event)=>{handleSelectedValue(event,"value",field)}}/></div>
                            </div>
                        </React.Fragment>
                    ))}
                    <div className="action-popup__btn action-popup__seperator--top" style={{pointerEvents:formValue.length>10?"none":""}} onClick={()=>{handleAddNewRow()}}>
                        <img src={Images.addrow} alt=""/>
                        <span>Add More Clauses ({10-(formValue.length)} more left)</span>
                    </div>
                </section> }
            </div>
            <footer>
                <div className="action-popup__note"><strong>Note: </strong>Above keys will be resolved at server level (not from screen). Hence, the keys should be saved before invoking the action. Design your process accordingly.</div>
                <div className="action-popup__actions">
                    <Button label="CANCEL" handleClick={closeOverlay}/>
                    <Button label="SAVE" secondary={true} handleClick={handleSave}/>
                </div>
            </footer>
        </div>
    )
}