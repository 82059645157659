import "./style.scss";
import { Images } from "assets/Images";
import { Button, TabsListing } from "components/generic";
import { FLOW_SETTINGS_TABS } from "../../config";
import { useState } from "react";
import { NavigationSettings } from "../navigation-settings";
import { Reinitiate } from "../reinitiate";
import { LinkSection } from "../link-section";
import { SlaSettings } from "../sla-settings";
import { Deviation } from "../deviation";

export function FlowSettings({ closeOverlay }){

    const [ selectedTab, setSelectedTab ] = useState(FLOW_SETTINGS_TABS[0]);

    const handleSaveData = () => {
    
    }

    return (
        <div className="flow-settings">
            <header>
                <span className="flow-settings__text">Flow Settings</span>
                <div className="flow-settings__save">
                    { <><Button label="SAVE" secondary={true} handleClick={handleSaveData}></Button>
                    <span className="flow-settings__seperator"></span> </>}
                    <span><img src={Images.close} alt="" onClick={closeOverlay}/></span>
                </div>
            </header>
            <div className="flow-settings__main">
                <TabsListing tabList={FLOW_SETTINGS_TABS} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
                { selectedTab === FLOW_SETTINGS_TABS[1] && <>
                    <Deviation/>
                </>}
                { selectedTab === FLOW_SETTINGS_TABS[2] && <>
                    <NavigationSettings/>
                </>}
                { selectedTab === FLOW_SETTINGS_TABS[3] && <>
                    <Reinitiate/>
                </>}
                { selectedTab === FLOW_SETTINGS_TABS[4] && <>
                    <LinkSection/>
                </>}
                { selectedTab === FLOW_SETTINGS_TABS[5] && <>
                    <SlaSettings/>
                </>}
            </div>
        </div>
    )
}