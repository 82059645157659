import './style.scss';
import { useState } from 'react';
import { Images } from 'assets/Images';
import { map } from 'lodash';
import * as moment from 'moment';
import { Pagination, SelectMenu, Tooltip } from 'components/generic';
import classNames from 'classnames';
import { truncate } from 'utils';

export default function Table(props){
    
    const { header, data, widgetName, widgetData, totalRecords, handleExpandEvent, handlePagination, handleSort, rowClick, onDropdownChange } = props;
    const [ expandView, setExpandedView ] = useState(false);
    const [ hoverData, setHoverData ] = useState(null);

    const onChange = (value,key, rowIndex) => {
        onDropdownChange({value: {[key]:value}, rowIndex: rowIndex, rowData: data[rowIndex], widgetName: widgetName})
    }

    const destructure = (value, nestedKey) => {
        const nestedKeysArrary = nestedKey.split('.');
        nestedKeysArrary?.forEach(key=>{
            if(value) value = value[key];
        })
        return value;
    }

    const tableHeader = (
        <thead>
            <tr>
                {
                    map(header,(el,i)=> <th key={i} style={{width: el?.width}}>
                        <span>{el?.label}</span>
                        { el?.sort && <span className="table-container__sort"><img src={Images.sort} alt="" onClick={()=>handleSort(widgetName)} className={classNames({"table-container__sort--rotate":widgetData?.sort===1})}/></span> }
                    </th>)
                }
            </tr>
        </thead>
    ); 

    const tableData = (
        <tbody>
            {
                map(data,(el,i)=> <tr key={i}>
                        {
                            map(header,(headerKey,ind)=> <td key={ind}>
                                { headerKey?.isNestedKey ? 
                                    <span>{destructure(el,headerKey?.key)}</span> :
                                    headerKey.key==='isProcess'?
                                        <>
                                           <span>{el[headerKey?.key]?'Process':'Product'}</span> 
                                        </>:
                                        <>
                                            { !headerKey?.dataListKey && !headerKey?.isDate && <>
                                                <span onMouseEnter={()=>setHoverData(headerKey?.key+i)} onMouseLeave={()=>setHoverData(null)}>
                                                    {truncate(el[headerKey?.key],18)||'-'}
                                                </span>
                                                { hoverData===(headerKey?.key+i) && el[headerKey?.key]?.length>18 && <span className="table-container__tooltip"><Tooltip value={el[headerKey?.key]}/></span> }
                                            </>}
                                            { headerKey?.isDate && !headerKey?.dataListKey && <span>{ moment(el[headerKey?.key]).format('MM-DD-YYYY HH:mm') }</span> }
                                            { headerKey?.dataListKey && <div className="table-container__dropdown">
                                                <SelectMenu value={el[headerKey?.key]} options={el[headerKey?.dataListKey]} onChange={(event)=>onChange(event, headerKey?.key, i)}/> 
                                            </div>}
                                            { headerKey?.showBurstIcon && <span className="table-container__bursticon" onClick={()=>rowClick(el, widgetName)}><img src={Images.burstIcon} alt=""/></span> }
                                        </>
                                }
                            </td>)
                        }
                </tr>)
            }
        </tbody>
    )

    return (
        <div className="table-container">
            <span className="table-container__expand">
                { !expandView && <img src={Images.maximize} alt="" onClick={()=>{handleExpandEvent(true,widgetName);setExpandedView(true)}}/> }
                { expandView && <img src={Images.minimize} alt="" onClick={()=>{handleExpandEvent(false,widgetName);setExpandedView(false)}}/> }
            </span>
            <table>
                { tableHeader }
                { tableData }
            </table>
            { totalRecords>0 && <Pagination {...widgetData} totalRecords={totalRecords} widgetName={widgetName} handlePagination={handlePagination}/> }
        </div>
    )
}