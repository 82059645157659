import "./style.scss";
import { Images } from "assets/Images";
import { ToggleSwitch } from "components/generic";

export function LinkSection(){
    return (
        <div className="link-section">
            <div className="link-section__group">
                <div>
                    <span><img src={Images.linksection} alt=""/></span>
                    <span className="link-section__text">Actions to child application</span>
                </div>
                <span className="link-section__description">This setting allow you to link the action to the child application</span>
            </div>
            <div className="link-section__row">
                <ToggleSwitch label="Enable re-initiate"/>
            </div>
        </div>
    )
}