import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { LayoutComponent } from "components/layout";
import LoginPage from "components/pages/login/index";
import { isEmpty } from 'lodash';
import { Toaster, Loader } from "components/generic";
import { useSelector } from 'react-redux';
// import { lazy, Suspense } from "react";
import LandingPage from "components/pages/landing";
import MicroflowPage from "components/pages/microflow";
import ConnectorPage from "components/pages/connector";
import IndesignPage from "components/pages/indesign";
import { PageNotFound } from "components/pages/pagenotfound";

function RequireAuth({ children }) {
    const authed = localStorage.getItem('_ac');

    return !isEmpty(authed) ? children : <Navigate to="/" replace />;
}

function Routers() {
    const toasterDetails = useSelector(state => state?.toaster?.data);
    const isLoading = useSelector(state=>state?.loader?.isLoading);
    // const LandingPage = lazy(() => import('components/pages/landing'));
    // const MicroflowPage = lazy(() => import('components/pages/microflow'));
    // const ConnectorPage = lazy(() => import('components/pages/connector'));
    return (
        <>
            {/* { isLoading && <Loader/>} */}
            { toasterDetails?.showToaster && <Toaster message={toasterDetails?.message} isError={toasterDetails?.isError}/> }
            <Router>
            {/* <Suspense fallback={<Loader/>}> */}
                <LayoutComponent>
                    <Routes>
                        <Route element={<LoginPage/>} path="/" />
                        <Route element={<RequireAuth><LandingPage/></RequireAuth>} path="/home" />
                        <Route element={<RequireAuth><MicroflowPage/></RequireAuth>} path="/microflow" />
                        <Route element={<RequireAuth><ConnectorPage/></RequireAuth>} path="/connector" />
                        <Route element={<RequireAuth><IndesignPage/></RequireAuth>} path="/indesign/listing" />
                        <Route element={<RequireAuth><IndesignPage/></RequireAuth>} path="/indesign/flow/:id" />
                        <Route element={<RequireAuth><PageNotFound/></RequireAuth>} path="*" />
                    </Routes>
                </LayoutComponent>
            {/* </Suspense> */}
                {/* <Route exact path="*" component={PageNotFound} /> */}
            </Router>
        </>
    )
}

export default Routers
