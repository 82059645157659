import "./style.scss";
import { Images } from "assets/Images";
import { ActionSettings } from "../action-settings";
import { useState } from "react";

export function ActionConfig(){

    const [ configureActionSettings, setConfigureActionSettings ] = useState(false);

    return (
        <div className="action-config">
            <div className="action-config__headers">
                <span>List of actions available to be enabled</span>
                <span>List of enabled actions</span>
            </div>
            <div className="action-config__row">
                <div className="action-config__col">
                    <div className="action-config__box">
                        <span>Cancel Application</span>
                        <span><img src={Images.addaction} alt=""/></span>
                    </div>
                    <div className="action-config__box">
                        <span>Cancel Application</span>
                        <span><img src={Images.addaction} alt=""/></span>
                    </div>
                </div>
                <div className="action-config__col">
                    <div className="action-config__box">
                        <span>Cancel Application</span>
                        <span className="action-config__box--group">
                            <span><img src={Images.settingsoption} alt="" onClick={()=>{setConfigureActionSettings(true)}}/></span>
                            <span><img src={Images.clearbtn} alt=""/></span>
                        </span>
                    </div>
                </div>
            </div>
            { configureActionSettings && <div className="action-config__overlay">
                <div className="action-config__overlay--box">
                    <ActionSettings closeOverlay={()=>{setConfigureActionSettings(false)}}/>
                </div>
            </div> }
        </div>
    )
}