import "./style.scss";
import { Images } from "assets/Images";
import { Checkbox, SelectMenu } from "components/generic";

export function SlaSettings(){
    return (
        <div className="sla-settings">
            <div className="sla-settings__group">
                <div>
                    <span><img src={Images.sla} alt=""/></span>
                    <span className="sla-settings__text">Service Level Arguments (SLA)</span>
                </div>
                <span className="sla-settings__description">Targets for SLAs can be set for this task. Please note that these are captured for MIS purposes. Enabling these targets does not activate TAT (Turn Around Time) calculations automatically</span>
            </div>
            <div className="sla-settings__row">
            <   div className="sla-settings__group">
                    <Checkbox label="Set target for overall time"/>
                    <span className="sla-settings__description">Overall time is sum of queue and assigned time</span>
                </div>
                <div className="sla-settings__select">
                    <SelectMenu label="Choose unit" placeholder="Enter time"/>
                </div>
                <div className="sla-settings__select">
                    <SelectMenu label="Choose unit" placeholder=""/>
                </div>
            </div>
        </div>
    )
}