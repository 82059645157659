import './style.scss';
import { Images } from 'assets/Images';
import * as moment from 'moment';
import { map } from 'lodash';
import { useEffect } from 'react';
import { useState } from 'react';
import { AUDIT_ACTIONS } from 'config';

export default function AuditComponent({ auditData, handleClosePopUp, handleLoadMore, showLoadMoreAuditsOption}){

    const [ selectedIndex, setSelectedIndex ] = useState(0);
    const [ changedFields, setChangedFields ] = useState([]);

    useEffect(()=>{
        setChangedFields(auditData?.[0]?.data || []);
    },[]);

    const handleSelectedField = (index) => {
        let fields = [];
        if(auditData[index]?.data?.length !== 0 || auditData[index]?.data != null){
            fields = auditData[index]?.data;
        }
        setChangedFields(fields);
        setSelectedIndex(index);
    }

    return (
        <div className="audit">
            <div className="audit-body">
                <div className="header">
                    <div className="header--right">
                        <img src={Images.audit2} alt=""/>
                        <label className="header__label">Audit</label>
                    </div>
                    <div className="header--right">
                        <span className="bar"></span>
                        <img src={Images.close} alt="" style={{cursor:'pointer'}} onClick={()=>{handleClosePopUp()}}/>
                    </div>
                </div>
                <div className="container">
                    <div className="audit__body">
                        <div className="audit__body__mainleftcontainer">
                            <div className="audit__body__leftcontanier">
                                <div className="audit__body__left">
                                    {map(auditData,(data,i)=>(
                                        <div key={i}>
                                            <div className="audit__row">
                                                <div className="audit__group">
                                                    <div className="audit__data--name">
                                                        { (data?.action === 'ADD UI ELEMENT' || data?.action === 'COPY' || data?.action === 'ADD COMPONENT' || data?.action === 'CREATE'|| data?.action === 'CREATE DRAFT'|| data?.action === 'CREATE DRAFT FLOW STEPS' || data?.action === 'ADD CONTAINER'|| data?.action === 'ADD DEPARTMENT' || data?.action === 'CREATE DRAFT TASK BASICS') && <span><img src={Images.auditAdd} alt=""/></span> }
                                                        { (data?.action === 'REORDER UI ELEMENT') && <span><img src={Images.auditReorder} alt=""/></span> }
                                                        { (data?.action === 'DELETE UI ELEMENT' || data?.action === 'DELETE') && <span><img src={Images.auditDelete} alt=""/></span> }
                                                        { (data?.action === 'EDIT CONTAINER' || data?.action === 'EDIT') && <span><img src={Images.auditEdit} alt=""/></span> }
                                                        { (data?.action === 'SUBMIT AND APPROVE') && <span><img src={Images.auditApproved} alt=""/></span> }
                                                        { (data?.action === 'SUBMIT AND APPROVE') && <span><img src={Images.auditCopy} alt=""/></span> }
                                                        { ((data?.action === 'SUBMIT FLOW STEPS FOR BASELINE') || (data?.action === 'BASELINE FLOW STEPS') || (data?.action === 'SUBMIT BASELINE FLOW STEPS')|| (data?.action === 'SUBMIT AND BASELINE')|| (data?.action === 'SUBMIT TASK BASICS FOR BASELINE') || (data?.action === 'BASELINE TASK BASICS')
                                                         || (data?.action === 'SUBMIT BASELINE TASK BASICS')|| (data?.action === 'BASELINE TASK DETAIL')|| (data?.action === 'SUBMIT TASK DETAIL FOR BASELINE') || (data?.action === 'SUBMIT BASELINE TASK DETAIL')|| (data?.action === 'SUBMIT AND BASELINE FLOW STEPS') || (data?.action === 'SUBMIT AND BASELINE TASK BASICS')) && <span><img src={Images.auditBaseline} alt=""/></span> }
                                                         { (data?.action === 'SENDBACK' || data?.action === 'SENDBACK FLOW STEPS' || data?.action === 'SENDBACK TASK BASICS' || data?.action === 'SENDBACK TASK DETAIL') && <span><img src={Images.auditSendback} alt=""/></span> }
                                                        { (data?.action === 'REJECT') && <span><img src={Images.auditReject} alt=""/></span> }
                                                        { (data?.action === 'REVIEW') && <span><img src={Images.auditReview} alt=""/></span> }
                                                        { (data?.action === 'INSERT') && <span><img src={Images.auditInsert} alt=""/></span> }
                                                        { (data?.action === 'SUBMIT FOR APPROVAL' || data?.action === 'SUBMIT AND APPROVE' || data?.action === 'SUBMIT APPROVE' || data?.action === 'APPROVE') && <span><img src={Images.auditSubmit} alt=""/></span> }
                                                        <span style={{'fontWeight': i===selectedIndex? '700':'500'}} onClick={()=>{handleSelectedField(i)}}>{AUDIT_ACTIONS[data?.action]}&nbsp;{data?.createdBy?.username}</span> 
                                                    </div>
                                                    <div className="version">V {data?.version}</div>
                                                </div>
                                                <div style={{'fontWeight': (i===selectedIndex)?'700':'500'}} className="audit__data--status">{moment(data?.createdTS).format('MM-DD-YYYY HH:mm')}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                { showLoadMoreAuditsOption && <div className="audit__loadmore">
                                    <span onClick={()=>{handleLoadMore()}}>Load more</span>
                                    <img src={Images.loadMoreArrow} onClick={()=>{handleLoadMore()}} alt=""/>
                                </div>}
                            </div>
                            {/* { loader && <div className="audit__body__loader">
                                <app-loader></app-loader>
                            </div> } */}
                        </div>
                        {(changedFields?.length===0 || changedFields == null) && <div className="audit__body__right">
                            <div>
                                <span><img src={Images.noAudit} alt=""/></span>
                            </div>
                        </div>}
                        { changedFields?.length>0 && <div className="audit__selected-listing">
                            {map(changedFields,(val,i)=>(
                                <div className="audit__body__right" key={i}>
                                    <span className="audit__body__right--title">{val?.fieldKey}</span>
                                    <span className="audit__body__right--desc">
                                        { val?.newValue && <span className="audit__body__right--desc--current">{JSON.stringify(val?.newValue)}</span> }
                                        { val?.oldValue && <span className="audit__body__right--desc--prev">{JSON.stringify(val?.oldValue)}</span> }
                                    </span>
                                </div>
                            ))}
                    </div> }
                    </div>
                </div>
            </div>
        </div>
    )
}
                                                       