import { createSlice } from "@reduxjs/toolkit";

export const loader = createSlice({
    name: "loader",
    initialState: {
        isLoading: false
    },
    reducers: {
        loaderReducer: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const { loaderReducer } = loader.actions;