import "./style.scss";
import { Images } from "assets/Images";
import { Button, Checkbox, Pagination, Search, ImageContainer } from "components/generic";
import { useQuery } from "@tanstack/react-query";
import * as api from 'actions/graphql';
import * as apiCall from "actions";
import { useState } from "react";
import { map } from "lodash";
import { ADMIN_API_AXIOS } from "utils/axios-interceptor";
import { toasterReducer } from "redux/reducers/toaster.reducer";
import { useDispatch } from "react-redux";

export function DependencyMap({ closeOverlay, microflowId, microflowVersion }){

    const [ listingData, setListingData ] = useState([]);
    const [ totalRecords, setTotalRecords ] = useState(0);
    const [ enableCheckAll, setEnableCheckAll ] = useState(false);
    const dispatch = useDispatch();

    const [selectedWorkFlows, setSelectedWorkFlows ] = useState([]);

    const [ widgetData, setWidgetData ] = useState({
        currentPageNumber: 1,
        searchString: '',
        recordsPerPage: 7,
        sort: null
    });

    const updateDependencyData = (response) => {
        setListingData(response?.data?.microFlowWorkFlowDependencyList?.data);
    }

    const updateDependencyCount = (response) => {
        setTotalRecords(response?.data?.microFlowWorkFlowDependencyList?.count)
    }

    const handleSearch = (searchString) => {
        setWidgetData({...widgetData, searchString: searchString, currentPageNumber: 1});
    }

    const handlePagination = (pageNumber) => {
        setWidgetData({...widgetData, currentPageNumber: pageNumber})
    }

    const handleSaveOnSuccess = (response) => {
        dispatch(toasterReducer({showToaster: true, message: response?.message, isError: response?.code!==200}));
        closeOverlay();
    }

    const handleSelectedValue = (event, element) => {
        const data = Object.assign([],selectedWorkFlows);
        if(event.target.checked){
            data.push(element);
        } else {
            let selectedIndex = null;
            data.forEach((workflow,index)=>{
                if(workflow.name === element.name){
                    selectedIndex = index;
                }
            })
            data.splice(selectedIndex,1);
        }
        setSelectedWorkFlows(data);
    }

    const handleSaveData = () => {
        selectedWorkFlows.forEach(workflow=>{
            workflow.microflowVersion = microflowVersion;
        })
        handleSave();
    }

    const handleSelectAll = (event) => {
        setEnableCheckAll(event.target.checked);
    }

    const { refetch: handleSave } = useQuery(['SAVE_ACTION'], ()=> apiCall.putMethodCall(ADMIN_API_AXIOS,`/v2/microflow-workflow/dependency`,{
        updateInfo: enableCheckAll?"all":selectedWorkFlows
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleSaveOnSuccess
    })

    //'affd33ba-77a7-48b9-9c0f-fa08df1c8919'
    useQuery(['FETCH_MICROFLOW_WORKFLOW_DETAILS',widgetData], ()=> api.getWorkFlowDataBasedOnMicroflow('/v2/graphql', {microflowId: microflowId}, widgetData.currentPageNumber,widgetData.recordsPerPage,widgetData.searchString, false), {
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: updateDependencyData
    })

    useQuery(['FETCH_MICROFLOW_WORKFLOW_COUNT',widgetData], ()=> api.getWorkFlowDataBasedOnMicroflow('/v2/graphql', {microflowId: microflowId}, widgetData.currentPageNumber,widgetData.recordsPerPage,widgetData.searchString, true), {
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: updateDependencyCount
    })

    return (
        <div className="dependencymap">
            <div className="dependencymap__group">
                <header>
                    <span className="dependencymap__text">Dependency Map</span>
                    <div className="dependencymap__save">
                        { listingData.length>0 && <><Button label="SAVE" secondary={true} handleClick={handleSaveData}></Button>
                        <Search searchFunction={handleSearch}/>
                        <span className="dependencymap__seperator"></span> </>}
                        <span><img src={Images.close} alt="" onClick={closeOverlay}/></span>
                    </div>
                </header>
                { listingData.length>0 && <section>
                    <div className="dependencymap__info">
                        <span><img src={Images.info} alt=""/></span>
                        <span>Select the flows to attach to the new version of microflow</span>
                    </div>
                    <div className="dependencymap__header">Workflow</div>
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {/* <Checkbox onChange={(event)=>{handleSelectAll(event)}}/> */}
                                    </th>
                                    <th><span>Name</span></th>
                                    <th><span>Version</span></th>
                                    <th><span>Type</span></th>
                                    <th><span>Step</span></th>
                                    <th><span>Trigger Point</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {map(listingData,(el,inde)=>
                                    <tr key={inde}>
                                        <td><Checkbox checked={enableCheckAll||selectedWorkFlows.includes(el)} onChange={(event)=>{handleSelectedValue(event, el)}}/></td>
                                        <td><span>{el?.workflowName}</span></td>
                                        <td><span>V {el?.version}</span></td>
                                        <td><span>{el?.isProcess?"Process":"Product"}</span></td>
                                        <td><span>{el?.stepName}</span></td>
                                        <td><span>{el?.triggerPoint}</span></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </section> }
                { listingData.length===0 && <div className="dependencymap__emptydata">
                    <ImageContainer label="There are no workflows attached to this microflow version" placeholderImg={Images.noworkflows}/>
                </div>}
            </div>
            {totalRecords>0 && <footer>
                <Pagination {...widgetData} totalRecords={totalRecords} handlePagination={handlePagination}/>
            </footer> }
        </div>
    )
}