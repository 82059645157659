import "./style.scss";
import { Images } from "assets/Images";
import { Checkbox, SelectMenu } from "components/generic";

export function SlaConfig(){
    return (
        <div className="sla-config">
            <header>
                <span><img src={Images.sla} alt=""/></span>
                <span>Service Level Arguments (SLA)</span>
            </header>
            <div className="sla-config__description">
                Targets for SLAs can be set for this task. Please note that these are captured for MIS purposes. Enabling these targets does not activate TAT (Turn Around Time) calculations automatically
            </div>
            <div className="sla-config__main">
                <div className="sla-config__row">
                    <div className="sla-config__col">
                        <Checkbox label="Set target for overall time"/>
                        <span className="sla-config__note">Overall time is sum of queue and assigned time</span>
                    </div>
                    <div className="sla-config__col">
                        <SelectMenu label="Choose unit"/>
                    </div>
                    <div className="sla-config__col">
                        <SelectMenu label="Over all Time" placeholder="Enter time"/>
                    </div>
                </div>
                <div className="sla-config__row">
                    <div className="sla-config__col">
                        <Checkbox label="Set target for queue time"/>
                        <span className="sla-config__note">Queue time refers to duration in which the task is waiting to be assigned,i.e. no work can happen in this state</span>
                    </div>
                    <div className="sla-config__col">
                        <SelectMenu label="Choose unit"/>
                    </div>
                    <div className="sla-config__col">
                        <SelectMenu label="Queued Time" placeholder="Enter time"/>
                    </div>
                </div>
                <div className="sla-config__row">
                    <div className="sla-config__col">
                        <Checkbox label="Set target for assigned time"/>
                        <span className="sla-config__note">Assigned time refers to duration in which the task is assigned to a person, i.e. actively being processed. e</span>
                    </div>
                    <div className="sla-config__col">
                        <SelectMenu label="Choose unit"/>
                    </div>
                    <div className="sla-config__col">
                        <SelectMenu label="Assigned Time" placeholder="Enter time"/>
                    </div>
                </div>
            </div>
        </div>
    )
}