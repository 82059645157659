import { Button, Checkbox, Radio, TextBox } from "components/generic";
import "./style.scss";
import { Images } from "assets/Images";
import { isObject, map } from "lodash";
import { useEffect, useState } from "react";

export function ActionPopup({ requestField, closeOverlay }){

    const [ formValue, setFormValue ] = useState(requestField?.value);

    useEffect(()=>{
        if(!requestField?.value) {
            setFormValue({selectedApplicant: "", enableDocuments: false, inputfield: []});
        } else {
            setFormValue(JSON.parse(formValue));
        }
    },[]);

    const handleSelectedValue = (event, key, value) => {
        event = isObject(event)?event?.target?.value:event;
        value[key] = event;
        setFormValue(structuredClone(formValue));
    }

    const handleSave = () => {
        requestField.value = JSON.stringify(formValue);
        closeOverlay();
    }

    const handleNewRow = (data) => {
        data.push({key:"", value:""});
        setFormValue(structuredClone(formValue));
    }

    const handleDelete = (field, index) => {
        field.splice(index,1);
        setFormValue(structuredClone(formValue));
    }

    return (
        <div className="action-popup">
            <div className="action-popup__main">
                <header>Webhook Attributes</header>
                { formValue && <section>
                    <div className="action-popup__row">
                        <div className="action-popup__item">
                            <Radio inputList={[{label:"Primary", value:"primary"},{label:"Co-applicant",value:"coapplicant"}]} value={formValue?.["selectedApplicant"]} id="selectedApplicant" handleInput={(event)=>{handleSelectedValue((event?.target?.value),"selectedApplicant",formValue)}} label="Choose Applicant" name="Type Of Microflow" mandatory={true}/>
                        </div>
                        <div className="action-popup__item">
                            <Checkbox label="Enable documents" checked={formValue?.enableDocuments} onChange={(event)=>{handleSelectedValue(event.target.checked, "enableDocuments",formValue)}}/>
                        </div>
                    </div>
                    {map(formValue?.inputfield,((field,index)=>
                        <div className="action-popup__row" key={index}>
                            <div className="action-popup__item">
                                <div className="action-popup__textbox"><TextBox placeholder="Key" type="text" onChange={(event)=>{handleSelectedValue(event,"key",field)}} value={field?.key}/></div>
                            </div>
                            <div className="action-popup__col action-popup__item">
                                <div className="action-popup__textbox"><TextBox placeholder="Value" type="text" onChange={(event)=>{handleSelectedValue(event,"value",field)}} value={field?.value}/></div>
                                <span><img src={Images.delete} alt="" onClick={()=>{handleDelete(formValue?.inputfield,index)}}/></span>
                            </div>
                        </div>
                    ))}
                    <div className="action-popup__btn" onClick={()=>{handleNewRow(formValue?.inputfield)}}>
                        <img src={Images.addrow} alt=""/>
                        <span>Add More</span>
                    </div>
                </section> }
            </div>
            <footer>
                <Button label="CANCEL" handleClick={closeOverlay}/>
                <Button label="SAVE" secondary={true} handleClick={handleSave}/>
            </footer>
        </div>
    )
}