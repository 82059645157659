import { Images } from "assets/Images";
import "./style.scss";
import { Radio, SelectMenu } from "components/generic";

export function Deviation(){
    return (
        <div className="deviation">
            <div className="deviation__info">
                <span><img src={Images.info} alt=""/></span>
                <span>Configure</span>
            </div>
            <div className="deviation__row">
                <div className="deviation__select"><SelectMenu label="Mode of Deviation"/></div>
                <div className="deviation__select"><SelectMenu label="Choose Document"/></div>
                <div className="deviation__select"><SelectMenu label="Choose Document Template"/></div>
            </div>
            <div className="deviation__row">
                <Radio label="Type" inputList={[{label:"Suggestion",value:"Suggestion"},{label:"Decision",value:"Decision"}]}/>
            </div>
            <div className="deviation__row">
                <div className="deviation__select"><SelectMenu label="Level"/></div>
                <div className="deviation__select"><SelectMenu label="Hierarchy"/></div>
                <div className="deviation__actions">
                    <span><img src={Images.delete} alt=""/></span>
                    <span>
                        <img src={Images.addrow} alt=""/>
                        <span className="deviation__text">Add step</span>
                    </span>
                </div>
            </div>
        </div>
    )
}