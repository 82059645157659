import "./style.scss";
import { Images } from "assets/Images";
import { Checkbox } from "components/generic";

export function QueryConfig(){
    return (
        <div className="query-config">
            <header>
                <span><img src={Images.query} alt=""/></span>
                <span>Queries</span>
            </header>
            <div className="query-config__description">
                You can make queries blocking, i.e. if there are open queries raised by the assignee, task should not be allowed to complete
            </div>
            <div>
                <Checkbox label="Enable Blocking"/>
            </div>
        </div>
    )
}