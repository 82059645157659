import './style.scss';
import { SelectMenu, TextBox, Button, Radio } from 'components/generic';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { useQuery } from '@tanstack/react-query';
import * as api from 'actions';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';
import { toasterReducer } from 'redux/reducers/toaster.reducer';
import { useDispatch } from 'react-redux';
import { Images } from 'assets/Images';
import { useState, useContext } from 'react';
import { ConnectorContext } from 'providers';
import { useEffect } from 'react';
import { decrypt } from 'utils/crypto';
import { isEmpty } from 'lodash';

export default function AddFlowPopup(props){
    const { handleClosePopUp } = props;
    const [ userDetails, setUserDetails ] = useState(null);
    let connectorValues = {};
    const dispatch = useDispatch();
    const connectorContext = useContext(ConnectorContext);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required")
            .required("Required")
            .min(5, 'Name should be min 5 to Max 40 characters')
            .max(40,'Name should be min 5 to Max 40 characters')
            .matches(/^[a-zA-Z0-9\s]+$/, "Special characters are not allowed"),
        providerName: Yup.string()
            .required("Required")
            .min(5, 'Provider name should be min 5 to Max 40 characters')
            .max(40,'Provider name should be min 5 to Max 40 characters'),
        type: Yup.string().required("Required"),
        httpMethod: Yup.string().required("Required"),
        description: Yup.string()
            .required("Required")
            .min(50, 'Description should be min 50 characters')
            .max(100, 'Description should be min 50 characters')
    });

    const [ successState, setSuccessState ] = useState(false);

    useEffect(()=>{
        const userData = decrypt(localStorage.getItem("_ud"));
        setUserDetails(userData);
    },[]);
    
    const handleCreateFlowOnSuccess = (response) => {
        if(response?.code !== 200){
            dispatch(toasterReducer({showToaster: true, message: response?.message, isError: true}));
        } else {
            setTimeout(() => {
                handleClosePopUp();
            }, 1000);
            setSuccessState(true);
        }
    }

    const handleCreateFlowError = (response) => {
    }

    const { refetch: handleAddNewFlow, isFetching } = useQuery(['ADD_NEW_FLOW'], ()=> api.postMethodCall(ADMIN_API_AXIOS,'/v2/connectors/create',connectorValues)
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (data)=>{handleCreateFlowOnSuccess(data)},
        onError: (data)=>{handleCreateFlowError(data)}
    })

    const handleButtonClick = (values) => {
        let reqObj = values;
        if(connectorContext.selectedConnectorDetail){
            reqObj = { ...connectorContext.selectedConnectorDetail,...values };
            delete reqObj.version;
            delete reqObj.status;
            delete reqObj.connectorId;
        }
        connectorValues = reqObj;
        handleAddNewFlow();
    }

    const handleDropdownChange = (value, key, setFieldValue) => {
        setFieldValue([key],value);
    }

    return (
      <div className="add-flow">
            { !isFetching && !successState && <Formik initialValues={{}} validationSchema={validationSchema} onSubmit={(values)=>{handleButtonClick(values)}}>
            {formik => {
                const { values, errors, isValid, dirty, handleChange, handleSubmit, touched, handleBlur, setFieldValue } = formik;
                return (
                    <Form>
                        <div>       
                            <div className="add-microflow__group">
                                <header>Add Flow</header>
                                <div>
                                    <div className="add-flow__row">
                                        <TextBox type="text" label="Name" value={values['name']} onChange={handleChange} onBlur={handleBlur} id="name" mandatory={true} errorMessage={errors['name']} error={touched['name'] && errors?.hasOwnProperty('name')} maxlength="40"/>
                                    </div>
                                    <div className="add-flow__row">
                                        <TextBox type="text" label="Description" value={values['description']} onChange={handleChange} onBlur={handleBlur} id="name" mandatory={true} errorMessage={errors['Description']} error={touched['description'] && errors?.hasOwnProperty('Description')} maxlength="40"/>
                                    </div>
                                    <div className="add-flow__row">
                                        <div className="add-flow__row--col">
                                            <Radio inputList={[{label:"Product", value:"Product"},{label:"Process",value:"Process"}]} id="type" label="Type"/>
                                        </div>
                                        <div className="add-flow__row--col">
                                            <SelectMenu label="Select product" required={true} value={values['type']} options={[]} onBlur={handleBlur} mandatory={true} onChange={(event)=>{handleDropdownChange(event,'type',setFieldValue)}} errorMessage={errors['type']} error={touched['type'] && errors?.hasOwnProperty('type')}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <footer>
                                <div className="add-flow__note"><strong>Note: </strong>Name, Type cannot be changed once added</div>
                                <div className="add-flow__actions">
                                    <Button label="CANCEL" handleClick={handleClosePopUp}/>
                                    <Button label="SAVE" secondary={true} handleClick={(e) => {handleSubmit(e)}} disabled={!(dirty && isValid)} />
                                </div>
                            </footer>
                        </div>
                    </Form>
                )}}
            </Formik> }
            { isFetching && !successState && <>
                    <div className="confirm-popup__group">
                        <img src={Images.shortloader} alt="" className="confirm-popup__loader"/>
                        <h5>{!isEmpty(connectorContext.selectedConnectorDetail)?'Copy':'Add'} in progress</h5>
                    </div>
            </> }
            { !isFetching && successState && <>
                <div className="confirm-popup__group">
                    <img src={Images.successtick} alt=""/>
                    <h5>{!isEmpty(connectorContext.selectedConnectorDetail)?'Copied':'Added'} successfully</h5>
                </div>
            </> }
      </div>
    )
}
