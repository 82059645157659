import "./style.scss";
import { Images } from "assets/Images";
import { Search, TabsListing, ImageContainer } from "components/generic";
import { TABS_LIST, } from 'config';
import { INDESIGN_ACTIONS, TABLE_ACTIONS } from "../../config";
import { useState } from "react";
import { map } from "lodash";
import AddFlowPopup from "../add-flow-popup";
import { useNavigate } from "react-router-dom";

export function Listing({ selectedTab, setSelectedTab }){

    const [ totalRecords, setTotalRecords ] = useState(1);
    const [ showAddNewPopUp, setShowAddNewPopUp ] = useState(false);
    const [ selectedType, setSelectedType ] = useState("Product");
    const navigate = useNavigate();

    const handleSearch = (searchString) => {
    }

    const handleSelectedType = (type) => {
        setSelectedType(type);
    }

    const handleSelectedAction = (actionName, routeName) => {
        switch(actionName){
            case "EDIT":
                navigate(`/indesign/flow/${12}`);
                break;
            default:
                break;
        }
    }

    return (
        <div className="indesign-listing">
            <header>
                <div className="indesign-listing__group">
                    <span><img src={Images.connectorIcon} alt=""/></span>
                    <h4>Flow</h4>
                </div>
                <Search searchFunction={handleSearch}/>
            </header>
            <div className="indesign-listing__body">
                <div>
                    <TabsListing tabList={TABS_LIST} selectedTab={selectedTab} setSelectedTab={setSelectedTab} actionsList={INDESIGN_ACTIONS} handleSelectedAction={handleSelectedAction} showProductToggle={true} selectedType={selectedType} handleSelectedType={handleSelectedType}/>
                    { totalRecords>0 && <div className="indesign-listing__main">
                        {map([1,2], (flowDetail,index)=>
                            <div className="indesign-listing__container" key={index}>
                                <div className="indesign-listing__header">
                                    <span className="indesign-listing__header--text">Test for approval</span>
                                    <div className="indesign-listing__actions">
                                        {map(TABLE_ACTIONS, (action,index)=>
                                            <span key={index}>
                                                <img src={action.img} alt="" onClick={()=>{handleSelectedAction(action.label,"routeName")}}/>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="indesign-listing__description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curab itur odio lacus, lobortis sit amet eu.
                                </div>
                                <div className="indesign-listing__header">
                                    <span className="indesign-listing__header--activity">2 days ago</span>
                                    <span className="indesign-listing__header--version">V1.0</span>
                                </div>
                            </div>
                        )}
                    </div>}
                </div>
                { totalRecords===0 && <ImageContainer label={'Currently, there are no flows with this name.'} placeholderImg={Images.nodata}/> }
            </div>
            { <span className="indesign-listing__addbtn" onClick={()=>{setShowAddNewPopUp(true)}}><img src={Images.addBtn} alt=""/></span> }
            {showAddNewPopUp && <div className="connector-listing__popup">
                <div className="connector-listing__popup--content">
                    <AddFlowPopup handleClosePopUp={()=>{setShowAddNewPopUp(false);}}/>
                </div>
            </div>}
        </div>
    )
}