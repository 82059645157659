import { Button, TextBox } from "components/generic";
import { Images } from "assets/Images";
import { isObject, map } from "lodash";
import React, { useEffect, useState } from "react";
import { toasterReducer } from "redux/reducers/toaster.reducer";
import { useDispatch } from "react-redux";

export function PopulateMultidataPopup({ requestField, closeOverlay }){

    const [ populateMultiDataObj, setPopulateMultiDataObj ] = useState({"dataFields" : [],"recordFields" : []});
    const dispatch = useDispatch();

    useEffect(()=>{
        if(requestField?.value)  {
            requestField.value && JSON.parse(requestField.value).forEach((row,index)=>{
                const arr = [];
                Object.keys(row).forEach(record=>{
                    if(index === 0){
                        populateMultiDataObj.dataFields.push({key:record});
                    }
                    arr.push({record: row[record]});
                })
                populateMultiDataObj.recordFields.push(arr);
            });
            setPopulateMultiDataObj(structuredClone(populateMultiDataObj));
        }
    },[]);

    const addDataField = () => {
        populateMultiDataObj.dataFields.push({"key":""});
        populateMultiDataObj.recordFields.forEach(data=>{data.push({"record":""})})
        setPopulateMultiDataObj(structuredClone(populateMultiDataObj));
    }

    const deleteDataField = (index) => {
        populateMultiDataObj.dataFields.splice(index,1);
        populateMultiDataObj.recordFields.forEach(data=>{data.splice(index,1)});
        setPopulateMultiDataObj(structuredClone(populateMultiDataObj));
    }

    const handleSelectedValue = (event, key, value) => {
        event = isObject(event)?event?.target?.value:event;
        value[key] = event;
        setPopulateMultiDataObj(structuredClone(populateMultiDataObj));
    }

    const handleSelectedHeader = (event, index) => {
        const val = populateMultiDataObj.dataFields
        val[index]["key"] = event?.target?.value;
        setPopulateMultiDataObj(structuredClone(populateMultiDataObj));
    }

    const addNewMultiDataRow = () => {
        const length = populateMultiDataObj.dataFields.length;
        const multiDataRecords = Array(length).fill({"record":""});
        populateMultiDataObj.recordFields.push(JSON.parse(JSON.stringify(multiDataRecords)));
        setPopulateMultiDataObj(structuredClone(populateMultiDataObj));
    }

    const dataMultidataRow = (index) => {
        populateMultiDataObj.recordFields.splice(index,1);
        setPopulateMultiDataObj(structuredClone(populateMultiDataObj));
    }

    const saveMultiDataValues = () => {
        try {
            const multiDataArray = [];
            let isInvalid = false;
            populateMultiDataObj.dataFields.forEach(data=>{if(!data) isInvalid = true;})
            if(isInvalid){
                dispatch(toasterReducer({showToaster: true, message: "Keys are mandatory", isError: true}));
                return ;
            }
            populateMultiDataObj.recordFields.forEach(rowData=>{
                const obj = {}
                rowData.forEach((recordData,index)=>{
                    obj[populateMultiDataObj.dataFields[index].key]=recordData.record;
                })
                multiDataArray.push(obj);
            })
            requestField.value = JSON.stringify(multiDataArray);
            closeOverlay();
        } catch (error) {
            console.log(error,'error');
        }
    }

    return (
        <div className="action-popup">
            <div className="action-popup__main">
                <header>Map Fields</header>
                { <section>
                    <h3>Define multidata</h3>
                    <div className="action-popup__rowgroup">
                        {map(populateMultiDataObj.dataFields,((field,index)=>
                            <div className="action-popup__row action-popup__seperator--right" key={index}>
                                <div className="action-popup__col action-popup__item">
                                    <div className="action-popup__textbox"><TextBox placeholder="Key" type="text" onChange={(event)=>{handleSelectedHeader(event,index)}} value={field?.key}/></div>
                                    <span><img src={Images.delete} alt="" onClick={()=>{deleteDataField(index)}}/></span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="action-popup__btn" onClick={addDataField}>
                        <img src={Images.addrow} alt=""/>
                        <span>Add Field</span>
                    </div>
                    <h3>Add records</h3>
                    {map(populateMultiDataObj.recordFields,((field,index)=>
                        <div key={index} className="action-popup__row" style={{gap:"40px"}}>
                            {map(field,(rowData,indee)=>
                                <div className="action-popup__rowgroup" key={indee}>
                                    <div className="action-popup__col action-popup__item">
                                        <div className="action-popup__textbox"><TextBox placeholder="Key" type="text" onChange={(event)=>{handleSelectedValue(event,"record",rowData)}} value={rowData?.record}/></div>
                                        { field.length===indee+1 && <span><img src={Images.delete} alt="" onClick={()=>{dataMultidataRow(index)}}/></span> }
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                    <div className="action-popup__btn" onClick={addNewMultiDataRow}>
                        <img src={Images.addrow} alt=""/>
                        <span>Add Field</span>
                    </div>
                </section> }
            </div>
            <footer>
                <div className="action-popup__note"><strong>Note: </strong>Above keys will be resolved at server level (not from screen). Hence, the keys should be saved before invoking the action. Design your process accordingly.</div>
                <div className="action-popup__actions">
                    <Button label="CANCEL" handleClick={closeOverlay}/>
                    <Button label="SAVE" secondary={true} handleClick={saveMultiDataValues}/>
                </div>
            </footer>
        </div>
    )
}