import { Button, TextBox } from "components/generic";
import { Images } from "assets/Images";
import { isObject, map } from "lodash";
import { useEffect, useState } from "react";

export function ValuationPopup({ requestField, closeOverlay }){

    const [ formValue, setFormValue ] = useState((requestField?.value));

    useEffect(()=>{
        if(!requestField?.value) {
            setFormValue([]);
        } else {
            setFormValue(JSON.parse(formValue));
        }
    },[]);

    const handleSelectedValue = (event, key, value) => {
        event = isObject(event)?event?.target?.value:event;
        value[key] = event;
        setFormValue(structuredClone(formValue));
    }

    const handleSave = () => {
        requestField.value = JSON.stringify(formValue);
        closeOverlay();
    }

    const handleNewRow = (data) => {
        data.push({key:"", newKey:""});
        setFormValue(structuredClone(formValue));
    }

    const handleDelete = (field, index) => {
        field.splice(index,1);
        setFormValue(structuredClone(formValue));
    }

    return (
        <div className="action-popup">
            <div className="action-popup__main">
                <header>Map Fields</header>
                { formValue && <section>
                    {map(formValue,((field,index)=>
                        <div className="action-popup__row" key={index}>
                            <div className="action-popup__item">
                                <div className="action-popup__textbox"><TextBox placeholder="Key" type="text" onChange={(event)=>{handleSelectedValue(event,"key",field)}} value={field?.key}/></div>
                            </div>
                            <div className="action-popup__col action-popup__item">
                                <div className="action-popup__textbox"><TextBox placeholder="New Key" type="text" onChange={(event)=>{handleSelectedValue(event,"newKey",field)}} value={field?.newKey}/></div>
                                <span><img src={Images.delete} alt="" onClick={()=>{handleDelete(formValue,index)}}/></span>
                            </div>
                        </div>
                    ))}
                    <div className="action-popup__btn" onClick={()=>{handleNewRow(formValue)}}>
                        <img src={Images.addrow} alt=""/>
                        <span>Add More</span>
                    </div>
                </section> }
            </div>
            <footer>
                <div className="action-popup__note"><strong>Note: </strong>Above keys will be resolved at server level (not from screen). Hence, the keys should be saved before invoking the action. Design your process accordingly.</div>
                <div className="action-popup__actions">
                    <Button label="CANCEL" handleClick={closeOverlay}/>
                    <Button label="SAVE" secondary={true} handleClick={handleSave}/>
                </div>
            </footer>
        </div>
    )
}