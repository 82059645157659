import './style.scss';
import { Images } from 'assets/Images';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { toasterReducer } from 'redux/reducers/toaster.reducer';
import { useDispatch } from 'react-redux';

export default function Pagination(props) {
    const { currentPageNumber, totalRecords, widgetName, recordsPerPage, handlePagination } = props;
    const [ totalPages, setTotalPages ] = useState(0);
    const [ pageNumber,  setPageNumber ] = useState(currentPageNumber);
    const dispatch = useDispatch();

    useEffect(()=>{
        let totalValue = Math.floor((totalRecords / recordsPerPage));
        if(totalRecords % recordsPerPage !== 0){
            totalValue++;
        };
        setTotalPages(totalValue);
    }, [totalRecords, recordsPerPage]);

    useEffect(()=>{
        setPageNumber(currentPageNumber);
    },[currentPageNumber])

      /**
   * @description handle if the entered input is valid
   * @param event 
   */
  const handleCheckIsValidInput = (event) => {
    try {
      if(event?.data && pageNumber + event?.data > totalPages){
        dispatch(toasterReducer({showToaster: true, message: 'Entered value exceeds the total pages', isError: true}));
        event.preventDefault();
      }
    } catch(error){
      console.log(error,'error');
    }
  }

  /**
   * @description handle page change event
   * @param pageNumber 
   */
  const onPageChange = (pageNumber) => {
    pageNumber = pageNumber?.target?.value;
    try {
        let pageValue;
        if(pageNumber <= totalPages){
            pageValue = pageNumber;
        }
        if(pageNumber > totalPages){
            pageValue = totalPages;
            dispatch(toasterReducer({showToaster: true, message: 'Entered value exceeds the total pages', isError: true}));
        }
        if(pageNumber===0){
            pageValue = 1;
        }
        if(Number(pageValue)){
            handlePagination(Number(pageValue));
            setPageNumber(pageValue)
        } else {
            setPageNumber('');   
        }
    } catch(error){
      console.log(error,'error');
    }
  }

    return (
        <div className="pagination">
            <div className="pagination__pagebox">
                <div className="pagination__pagebox--page">Page</div>
            </div>
            <div className="pagination__inputbox">
                <input min="1" className="pagination__inputbox--input" type="number" value={pageNumber} onChange={(event)=>{onPageChange(event)}} onBeforeInput={(event)=>{handleCheckIsValidInput(event)}}/>
            </div>
            <div className="pagination__totalpagebox">
                <div className="pagination__totalpagebox--totalpage">
                    of {totalPages}
                </div>
            </div>
            <div className="pagination__arrowbox">
                <div onClick={()=>handlePagination(pageNumber-1, widgetName)} className={classNames({"pagination__arrowbox--leftarrowdisable":pageNumber===1, "pagination__arrowbox--leftarrow": true})}>
                    <img src={Images.backArrow} alt="" />
                </div>
                <div onClick={()=>handlePagination(pageNumber+1, widgetName)} className={classNames({"pagination__arrowbox--rightarrowdisable":totalPages===pageNumber, "pagination__arrowbox--rightarrow": true})}>
                    <img src={Images.forwardArrow} alt="" />
                </div>
            </div>
        </div>
    )
}