import { Images } from "assets/Images";

export const PROFILE_COLORS = ['#B983FF', '#D3E4CD', '#FF87CA', '#94DAFF', '#DED9C4', '#FFDEFA', '#FCFFA6', '#F6AE99', '#F0D9FF', '#CDF0EA', '#BDD2B6', '#046582', '#92967D', '#FDFFBC', '#056676'];

export const MODULES = [
    {
        name: 'Microflow',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        icon: Images.microflow,
        path: '/microflow',
        role: 'MICROFLOW',
    },
    {
        name: 'Connectors',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
        icon: Images.connector,
        path: '/connector',
        role: 'CONNECTOR'
    },
    // {
    //     name: 'Flow',
    //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
    //     icon: Images.designer,
    //     path: '/indesign/listing',
    //     role: 'CONNECTOR'
    // }
];

export const TASK_ACTIONS_LIST = [ "SUBMIT FOR REVIEW" ];

export const TABS_LIST = ['Draft','Review','Published'];

export const REVIEWER_ACTIONS_LIST = [ "SUBMIT FOR REVIEW", "SEND FOR REWORK" ];

export const APPROVER_ACTIONS_LIST = [ "APPROVE & PUBLISH", "SEND FOR REWORK" ];

export const AUDIT_ACTIONS = {
    "REVIEW": "Reviewed By",
    "APPROVE": "Approved By",
    "REWORK": "Sent back By",
    "CREATE": "Created By",
    "EDIT": "Edited By",
    "REVERT": "Sent back By",
    "SENDBACK": "Sent Back By",
    "DELETE": "Deleted By",
    "CREATE DRAFT": "Created Draft By",
    "INSERT": "Inserted By",
    "COPY": "Copied By",
    "SENTBACK": "Sent back By",
    "ADD CONTAINER": "Added Container By",
    "SUBMIT FOR APPROVAL": "Submitted For Approval By",
    "SUBMIT AND APPROVE": "Submitted And Approved By",
    "ADD UI ELEMENT": "Added UI Element By",
    "DELETE UI ELEMENT": "Deleted UI Element By",
    "UPDATE UI ELEMENT": "Updated UI Element By",
    "REORDER UI ELEMENT": "Reordered UI Element By",
    "SUBMIT APPROVE": "Submitted and Approved By",
    "SUBMIT FLOW STEPS FOR BASELINE": "Flow Steps Submitted By",
    "BASELINE FLOW STEPS": "Flow Steps Baselined By",
    "SENDBACK FLOW STEPS": "Flow Steps Sent back By",
    "SUBMIT BASELINE FLOW STEPS": "Flow Steps Submitted And Baselined By",
    "CREATE DRAFT FLOW STEPS": "Created Draft for Flow Steps By ",
    "REORDER CONATAINER": "Reorder container By",
    "SUBMIT AND BASELINE": "SUBMIT AND BASELINE",
    "SUBMIT AND BASELINE FLOW STEPS":"Submit and baseline flow step by",
    "EDIT CONTAINER": "EDIT CONTAINER",
    "ADD COMPONENT": "ADD COMPONENT",
    "ADD DEPARTMENT": "ADD DEPARTMENT",
    "SUBMIT TASK BASICS FOR BASELINE": "Submitted task basics for baseline by",
    "SENDBACK TASK BASICS": "Sendback task basic by",
    "BASELINE TASK BASICS": "Baseline task basic by",
    "SUBMIT BASELINE TASK BASICS": "Submit baseline task basic",
    "CREATE DRAFT TASK BASICS": "Created draft task basic by",
    "SUBMIT TASK DETAIL FOR BASELINE": "Submit task details for baseline by",
    "SENDBACK TASK DETAIL": "SENDBACK TASK DETAIL",
    "BASELINE TASK DETAIL": "BASELINE TASK DETAIL",
    "SUBMIT BASELINE TASK DETAIL": "SUBMIT BASELINE TASK DETAIL",
    "CREATE DRAFT TASK DETAIL": "CREATE DRAFT TASK DETAIL",
    "SUBMIT AND BASELINE TASK BASICS":"Submitted and baselined task basics by"
}