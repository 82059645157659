import './style.scss';
import classNames from 'classnames';
import { map } from 'lodash';
import { Tooltip } from 'components/generic';
import { useState } from 'react';

export default function TabsListing({ tabList, selectedTab, setSelectedTab, actionsList, handleSelectedAction, showProductToggle, selectedType, handleSelectedType }){

    const [ hoverData, setHoverData ] = useState(null);

    return (
        <div className="headertab">
            <div className="headertab__tab">
                {map(tabList,(tab,ind)=>
                    <span key={ind} className={classNames({"headertab__tab--highlight":selectedTab===tab})} onClick={()=>setSelectedTab(tab)}>{tab}</span>
                )}
            </div>
            <div className="headertab__actions">
                {map(actionsList,(action,ind)=>
                    <span className="headertab__actions--group" key={ind} onClick={()=>{handleSelectedAction(action.label)}}>
                        <img src={action?.img} alt="" onMouseEnter={()=>setHoverData(action?.label)} onMouseLeave={()=>setHoverData(null)}/>
                        { hoverData===action?.label && <span className="headertab__tooltip"><Tooltip value={action.label}/></span> }
                    </span>
                )}
                { showProductToggle && <div className="headertab__toggle">
                    {map(["Product","Process"],(type,ind)=>
                        <div className={classNames({"headertab__toggle--highlight":selectedType===type})} onClick={()=>{handleSelectedType(type)}} key={ind}>{type}</div>
                    )}
                </div> }
            </div>
        </div>
    )
}
