import { Button, SelectMenu } from "components/generic";
import { useEffect, useState, useRef } from "react";
import { useQuery } from '@tanstack/react-query';
import * as api from 'actions';
import { OLD_ADMIN_API_AXIOS } from 'utils/axios-interceptor';
import { LookupFieldKey, LookupTypesOptions } from "../../../config/index.js";

export function LookupPopup({ closeOverlay, fetchReqestResponseData }){

    const [ lookupCategories, setLookupCategories ] = useState([]);
    const [ lookupSubCategories, setLookupSubCategories ] = useState([]);
    const [ selectedCategory, setSelectedCategory ] = useState("");
    const [ selectedSubCategory, setSelectedSubCategory ] = useState("");
    const [ selectedType, setSelectedType] = useState("");
    const lookupConfigMapRef = useRef(null);

    useEffect(()=>{
    },[]);

    const populateLookup = (response) => {
        const lookupConfigs = response['data'];
        let lookupConfigsMap = {};
        if (lookupConfigs && Array.isArray(lookupConfigs) && lookupConfigs.length > 0) {
            for (const lookup of lookupConfigs) {
                const category = lookup.setupCategory;
                const subCategoriesConfigs = lookup.configs;
                for (const subCategoriesConfig of subCategoriesConfigs) {
                    const subCategory = subCategoriesConfig.setupSubCategory;
                    if (!lookupConfigsMap) {
                        lookupConfigsMap = {};
                    }
                    if (!lookupConfigsMap[category]) {
                        lookupConfigsMap[category] = [];
                    }
                    const lookupCategoryConfigs = lookupConfigsMap[category];
                    if (!lookupCategoryConfigs.includes(subCategory)) {
                        lookupCategoryConfigs.push(subCategory);
                    }
                }
            }
            const tempLookupCategories = [];
            lookupConfigMapRef.current = lookupConfigsMap;
            Object.keys(lookupConfigsMap).forEach(config=>{
                tempLookupCategories.push({label:config, value: config});
            });
            setLookupCategories(tempLookupCategories);        
        }
    }

    const handleChange = (event, type) => {
        if(type === "category") {
            setSelectedCategory(event);
            const tempLookupSubCategories = [];
            lookupConfigMapRef.current?.[event].forEach(config=>{
                tempLookupSubCategories.push({label:config, value: config});
            });
            setLookupSubCategories(tempLookupSubCategories);
        } else {
            setSelectedSubCategory(event);
        }
    }

    const getLookupConfig = () => {
        if (!(selectedCategory && selectedSubCategory)) {
            return;
        }
        getSetupConfig();
    }

    const populateSetupConfig = (response) => {
        const requestMap = [];
        const responseMap = [];
        requestMap.push(
            {key: LookupFieldKey.CATEGORY, value: selectedCategory, static: true, readOnly: true},
            {key: LookupFieldKey.SUB_CATEGORY, value: selectedSubCategory, static: true, readOnly: true}
        );
        if (response && response.data) {
            for (const field of response.data.fields) {
                if (field.field_type_details.lookupEnabled) {
                    // Output field
                    responseMap.push({key: "", value: field.key});
                } else {
                    // Input field
                    requestMap.push({key: field.key, value: "", static: false});
                }
            }
        }
        fetchReqestResponseData({requestMap,responseMap})
        closeOverlay();
    }

    useQuery(['FETCH_LOOKUP_LISTING_DATA'], ()=> api.getMethodCall(OLD_ADMIN_API_AXIOS,`v3/component/setup_configs?skipFields=&skipTypes=&allowedTypes=LOOKUP`), { refetchOnWindowFocus: false,  enabled: true,onSuccess: (data)=>populateLookup(data) });

    const { refetch: getSetupConfig } = useQuery(['GET_SETUP_CONFIG'], ()=> api.getMethodCall(OLD_ADMIN_API_AXIOS,`v3/component/setup_config/${selectedCategory}/${selectedSubCategory}`), { refetchOnWindowFocus: false,  enabled: false,onSuccess: (data)=>populateSetupConfig(data) });

    return (
        <div className="action-popup">
            <div className="action-popup__main">
                <header>Select Lookup</header>
                { <section>
                    <div className="action-popup__container">
                        <div className="action-popup__row">
                            <SelectMenu label="Type" value={selectedType} mandatory={true} options={LookupTypesOptions} onChange={(event)=>{setSelectedType(event)}}/>
                        </div>
                        <div className="action-popup__row">
                            <SelectMenu label="Lookup category" value={selectedCategory} mandatory={true} options={lookupCategories} onChange={(event)=>{handleChange(event,"category")}}/>
                        </div>
                        <div className="action-popup__row">
                            <SelectMenu label="Lookup sub category"  value={selectedSubCategory} mandatory={true} options={lookupSubCategories} onChange={(event)=>{handleChange(event,"subCategory")}}/>
                        </div>
                    </div>
                </section> }
            </div>
            <footer>
                <Button label="CANCEL" handleClick={closeOverlay}/>
                <Button label="SAVE" secondary={true} handleClick={getLookupConfig}/>
            </footer>
        </div>
    )
}