
export class MicroflowAction {
    constructor(name, description,type, requestMap, responseMap, parameterMap) { 
        this.name = name;
        this.description = description;
        this.type = type;
        this.requestMap = requestMap;
        this.responseMap = responseMap;
        this.parameterMap = parameterMap
    }
}
