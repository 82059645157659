import { Range } from 'react-range';
import { useEffect, useState } from 'react';
import { Images } from 'assets/Images';
import "./style.scss";

export function UiRange({ rangeValue, setRangeValue }){

    const [ value, setValue ] = useState([50]);

    useEffect(()=>{
        setValue([rangeValue]);
    },[]);

    return (
        <div className="ui-range">
            <span><img src={Images.minus} alt="" onClick={()=>{setValue([value?.[0]-1]);setRangeValue(value?.[0]-1)}}/></span>
            <Range
                step={1}
                min={50}
                max={200}
                values={value}
                onChange={(values) => {setValue(values);setRangeValue(values?.[0])}}
                renderTrack={({ props, children }) => (
                <div
                    {...props}
                    style={{
                    ...props.style,
                    height: '2px',
                    width: '140px',
                    background: '#D2D2D2'
                    }}
                >
                    {children}
                </div>
                )}
                renderThumb={({ props }) => (
                <div
                    {...props}
                    style={{
                    ...props.style,
                    height: '10px',
                    width: '10px',
                    borderRadius: '50%',
                    background: '#0F3FE0'
                    }}
                />
                )}
            />
            <span><img src={Images.plus} alt="" onClick={()=>{setValue([value?.[0]+1]);setRangeValue(value?.[0]+1)}}/></span>
            <span className="ui-range__text">{value?.[0]}%</span>
        </div>
    )
}