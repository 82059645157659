import "./style.scss";
import { FieldListing } from "../field-listing";
import GridLayout from "react-grid-layout";
import { Images } from "assets/Images";
import { map } from "lodash";
import React, { useState } from "react";


export function SectionConfig(){

    const [ layout, setlayout ] = useState([]);

    // [ 
    //     { // rowFields
    //         fields: [ // columnFields
    //             {
    //                 fields: [
    //                     {
    //                         name: "textbox"
    //                     },
    //                     {
    //                         name: "textbox"
    //                     }
    //                 ] // fields
    //             },
    //             {
    //                 fields: [
    //                     {
    //                         name: "textbox"
    //                     },
    //                     {
    //                         name: "textbox"
    //                     }
    //                 ] // fields
    //             }
    //         ]
    //     },
    //     { // rowFields
    //         fields: [ // columnFields
    //             {
    //                 fields: [
    //                     {
    //                         name: "textbox"
    //                     },
    //                     {
    //                         name: "textbox"
    //                     }
    //                 ] // fields
    //             }
    //         ]
    //     }
    // ]

    const [ containerData, setContainerData ] = useState([]);


    const addLayout = () => {
        layout.push({ x: 0, y: 0, w: 1, h: 1 });
        setlayout(structuredClone(layout));
    }

    const handleAddRow = () => {
        containerData.push({fields:[{}]});
        setContainerData(structuredClone(containerData));
    }

    const handleAddColumn = (field) => {
        console.log(containerData);
        field.push({fields:[]});
        console.log(field);
        setContainerData(structuredClone(containerData));
    }

    return (
        <div className="section-config">
                <div className="section-config__col">
                    <FieldListing/>
                </div>
                <div className="section-config__col">
                    <GridLayout
                    className="layout"
                    layout={layout}
                    cols={12}
                    rowHeight={30}
                    width={1200}
                    >
                        {map(layout,(val,index)=>
                            <div key={index} className="section-config__container">
                                <header>
                                    <span>Container 1</span>
                                    <span className="section-config__group">
                                        <span><img src={Images.settingsoption} alt=""/></span>
                                        <span><img src={Images.delete} alt=""/></span>
                                    </span>
                                </header>
                                <div className="mycolumn" style={{borderRight:"none"}}>
                                    {map(containerData,(rowField,ind1)=>
                                        <div key={ind1} className="myrow">
                                                <div className="section-config__row">
                                                    <div className="section-config__addrow">
                                                        <div className="section-config__addrow--line" onClick={()=>{handleAddColumn(rowField.fields)}}></div>
                                                    </div>
                                                    <div className="section-config__addcolumn">
                                                        <div className="section-config__addcolumn--line" onClick={()=>{handleAddColumn(rowField.fields)}}></div>
                                                    </div>
                                                    {map(rowField.fields,(columnFields,ind2)=>
                                                        <div key={ind2} className="mycolumn">
                                                            <>
                                                                {map(columnFields.fields,(field,ind3)=>
                                                                    <React.Fragment>{'field'}</React.Fragment>
                                                                )}
                                                            </>
                                                        </div>
                                                    )}
                                                </div>
                                            
                                        </div>
                                    )}
                                </div>
                                <div className="section-config__group">
                                    <span onClick={handleAddRow}><img src={Images.addrow} alt=""/></span>
                                    <span className="section-config__text" onClick={handleAddRow}>Add Row</span>
                                </div>
                            </div>
                        )}
                    </GridLayout>
                </div>
                <span className="section-config__addbtn" onClick={addLayout}><img src={Images.addBtn} alt=""/></span>
        </div>
    )
}