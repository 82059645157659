import "./style.scss";
import { INTEGRATION_TABS_LIST } from "../../config";
import { useState } from "react";
import { Images } from "assets/Images";
import classNames from "classnames";
import { map } from "lodash";
import { MicroflowActions } from "../../config/microflowAction.js";
import { useQuery } from '@tanstack/react-query';
import * as apicall from 'actions/graphql';
import { Search } from "components/generic";

export function AddIntegration({ closeOverlay, handleSelectedRow }){

    const [ selectedTab, setSelectedTab ] = useState(INTEGRATION_TABS_LIST[0]);
    const [ connectorListingData, setConnectorListingData ] = useState([]);
    const [ selectedDataList, setSelectedDataList ] = useState(MicroflowActions);
    const [ searchValue, setSearchValue ] = useState("");
    const populateConnectorData = (response) => {
        setConnectorListingData(response?.data?.connectorDetails?.data ?? []);
    }

    const handleSelectedTab = (tab) => {
        setSelectedTab(tab);
        handleSearch(searchValue, tab);
    }

    const ImageSelector = (index) => {
        switch(index%6){
            case 0:
                return Images.shape1;
            case 1:
                return Images.shape2;
            case 2:
                return Images.shape3;
            case 3:
                return Images.shape4;
            case 4:
                return Images.shape5;
            case 5:
                return Images.shape6;
            default:
                break;
        }
    }

    const handleSearch = (searchString, tab) => {
        setSearchValue(searchString);
        const searchArray = [];
        switch(tab){
            case INTEGRATION_TABS_LIST[0]:
                map(MicroflowActions,(action)=>{
                    if(action.name.toLowerCase().includes(searchString.toLowerCase())){
                        searchArray.push(action);
                    }
                })
                break;
            case INTEGRATION_TABS_LIST[1]:
                map(connectorListingData,(connector)=>{
                    if(connector.name.toLowerCase().includes(searchString.toLowerCase())){
                        searchArray.push(connector);
                    }
                })
                break;
            default:
                break;
        }
        setSelectedDataList(searchArray);
    }

    useQuery(['FETCH_CONNECTOR_LISTING_DATA'], ()=> apicall.fetchPublishedConnectors(`/v2/graphql`), { refetchOnWindowFocus: false,  enabled: true,onSuccess: (data)=>populateConnectorData(data) });

    return (
        <div className="add-integration">
            <header>
                <span className="add-integration__text">Add Integration</span>
                <div className="add-integration__save">
                    <Search searchFunction={(value)=>{handleSearch(value,selectedTab)}}/>
                    <span className="add-integration__seperator"></span>
                    <span><img src={Images.close} alt="" onClick={closeOverlay}/></span>
                </div>
            </header>
            <section>
                <div className="add-integration__tabs">
                    {map(INTEGRATION_TABS_LIST,(tab,index)=>
                        <span className={classNames({"add-integration__tabs--highlight": selectedTab===tab,})} key={index} onClick={()=>{handleSelectedTab(tab)}}>{tab}</span>
                    )}
                </div>
                { selectedTab === "Connectors" && <div  className="add-integration__tab">
                    {map(selectedDataList,(connectorData,ind)=>
                        <div className="add-integration__row" key={ind}>
                            <div className="add-integration__row--group">
                                <div className="add-integration__row--section">
                                    <span><img src={Images.connectorsymbol} alt=""/></span>
                                    <span className="add-integration__row--line"></span>
                                    <div className="add-integration__row--col">
                                        <span>{connectorData?.name}(v{connectorData?.version})</span>
                                        <div className="add-integration__row--description">{connectorData?.description??'-'} </div>
                                    </div>
                                </div>
                                <div className="add-integration__row--section">
                                    <span><img src={Images.addnewrow} alt="" onClick={()=>{handleSelectedRow(connectorData,"CONNECTORS")}}/></span>
                                </div>
                            </div>
                        </div>
                    )}
                </div> }
                { selectedTab === "Action" && <div className="add-integration__tab">
                        {map(selectedDataList,(action,ind)=>
                            <div className="add-integration__row" key={ind} style={{background:(ind%2===0)?'#F2F5FB':'#F1F1F1'}}>
                                <div className="add-integration__row--group">
                                    <div className="add-integration__row--section">
                                        <span>
                                            <img src={ImageSelector(ind)} alt=""/>
                                        </span>
                                        <span className="add-integration__row--line"></span>
                                        <div className="add-integration__row--col">
                                            <span>{action?.name}</span>
                                            <div className="add-integration__row--description">{action?.description??'-'}</div>
                                        </div>
                                    </div>
                                    <div className="add-integration__row--section">
                                        <span><img src={Images.addnewrow} alt="" onClick={()=>{handleSelectedRow(action,"ACTIONS")}}/></span>
                                    </div>
                                </div>
                           </div>
                        )}
                </div> }
            </section>
        </div>
    )
}