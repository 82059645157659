import "./style.scss";
import { Images } from "assets/Images";
import { MicroflowSetUpContext } from "providers";
import { useContext, useEffect, useState } from "react";
import { map } from "lodash";
import { MICROFLOW_ACTION_LIST, MICROFLOW_RULE_ACTION_LIST, STEP_CONTAINER_TYPES } from "../../config";
import React from "react";
import { useDispatch } from "react-redux";
import { toasterReducer } from "redux/reducers/toaster.reducer";
import { ReactSVG } from "react-svg";

export function StepContainer({ stepContainers, stepContainerType, stepPointerStr, parentStepId, disabled }){

    let depthNumber = 0;
    const microflowSetUpContext = useContext(MicroflowSetUpContext);
    const [ settingsIndex, setSettingsIndex ] = useState(null);
    const [ loadingRef, setLoadingRef ] = useState(false);
    const [ hoverData, setHoverData ] = useState(null);
    const [ collapseLeft, setCollapseLeft ] = useState(false);
    const [ collapseRight, setCollapseRight ] = useState(false);
    const dispatch = useDispatch();


    useEffect(()=>{
        generateStepDepth();
        setLoadingRef(true);
    });

    const handleSettings = (id) => {
        if(settingsIndex === id){
            setSettingsIndex(null);
        } else {
            setSettingsIndex(id);
        }
    }

    /**
     * @desc function to generate depth of each step container
     */
    const generateStepDepth = () => {
        try {
            let tempStr = ''; //to store the string to be generated
            stepContainers.forEach((d, i) => {
                //if(!isArray(d)) {
                    if(stepContainerType === STEP_CONTAINER_TYPES.DEFAULT) {
                        tempStr = 'R'+depthNumber;
                        depthNumber += 1;
                        if(stepContainers[i]) stepContainers[i]["ruleDepth"] = tempStr;
                    }else if(stepContainerType === STEP_CONTAINER_TYPES.PASSED) {
                        tempStr = stepPointerStr+'P'+depthNumber;
                        depthNumber += 1;
                        if(stepContainers[i]) stepContainers[i]["ruleDepth"] = tempStr;
                    }else if(stepContainerType === STEP_CONTAINER_TYPES.FAILED) {
                        tempStr = stepPointerStr+'N'+depthNumber;
                        depthNumber += 1;
                        if(stepContainers[i]) stepContainers[i]["ruleDepth"] = tempStr;
                    }
                    if(stepContainers?.[i] && stepContainers[i].type === 'RULE') {
                        stepPointerStr = tempStr;
                    //}
                }
            });
        } catch(error) {
        console.log(error);
        }
    }

    const handleSelectedAction = (actionName, index, containerData) => {
        switch(actionName){
            case "EDIT":
                microflowSetUpContext.fetchInputOuputField(containerData)
                // microflowSetUpContext.setSelectedContainer(containerData);
                break;
            case "ADD_ABOVE":
                microflowSetUpContext.handleCreateContainer(stepContainers, index, "TOP", parentStepId);
                break;
            case "ADD_BELOW":
                microflowSetUpContext.handleCreateContainer(stepContainers, index, "BOTTOM", parentStepId);
                break;
            case "COPY":
                navigator.clipboard.writeText(containerData?.ruleDepth);
                dispatch(toasterReducer({showToaster: true, message: "Copied to clipboard Successfully", isError: false}));
                break;
            case "EDIT_RULE":
                microflowSetUpContext.setSelectedContainer(containerData);
                microflowSetUpContext.setShowConfigureRules(true);
                break;
            case "DELETE":
                microflowSetUpContext.handleDeleteContainer(containerData,index,stepContainers);
                break;
            default:
                break;
        }
        setSettingsIndex(null);
    }

    return (
        <>
            { loadingRef && map(stepContainers,(containerData,index)=>
                <React.Fragment key={index}>
                    { containerData?.type!=="RULE" && <div className="microflow-setup__group">
                        <div className="microflow-setup__box" onMouseEnter={()=>setHoverData(containerData?.stepId+index)} onMouseLeave={()=>{setHoverData(null); setSettingsIndex(null);}}>
                            <span className="microflow-setup__name">{containerData?.entities?.[0]?.name}</span>
                            { hoverData === containerData?.stepId+index && <span className="microflow-setup__settings"><img src={Images.settings} alt="" onClick={()=>{handleSettings(containerData?.ruleDepth)}}/></span> }
                            { settingsIndex===containerData?.ruleDepth && <div className="microflow-setup__actions">
                                {map(MICROFLOW_ACTION_LIST, (action,ind)=><React.Fragment key={ind}>
                                    <span>
                                    <img src={action.img} alt="" loading="lazy" onClick={()=>{handleSelectedAction(action.label,index,containerData)}} style={{pointerEvents: ((disabled&&action.label!=="EDIT")||(containerData?.entities?.[0]?.name==="Goto"&&(action.label==="ADD_BELOW")))?"none":""}}/>
                                        { action.label==="COPY" && <span className="microflow-setup__actions--text">{containerData?.ruleDepth}</span> }
                                    </span>
                                    { ind!==MICROFLOW_ACTION_LIST.length-1 && <span className="microflow-setup__actions--seperator"></span> }
                                </React.Fragment>
                                )}
                            </div> }
                        </div>
                        { containerData?.type!=="GOTO" && <div className="microflow-setup__line"></div> }
                        { stepContainers.length===index+1 && containerData?.type!=="GOTO" && <img src={Images.blueadd} alt="" onClick={()=>{microflowSetUpContext.handleCreateContainer(stepContainers)}} className="microflow-setup__straightline--img" style={{pointerEvents:disabled?"none":""}}/> }
                    </div> }
                    { containerData?.type==="RULE" && <div className="microflow-setup__group">
                        <span className="microflow-setup__rule" onMouseEnter={()=>setHoverData(containerData?.stepId+index)} onMouseLeave={()=>{setHoverData(null);setSettingsIndex(null);}}>
                            <span style={{position:"inherit",zIndex:9999,display:"inline-block"}}>
                            <ReactSVG src="images/microflow/diamondd.svg" />
                            </span>
                            <span className="microflow-setup__name microflow-setup__rule--text">{containerData?.entities?.[0]?.name||'RULE'}</span>
                            { hoverData === containerData?.stepId+index && <span className="microflow-setup__settingsrule"><img src={Images.settings} alt="" onClick={()=>{handleSettings(containerData?.ruleDepth)}}/></span> }
                            { hoverData === containerData?.stepId+index && settingsIndex===containerData?.ruleDepth && <div className="microflow-setup__actions microflow-setup__ruleactions">
                                {map(MICROFLOW_RULE_ACTION_LIST, (action,ind)=><React.Fragment key={ind}>
                                    <span>
                                        <img src={action.img} loading="lazy" alt="" onClick={()=>{handleSelectedAction(action.label,index, containerData)}} style={{pointerEvents: (disabled&&action.label!=="EDIT_RULE")?"none":""}}/>
                                        { action.label==="COPY" && <span className="microflow-setup__actions--text">{containerData?.ruleDepth}</span> }
                                    </span>
                                    { ind!==MICROFLOW_RULE_ACTION_LIST.length-1 && <span className="microflow-setup__actions--seperator"></span> }
                                </React.Fragment>
                                )}
                            </div> }
                        </span>
                        <div className="microflow-setup__straightline group">
                            <span className="microflow-setup__actionkey">No</span>
                            <span className="microflow-setup__actionkey microflow-setup__actionkey--right">Yes</span>
                            <div className="microflow-setup__straightline--col">
                                <div className="microflow-setup__line" style={{marginTop:'0px'}}></div>
                                { containerData.passedEvent?.length>0 && <span style={{margin: "0 0 0 -7px",display:"inline-block",width:"16px"}}><img src={Images.collapse} alt="" onClick={()=>{setCollapseLeft(!collapseLeft)}} style={{transform: !collapseLeft?"rotate(180deg)":""}}/></span> }
                                { containerData.passedEvent?.length===0 && <span style={{margin: "0 0 0 -7px",display:"inline-block",width:"16px"}}><img  className="microflow-setup__straightline--img" src={Images.blueadd} alt="" onClick={()=>{microflowSetUpContext.handleCreateContainer(containerData.passedEvent)}} style={{pointerEvents:disabled?"none":""}}/></span>}
                                { !collapseLeft && containerData.passedEvent?.length!==0 && 
                                    <div style={{transform:"translate(-50%"}}>
                                        <StepContainer stepContainers={containerData.passedEvent} stepContainerType={STEP_CONTAINER_TYPES.PASSED} stepPointerStr={containerData?.ruleDepth} parentStepId={containerData?.stepId} disabled={disabled}/>
                                    </div>
                                }
                            </div>
                            <div className="microflow-setup__straightline--col">
                                <div className="microflow-setup__line" style={{marginTop:'0px'}}></div>
                                { containerData.failedEvent?.length>0 && <span style={{margin: "0px -8.5px 0 0",display:"inline-block",width:"16px"}}><img src={Images.collapse} alt="" onClick={()=>{setCollapseRight(!collapseRight)}} style={{transform: !collapseRight?"rotate(180deg)":""}}/></span> }
                                { containerData.failedEvent?.length===0 && <span style={{margin: "0px -8.5px 0 0",display:"inline-block",width:"16px"}}><img src={Images.blueadd} className="microflow-setup__straightline--img" alt="" onClick={()=>{microflowSetUpContext.handleCreateContainer(containerData.failedEvent)}}  style={{pointerEvents:disabled?"none":""}}/></span> }
                                { !collapseRight && containerData.failedEvent?.length!==0 && <div style={{transform:"translate(50%"}}>
                                    <StepContainer stepContainers={containerData.failedEvent} stepContainerType={STEP_CONTAINER_TYPES.FAILED} stepPointerStr={containerData?.ruleDepth} parentStepId={containerData?.stepId} disabled={disabled}/>
                                </div>}
                            </div>
                        </div>
                    </div> }
                </React.Fragment>
            )}
        </>
    )
}