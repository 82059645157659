import { Images } from "assets/Images";

export const CONNECTOR_TABLE_HEADERS = [
    {
        key: 'name',
        label: 'Name',
        sort: true,
        width: '13%',
    },
    {
        key: '',
        label: '',
        width: "4%",
        description: true,
    },
    {
        key: 'providerName',
        label: 'Provider name',
        width: '13%'
    },
    {
        key: 'type',
        label: 'Type',
        width: '10%'
    },
    {
        key: 'version',
        label: 'Version',
        width: '10%'
    },
    {
        key: 'httpMethod',
        label: 'Method',
        width: '10%',
        sort: true,
    },
    {
        key: 'initiatedBy.username',
        label: 'Created By',
        isNested: true,
        width: '10%'
    },
    {
        key: 'modifiedTS',
        label: 'Modified on',
        isDate: true,
        width: '10%',
        sort: true,
    },
    {
        key: 'status',
        label: 'Status',
        width: '10%',
        sort: true,
    },
    {
        key: '',
        label: 'Action',
        width: '10%'
    }
]

export const CONNECTOR_TABLE_DRAFT_HEADERS = [
    {
        key: 'name',
        label: 'Name',
        sort: true,
        width: '14%',
    },
    {
        key: '',
        label: '',
        width: "4%",
        description: true,
    },
    {
        key: 'providerName',
        label: 'Provider name',
        width: '14%'
    },
    {
        key: 'type',
        label: 'Type',
        width: '12%'
    },
    {
        key: 'version',
        label: 'Version',
        width: '10%'
    },
    {
        key: 'httpMethod',
        label: 'Method',
        width: '10%',
        sort: true
    },
    {
        key: 'modifiedTS',
        label: 'Modified on',
        isDate: true,
        sort: true,
        width: '12%'
    },
    {
        key: 'status',
        label: 'Status',
        width: '12%',
        sort: true
    },
    {
        key: '',
        label: 'Action',
        width: '12%'
    }
]

export const CONNECTOR_SETUP_TABS_LIST = ['Settings','Headers & Parameters','Request and Response'];

export const CONNECTOR_TABLE_ACTIONS = [
    {
        label: 'EDIT',
        img: Images.edit,
        value: 'Edit'
    },
    {
        label: 'COPY',
        img: Images.copy,
        value: 'Copy'
    },
    {
        label: 'DOWNLOAD',
        img: Images.download2,
        value: 'Download'
    },
    {
        label: 'DELETE',
        img: Images.delete,
        value: 'Delete'
    }   
]

export const CONNECTOR_TABLE_PUBLISHED_ACTIONS = [
    {
        label: 'EDIT',
        img: Images.edit,
        value: 'Edit'
    },
    {
        label: 'COPY',
        img: Images.copy,
        value: 'Copy'
    },
    {
        label: 'DOWNLOAD',
        img: Images.download2,
        value: 'Download'
    } 
]

export const CONNECTOR_TABLE_REVIEW_ACTION = [ 
    {
        label: 'EDIT',
        img: Images.edit,
        value: 'Edit'
    },
 ]

export const CONNECTOR_ACTIONS = [
    {
        label: 'Download Template',
        img: Images.upload,
        value: 'Download'
    },
    {
        label: 'Upload',
        img: Images.download,
        value: 'Upload'
    },
    {
        label: 'Filter',
        img: Images.filter,
        value: 'Filter'
    }
]

export const CONNECTOR_SETUP_ACTIONS = [
    {
        label: 'Audit',
        value: 'Audit',
        img: Images.version
    }
]

export const METHOD_OPTIONS = [
    { value: "GET", label: "GET"},
    { value: "POST", label: "POST"}
]

export const TYPE_OPTIONS = [
    { value: "DEFAULT", label: "Default"},
    { value: "LOOKUP", label: "Lookup"}
];

export const CONNECTOR_INITIAL_VALUES = { name: '', providerName: '', type: '', httpMethod: '', description: '' };

export const NUMBER_OF_RETRY_OPTIONS = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
];

export const OPTIONS_TYPES = [
    { value: 'Any', label: 'Any' },
    { value: 'String', label: 'String' },
    { value: 'Number', label: 'Number' },
    { value: 'Array<Object>', label: 'Array<Object>' },
]

export const REQUEST_TYPES = [ 
    { value: "Form UrlEncoded", label: 'Form UrlEncoded' },
    { value: "Form Data", label: 'Form Data' },
    { value: "Raw", label: 'Raw' }
];

export const ACCORDION_OPTIONS_TYPES = [
    { value: 'Any', label: 'Any' },
    { value: 'String', label: 'String' },
    { value: 'Number', label: 'Number' },
]

export const ROW_KEYS = { 
    headers: { key:'', value: '', static: false },
    requestMap: { key: '', fieldType: 'Any', mandatory: false, verify: false, accordionFields: [] },
    responseMap: { key: '', value: '', fieldType: 'Any', accordionFields: [] },
    parameterMap: { key: '', mandatory: true, verify: false, static: false },
};

export const CONNECTOR_INITIAL_STATE = {
    connectorId: '',
    type: 'Default',
    numberOfRetries: 0,
    version: 0,
    providerName: '',
    validity: 0,
    description: '',
    endpoint: '',
    httpMethod: '',
    iconUrl: '',
    timeout: 0,
    directCall: false,
    enableCache: false,
    timeGap: 0,
    requestMediation: '',
    responseMediation : '',
    headers: [],
    parameterMap: [],
    requestMap: [],
    responseMap: [],
    requestType: ''
};