import './style.scss';
import { Images } from 'assets/Images';
import { useQuery } from '@tanstack/react-query';
import * as api from 'actions';
import { useNavigate } from 'react-router-dom';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';

function Sidebar(){
    const navigate = useNavigate();
    const handleLogoutOnSuccess = () => {
        localStorage.clear(); 
        navigate("/", { replace: true });
    }

    const { refetch: handleLogout } = useQuery(['LOGOUT'], ()=> api.postMethodCall(ADMIN_API_AXIOS,'/v2/auth/logout',{})
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleLogoutOnSuccess
    })

    return (
        <>
            <aside className="sidebar">
                <div>
                    <nav>
                        <ul>
                            <li>
                                <span onClick={()=>navigate('/home')} className="sidebar__item">
                                    <img src={Images.home} alt=""/>
                                    <div className="sidebar__profile--text">Home</div>
                                </span>
                            </li>
                            {/* <li>
                                <span className="sidebar__item">
                                    <img src={Images.flow} alt=""/>
                                    <div className="sidebar__profile--text">Flow</div>
                                </span>
                            </li>
                            <li>
                                <span className="sidebar__item">
                                    <img src={Images.indesign} alt=""/>
                                    <div className="sidebar__profile--text">Design</div>
                                </span>
                            </li> */}
                            {/* <li>
                                <span className="sidebar__item">
                                    <img src={Images.helptext} alt=""/>
                                    <div className="sidebar__profile--text">Help</div>
                                </span>
                            </li> */}
                        </ul>
                    </nav>
                </div>
                <div className="sidebar__item">
                    <img src={Images.logout} alt="" onClick={handleLogout}/>
                </div>
            </aside>
        </>
    )
}

export default Sidebar;