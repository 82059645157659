import axios from 'axios';
import { createBrowserHistory } from "history";

const { REACT_APP_OLD_ADMIN_URL, REACT_APP_ADMIN_URL, REACT_APP_ENTITY_ID } = process.env;
const history = createBrowserHistory();

const OLD_ADMIN_API_AXIOS = axios.create({ baseURL: REACT_APP_OLD_ADMIN_URL });

const ADMIN_API_AXIOS = axios.create({ baseURL: REACT_APP_ADMIN_URL });

export { OLD_ADMIN_API_AXIOS, ADMIN_API_AXIOS };

export const request = ({ client,...options }) => {
    client.defaults.headers.common['access_token'] = localStorage.getItem('_ac') !== null ? localStorage.getItem('_ac') : '';
    client.defaults.headers.common['sessionid'] = localStorage.getItem('_sc') !== null ? localStorage.getItem('_sc') : '';
    client.defaults.headers.common['entityId'] = REACT_APP_ENTITY_ID;
    const onSuccess = response => response
    const onError = error => {
        if (error?.response?.status === 401) {
            localStorage.clear();
            history.push("/");
            history.go(0);
        }
        return error?.response;
    }
    return client(options).then(onSuccess).catch(onError)
}