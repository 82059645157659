import './style.scss';
import { Images } from 'assets/Images';

function Header({ handleProfileClick }) {

    return (
        <header className="header">
            <span className="header__logo"><img src={Images.logo} alt=""/></span>
            <span onClick={handleProfileClick}><img src={Images?.profileIcon} alt=""/></span>
        </header>
    )
}

export default Header;