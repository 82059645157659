import "./style.scss";
import { Search } from "components/generic";

export function FieldListing(){
    return (
        <div className="field-listing">
            <header>List of fields available</header>
            <Search/>
        </div>
    )
}