

import './style.scss';
import Header from './components/header';
import Sidebar from './components/sidebar';
import Profile from './components/profile';
import { handleOutsideClick } from 'utils';
import { useState, useEffect, useRef } from 'react';

export const LayoutComponent = (props) => {

    const [ showProfile, setShowProfile ] = useState(false);
    const clickRef = useRef(true);

    const handleProfileClick = () => {
        if(clickRef.current){
            setShowProfile(!showProfile);
        }
        clickRef.current = true;
    }

    const ref = useRef();
    useOnClickOutside(ref, () => {
        clickRef.current = false;
        return setShowProfile(false);
    });

    function useOnClickOutside(ref, handler) {
        useEffect(() => {
            handleOutsideClick(ref, handler)
        }, [ref, handler]);
    }

    return (
        <div className="layout-main">
            <Header handleProfileClick={handleProfileClick}/>
            <div className="layout-main__profile">
                {showProfile && <div ref={ref}><Profile/></div>}
            </div>
            <div className="layout-main__col">
                <Sidebar/>
                <div className="layout-main__container">{props.children}</div>
            </div>
        </div>
    )
}

