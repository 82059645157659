import "./style.scss";
import { Images } from "assets/Images";
import { Checkbox, ToggleSwitch } from "components/generic";

export function NavigationSettings(){
    return (
        <div className="navigation-settings">
            <div className="navigation-settings__group">
                <div>
                    <span><img src={Images.workspacesummary} alt=""/></span>
                    <span className="navigation-settings__text">Workspace summary</span>
                </div>
                <span className="navigation-settings__description">Targets for SLAs can be set for this task. Please note that these are captured for MIS purposes. Enabling these targets does not activate TAT (Turn Around Time) calculations automatically</span>
            </div>
            <div className="navigation-settings__row">
                <div className="navigation-settings__group">
                    <Checkbox label="Default"/>
                    <span className="navigation-settings__description">Overall time is sum of queue and assigned time</span>
                </div>
                <span>
                    <img src={Images.default} alt=""/>
                </span>
            </div>
            <div className="navigation-settings__row">
                <div className="navigation-settings__group">
                    <Checkbox label="Tab Layout"/>
                    <span className="navigation-settings__description">Overall time is sum of queue and assigned time</span>
                </div>
                <span>
                    <img src={Images.tabLayout} alt=""/>
                </span>
            </div>
            <div className="navigation-settings__row">
                <div className="navigation-settings__group">
                    <Checkbox label="Section & Subsection"/>
                    <span className="navigation-settings__description">Overall time is sum of queue and assigned time</span>
                </div>
                <span>
                    <img src={Images.sectionSubsection} alt=""/>
                </span>
            </div>
            <div className="navigation-settings__group">
                <div>
                    <span><img src={Images.workspacesettings} alt=""/></span>
                    <span className="navigation-settings__text">Workspace settings</span>
                </div>
                <span className="navigation-settings__description">Attach a data field from the repository. You can also provide basic settings related to behavior of the field on interacting with user</span>
            </div>
            <div className="navigation-settings__row">
                <ToggleSwitch label="Enable Page Level Save"/>
            </div>
            <div className="navigation-settings__row">
                <ToggleSwitch label="Direct Actions"/>
            </div>
        </div>
    )
}