import './style.scss';
import { Images } from 'assets/Images';
import { SelectMenu, TextBox, ToggleSwitch, Button, Checkbox, ConfirmationPopUp, TabsListing } from 'components/generic';
import { useCallback, useContext, useEffect, useMemo, useReducer, useState, useRef } from 'react';
import { ConnectorContext } from 'providers';
import VersionHistory from '../../../../generic/version-history';
import AuditComponent from 'components/generic/audit';
import { CONNECTOR_SETUP_TABS_LIST, CONNECTOR_SETUP_ACTIONS, NUMBER_OF_RETRY_OPTIONS, OPTIONS_TYPES, ROW_KEYS, REQUEST_TYPES, CONNECTOR_INITIAL_STATE } from '../../config';
import { APPROVER_ACTIONS_LIST, TASK_ACTIONS_LIST  } from 'config';
import { useQuery } from '@tanstack/react-query';
import * as api from 'actions';
import * as apicall from 'actions/graphql';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';
import { isEmpty, isNumber, isObject, map } from 'lodash';
import { toasterReducer } from 'redux/reducers/toaster.reducer';
import { useDispatch } from 'react-redux';
import FieldConfigPopup from '../field-config-popup';
import { decrypt } from 'utils/crypto';

export default function ConnectorSetup(){

    const [ selectedTab, setSelectedTab ] = useState(CONNECTOR_SETUP_TABS_LIST[0]);
    const [ showVersionHistory, setShowVersionHistory ] = useState(false);
    const [ showAudit, setShowAudit ] = useState(false);
    const [ showConfigureFields, setShowConfigureFields ] = useState(false);
    const [ selectedAction, setSelectedAction ] = useState(null);
    const [ actionList, setActionList ] = useState(TASK_ACTIONS_LIST);
    const [ showConfirmationPopUp, setShowConfirmationPopUp ] = useState(false);
    const connectorContext = useContext(ConnectorContext);
    const dispatch = useDispatch();
    const paramData = useRef(null);
    const roles = decrypt(localStorage.getItem('_ud'))?.roles;
    const selectedUserDetail = useRef(null);
    const [  errorData, setErrorData ] = useState({});
    const connectorRef = useRef(null);
    const modifiedTS = useRef("");
    const [ auditsData, setAuditsData ] = useState([]);
    const auditsPageNumber = useRef(1);
    const showLoadMoreAuditsOption = useRef(true);

    const reducer = (state, action) => {
        switch(action.type){
            case 'UPDATE':
                if(action.update){
                    connectorRef.current = JSON.stringify({ ...state, ...action.data });
                }
                return { ...state, ...action.data };
            case 'ADD_ROW':
                return { ...state, [action.key]: [...state[[action.key]], ...[{...ROW_KEYS[action.key]}]] };
            case 'UPDATE_ROW':
                const stateData = { ...state };
                stateData[action.key][action.rowIndex][action.nestedKey] = action.data;
                return stateData;
            case 'DELETE_ROW':
                const rowData = { ...state };
                rowData[action.key].splice(action.rowIndex,1);
                return rowData;
            default:
                break;
        }
        return state;
    }

    const [ connectorData, dispatchConnectorData ] = useReducer(reducer,CONNECTOR_INITIAL_STATE);

    useEffect(()=>{
        modifiedTS.current = connectorContext?.selectedConnectorDetail?.modifiedTS;
        dispatchConnectorData({ type: 'UPDATE', data: connectorContext.selectedConnectorDetail, update: true });
        if(connectorContext.selectedConnectorDetail?.status === 'Pending'){
            // if(roles?.includes("CHECKER")) {
                setSelectedAction(APPROVER_ACTIONS_LIST[0]);
                setActionList(APPROVER_ACTIONS_LIST);
            // }
        } else if(connectorContext.selectedConnectorDetail?.status === 'Draft' || connectorContext.selectedConnectorDetail?.status === 'Rework'){    
            // if(roles?.includes("MAKER")) {
                setSelectedAction(TASK_ACTIONS_LIST[0]);
                setActionList(TASK_ACTIONS_LIST);
            // }
        } 
    },[]);

    const handleUpdateConnectorOnSuccess = (response) => {
        if(response.code === 200){
            modifiedTS.current = response?.data?.modifiedTS || "";
            fetchConnectorData();
            dispatch(toasterReducer({showToaster: true, message: response?.message, isError: false}));
        } else {
            dispatch(toasterReducer({showToaster: true, message: response?.message, isError: true}));
        }
    }

    const handleFetchAuditsData = (response) => {
        if(response?.data?.connectorAudits?.data?.length===0){
            showLoadMoreAuditsOption.current = false;
        }
        setAuditsData([...auditsData,...response?.data?.connectorAudits?.data]);
        setShowAudit(true);
    }

    const handleSelectedAction = useCallback((actionName) => {
        switch(actionName){
            case 'Audit':
                fetchAuditsData();
                break;
            default:
                break;
        }
    },[]);

    const handleSelectedValue = (event ,key, index, nestedKey, isArray, isCheckbox) => {
        if(isCheckbox) {
            event = event?.target?.checked;
        }
        else if(isObject(event) && !isArray) event = event?.target?.value;
        if(isNumber(index)){
            dispatchConnectorData({ type: 'UPDATE_ROW', data: event, rowIndex: index, key: key, nestedKey: nestedKey });
        } else {
            dispatchConnectorData({ type: 'UPDATE', data: { [key]: event } });
        }
    }

    const handleAction = (actionName, data) => {
        switch(actionName){
            case TASK_ACTIONS_LIST[0]:
                submitConnector();
                break;
            case APPROVER_ACTIONS_LIST[0]:
                approveConnector();
                break;
            case APPROVER_ACTIONS_LIST[1]:
                selectedUserDetail.current = data;
                rejectConnector();
                break;
            default:
                break;
        }
    }

    const handleEdit = (event, index) => {
        paramData.current = { type: event, index };
        setShowConfigureFields(true);
    }

    const deleteRowData = (type, index) =>  {
        const errorObj = structuredClone(errorData);
        if(errorObj?.[type]) {
            delete errorObj[type];
            connectorData?.[type]?.forEach((header,ind)=>{
                if(!header.key && ind!==index){
                    if(!errorObj[type]) errorObj[type] = { key: {} };
                    errorObj[type]['key'][ind] = 'Mandatory Field';
                }
            })
            setErrorData(errorObj);
        }
        dispatchConnectorData({type:'DELETE_ROW', key: type, rowIndex: index});
    }

    const handleSubmitAction = ((value) => {
        setShowConfirmationPopUp(true);
    });

    const { refetch: handleUpdateConnector } = useQuery(['UPDATE_CONNECTOR'], ()=>{
        const reqData = {...connectorData};
        delete reqData?.name;
        delete reqData?.connectorId;
        return api.putMethodCall(ADMIN_API_AXIOS,`/v2/connectors/${connectorData?.connectorId}`,{ 
            updateData: reqData,
            modifiedTS: modifiedTS.current
        })
    }, {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (data)=>{handleUpdateConnectorOnSuccess(data)},
    })

    const handleFetchData = (response) => {
        dispatchConnectorData({ type: 'UPDATE', data: response?.data?.connectorDetails?.data?.[0], update: true });
    }

    const saveConnector = () =>  {
        if(connectorData?.status==='Published'){
            createDraft();
        } else {
            const errorObj = {};
            if(!connectorData?.requestType){
                errorObj['requestType'] = 'Mandatory Field';
            }
            if(!connectorData?.endpoint){
                errorObj['endpoint'] = 'Mandatory Field';
            }
            if(connectorData?.timeGap<0){
                errorObj['timeGap'] = 'Value cant be negative';
            }
            if(connectorData?.validity<0){
                errorObj['validity'] = 'Value cant be negative';
            }
            if(connectorData?.timeout<0){
                errorObj['timeout'] = 'Value cant be negative';
            } 
            connectorData?.headers?.forEach((header,ind)=>{
                if(!header.key){
                    if(!errorObj['headers']) errorObj['headers'] = { key: {}, value: {}};
                    errorObj['headers']['key'][ind] = 'Mandatory Field';
                }
            })
            connectorData?.parameterMap?.forEach((header,ind)=>{
                if(!header.key){
                    if(!errorObj['parameterMap']) errorObj['parameterMap'] = { key: {} };
                    errorObj['parameterMap']['key'][ind] = 'Mandatory Field';
                } 
            })
            connectorData?.requestMap?.forEach((header,ind)=>{
                if(!header.key){
                    if(!errorObj['requestMap']) errorObj['requestMap'] = { key: {} };
                    errorObj['requestMap']['key'][ind] = 'Mandatory Field';
                } 
            })
            connectorData?.responseMap?.forEach((header,ind)=>{
                if(!header.key){
                    if(!errorObj['responseMap']) errorObj['responseMap'] = { key: {} };
                    errorObj['responseMap']['key'][ind] = 'Mandatory Field';
                } 
            })
            setErrorData(errorObj);
            if(isEmpty(errorObj)){
                handleUpdateConnector();
            } else {
                dispatch(toasterReducer({showToaster: true, message: 'Please fill all the mandatory fields', isError: true}));
            }
        }
    }

    const handleActionOnSuccess = (response) => {
        showConfirmationPopUp && setShowConfirmationPopUp(false);
        dispatch(toasterReducer({showToaster: true, message: response?.message, isError: response?.code!==200}));
        if(response.code === 200){
            connectorContext.setShowConnectorDetails(false);
        }
    }

    const handleSelectedVersion = (versionData) => {
        if(!versionData) { 
            dispatchConnectorData({ type: 'UPDATE', data: connectorContext.selectedConnectorDetail });
        } else {
            dispatchConnectorData({ type: 'UPDATE', data: versionData });
        }
    }

    const handleLoadMoreAuditsData = () => {
        auditsPageNumber.current++;
        fetchAuditsData();
    }

    const tableHeaderTabVal = useMemo(() => (
        <TabsListing tabList={CONNECTOR_SETUP_TABS_LIST} selectedTab={selectedTab} setSelectedTab={setSelectedTab} actionsList={CONNECTOR_SETUP_ACTIONS} handleSelectedAction={handleSelectedAction}/>
    ),[selectedTab, handleSelectedAction]);

    const { refetch: fetchConnectorData } =useQuery(['FETCH_SELECTED_CONNECTOR_DATA'], ()=> apicall.fetchConnectorData(`/v2/graphql`,connectorContext.selectedConnectorDetail?.status,connectorContext.selectedConnectorDetail?.connectorId),
    {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleFetchData
    })

    const { refetch: fetchAuditsData } =useQuery(['FETCH_AUDITS_CONNECTOR_DATA'], ()=> apicall.fetchConnectorAudits(`/v2/graphql`,connectorContext.selectedConnectorDetail?.connectorId, auditsPageNumber.current),
    {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleFetchAuditsData
    })

    const { data: versionHistoryData, refetch: fetchVersionHistoryDetails } =useQuery(['FETCH_VERSION_HISTORY'], ()=> apicall.fetchConnectorVersionHistoryDetails(`v2/graphql`,connectorData?.connectorId), 
    {
        enabled: false,
        refetchOnWindowFocus: false,
    })

    const { refetch: submitConnector } = useQuery(['SUBMIT_CONNECTOR'], ()=> api.postMethodCall(ADMIN_API_AXIOS,`v2/connectors/${connectorData?.connectorId}/submit`,{
        // userDetails: selectedUserDetail?.current,
        modifiedTS: modifiedTS.current
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleActionOnSuccess
    })

    const { refetch: createDraft } = useQuery(['CRAETE_DRAFT'], ()=> api.postMethodCall(ADMIN_API_AXIOS,`v2/connectors/${connectorData?.connectorId}/create-draft`,{
        // userDetails: selectedUserDetail?.current,
        modifiedTS: modifiedTS.current
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleActionOnSuccess
    })

    const { refetch: approveConnector } = useQuery(['APPROVE_CONNECTOR'], ()=> api.postMethodCall(ADMIN_API_AXIOS,`v2/connectors/${connectorData?.connectorId}/approve`,{
        modifiedTS: modifiedTS.current
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleActionOnSuccess
    })

    const { refetch: rejectConnector } = useQuery(['REJECT_CONNECTOR'], ()=> api.postMethodCall(ADMIN_API_AXIOS,`v2/connectors/${connectorData?.connectorId}/sendback`,{
        // comments: selectedUserDetail?.current
        modifiedTS: modifiedTS.current
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleActionOnSuccess
    })

    return (
      <div className="connector-setup">
        <div className="connector-setup__header">
            <div className="connector-setup__header--backoption">
                <img src={Images.backicon} alt="" className="connector-setup__backicon" onClick={()=>connectorContext.setShowConnectorDetails(false)}/>
                <span className="connector-setup__header--text">Connector <strong>/ {connectorData?.name}</strong></span>
            </div>
            <div className="connector-setup__action">
                { (connectorData?.status==='Submitted'||connectorData?.status==='Pending'||connectorData?.status==='Published') && <span className="connector-setup__readonly">
                    <span><img src={Images.readonly} alt=""/></span>
                    <span className="connector-setup__readonly--text">Read only mode</span>
                </span> }
                <span  className="connector-setup__version" onClick={()=>{fetchVersionHistoryDetails();setShowVersionHistory(true);}}>V {connectorData?.version}</span>
                { (connectorData?.version===connectorContext?.selectedConnectorDetail?.version && connectorData?.status!=='Pending' && connectorData?.status!=='Submitted') && <Button label={connectorData?.status==='Published'?'CREATE DRAFT':'SAVE AS DRAFT'} handleClick={saveConnector} disabled={connectorRef.current===JSON.stringify(connectorData)&&connectorData?.status!=='Published'}/> }
                { connectorData?.version===connectorContext?.selectedConnectorDetail?.version && connectorData?.status!=='Published' && selectedAction && <Button label={selectedAction} secondary={true} isMultiple={actionList?.length>1} optionList={actionList} handleClick={(event)=>{handleSubmitAction(event)}} handleSelectedOption={(event)=>{setSelectedAction(event);handleSubmitAction(event);}}/> }
            </div>
        </div>
        <div className="connector-setup__body">
            {tableHeaderTabVal}
            <div style={{height: 'calc(100% - 26px)',pointerEvents: (connectorData?.status==='Submitted'||connectorData?.status==='Pending'||connectorData?.status==='Published')?'none':''}}>
                { selectedTab==='Settings' && <div className="connector-setup__main">
                    <div className="connector-setup__toggle">
                        <ToggleSwitch label="Direct call" checked={connectorData?.directCall} onChange={(event)=>{handleSelectedValue(event, 'directCall')}}/>
                        <ToggleSwitch label="Enable cache" checked={connectorData?.enableCache} onChange={(event)=>{handleSelectedValue(event, 'enableCache')}}/>
                        <div className="connector-setup__toggle--select">
                            <SelectMenu label="Request type" mandatory={true} options={REQUEST_TYPES} value={connectorData?.requestType} onChange={(event)=>{handleSelectedValue(event,'requestType')}} errorMessage={errorData?.requestType} error={errorData?.requestType}/>
                        </div>
                    </div>
                    <div className="connector-setup__section">
                        <TextBox label="URL" type="text" value={connectorData?.endpoint} onChange={(event)=>{handleSelectedValue(event,'endpoint')}} mandatory={true} errorMessage={errorData?.endpoint} error={errorData?.endpoint}/>
                        <div className="connector-setup__row">
                            <SelectMenu label="Number of retries" options={NUMBER_OF_RETRY_OPTIONS} value={connectorData?.numberOfRetries?.toString()}  onChange={(event)=>{handleSelectedValue(event,'numberOfRetries')}} mandatory={true}/>
                            <TextBox label="Time gap between retries (msecs)" type="number" min={0} value={connectorData?.timeGap} onChange={(event)=>{handleSelectedValue(event,'timeGap')}} errorMessage={errorData?.timeGap} error={errorData?.timeGap}/>
                            <TextBox label="Validity Timeout (seconds)" type="number" min={0} value={connectorData?.validity} onChange={(event)=>{handleSelectedValue(event,'validity')}} errorMessage={errorData?.validity} error={errorData?.validity}/>
                        </div>
                        <TextBox label="Icon URL" type="text" value={connectorData?.iconUrl} onChange={(event)=>{handleSelectedValue(event,'iconUrl')}}/>
                        <div className="connector-setup__row">
                            <TextBox label="Request Mediation" type="text" value={connectorData?.requestMediation} onChange={(event)=>{handleSelectedValue(event,'requestMediation')}}/>
                            <TextBox label="Response Mediation" type="text" value={connectorData?.responseMediation} onChange={(event)=>{handleSelectedValue(event,'responseMediation')}}/>
                            <TextBox label="Timeout (seconds)" type="number" min={0} value={connectorData?.timeout} onChange={(event)=>{handleSelectedValue(event,'timeout')}} errorMessage={errorData?.timeout} error={errorData?.timeout}/>
                        </div>
                    </div>
                </div> }
                { selectedTab==='Headers & Parameters' && <div className="connector-setup__group">
                    <div className="connector-setup__col">
                        <h5 className="connector-setup__col--header">Header</h5>
                        <div className="connector-setup__col--header">
                            <span className="connector-setup__col--key connector-setup__col--textbox">Key</span>
                            <span className="connector-setup__col--key connector-setup__col--textbox">Value</span>
                            <span className="connector-setup__col--key">Option</span>
                        </div>
                        <div className="connector-setup__colgroup">
                            {map(connectorData?.headers,(headerRow,ind)=>
                                <div className="connector-setup__rowgroup" key={ind}>
                                    <div className="connector-setup__rowgroup--textbox"><TextBox placeholder={'Header key '+ (ind+1)+'*'} type="text" value={headerRow?.key} onChange={(event)=>{handleSelectedValue(event,'headers',ind,'key')}} mandatory={true} errorMessage={errorData?.headers?.key?.[ind]} error={errorData?.headers?.key?.[ind]}/></div>
                                    <div className="connector-setup__rowgroup--textbox"><TextBox placeholder="value" type="text" value={headerRow?.value} onChange={(event)=>{handleSelectedValue(event,'headers',ind,'value')}} mandatory={true} errorMessage={errorData?.headers?.value?.[ind]} error={errorData?.headers?.value?.[ind]}/></div>
                                    <div className="connector-setup__rowgroup--section">
                                        <Checkbox label="Static" checked={headerRow?.static} onChange={(event)=>{handleSelectedValue(event,'headers',ind,'static',null,true)}}/>
                                        <span><img src={Images.deleterow} alt="" onClick={()=>{deleteRowData('headers',ind)}}/></span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="connector-setup__btn" onClick={()=>{dispatchConnectorData({type:'ADD_ROW', key: 'headers'})}}>
                            <img src={Images.addrow} alt=""/>
                            <span>Add Header</span>
                        </div>
                    </div>
                    <div className="connector-setup__col">
                        <h5 className="connector-setup__col--header">Parameter</h5>
                        <div className="connector-setup__col--header">
                            <span className="connector-setup__col--key connector-setup__col--textbox">Key</span>
                            <span className="connector-setup__col--key">Option</span>
                        </div>
                        <div className="connector-setup__colgroup">
                            {map(connectorData?.parameterMap,(headerRow,ind)=>
                                <div className="connector-setup__rowgroup" key={ind}>
                                    <div className="connector-setup__rowgroup--textbox"><TextBox placeholder={'Parameter key '+ (ind+1)+'*'} type="text" value={headerRow?.key} onChange={(event)=>{handleSelectedValue(event,'parameterMap',ind,'key')}} mandatory={true} errorMessage={errorData?.parameterMap?.key?.[ind]} error={errorData?.parameterMap?.key?.[ind]}/></div>
                                    <div className="connector-setup__rowgroup--section">
                                        <Checkbox label="Mandatory" checked={headerRow?.mandatory} onChange={(event)=>{handleSelectedValue(event,'parameterMap',ind,'mandatory',null,true)}}/>
                                        <Checkbox label="Validate" checked={headerRow?.verify} onChange={(event)=>{handleSelectedValue(event,'parameterMap',ind,'verify',null,true)}}/>
                                        <Checkbox label="Static" checked={headerRow?.static} onChange={(event)=>{handleSelectedValue(event,'parameterMap',ind,'static',null,true)}}/>
                                        <span><img src={Images.deleterow} alt="" onClick={()=>{deleteRowData('parameterMap',ind)}}/></span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="connector-setup__btn" onClick={()=>{dispatchConnectorData({type:'ADD_ROW', key: 'parameterMap'})}}>
                            <img src={Images.addrow} alt=""/>
                            <span>Add Parameter</span>
                        </div>
                    </div>
                </div> }
                { selectedTab==='Request and Response' && <div className="connector-setup__group">
                    <div className="connector-setup__col">
                        <h5 className="connector-setup__col--header">Request</h5>
                        <div className="connector-setup__col--header">
                            <span className="connector-setup__col--key connector-setup__col--textbox">Key</span>
                            <span className="connector-setup__col--key connector-setup__col--select">Type</span>
                            <span className="connector-setup__col--key">Option</span>
                        </div>
                        <div className="connector-setup__colgroup">
                            {map(connectorData?.requestMap,(headerRow,ind)=>
                                <div className="connector-setup__rowgroup" key={ind}>
                                    <div className="connector-setup__rowgroup--textbox"><TextBox placeholder={'Request key '+(ind+1)+'*'} type="text" value={headerRow?.key} onChange={(event)=>{handleSelectedValue(event,'requestMap',ind,'key')}} mandatory={true} errorMessage={errorData?.requestMap?.key?.[ind]} error={errorData?.requestMap?.key?.[ind]}/></div>
                                    <div className="connector-setup__type">
                                        <SelectMenu options={OPTIONS_TYPES} value={headerRow?.fieldType}  onChange={(event)=>{handleSelectedValue(event,'requestMap',ind,'fieldType')}}/>
                                        { headerRow?.fieldType === 'Array<Object>' && <span><img src={Images.edit} alt="" onClick={()=>{handleEdit('requestMap',ind)}}/></span> }
                                    </div>
                                    <div className="connector-setup__rowgroup--section">
                                        <Checkbox label="Mandatory" checked={headerRow?.mandatory} onChange={(event)=>{handleSelectedValue(event,'requestMap',ind,'mandatory',null,true)}}/>
                                        <Checkbox label="Validate" checked={headerRow?.verify} onChange={(event)=>{handleSelectedValue(event,'requestMap',ind,'verify',null,true)}}/>
                                        <span><img src={Images.deleterow} alt="" onClick={()=>{deleteRowData('requestMap',ind)}}/></span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="connector-setup__btn" onClick={()=>{dispatchConnectorData({type:'ADD_ROW', key: 'requestMap'})}}>
                            <img src={Images.addrow} alt=""/>
                            <span>Add Field</span>
                        </div>
                    </div>
                    <div className="connector-setup__col">
                        <h5 className="connector-setup__col--header">Response</h5>
                        <div className="connector-setup__col--header">
                            <span className="connector-setup__col--key connector-setup__col--textbox">Key</span>
                            <span className="connector-setup__col--key">Type</span>
                        </div>
                        <div className="connector-setup__colgroup">
                            {map(connectorData?.responseMap,(headerRow,ind)=>
                                <div className="connector-setup__rowgroup" key={ind}>
                                    <div className="connector-setup__rowgroup--textbox"><TextBox placeholder={'Response key '+(ind+1)+'*'} type="text" value={headerRow?.key} onChange={(event)=>{handleSelectedValue(event,'responseMap',ind,'key')}} mandatory={true} errorMessage={errorData?.responseMap?.key?.[ind]} error={errorData?.responseMap?.key?.[ind]}/></div>
                                    <div className="connector-setup__rowgroup--type">
                                        <SelectMenu options={OPTIONS_TYPES} value={headerRow?.fieldType}  onChange={(event)=>{handleSelectedValue(event,'responseMap',ind,'fieldType')}}/>
                                        { headerRow?.fieldType === 'Array<Object>' && <span><img src={Images.edit} alt="" onClick={()=>{handleEdit('responseMap',ind)}}/></span> }
                                        <span><img src={Images.deleterow} alt="" onClick={()=>{deleteRowData('responseMap',ind)}}/></span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="connector-setup__btn" onClick={()=>{dispatchConnectorData({type:'ADD_ROW', key: 'responseMap'})}}>
                            <img src={Images.addrow} alt=""/>
                            <span>Add Field</span>
                        </div>
                    </div>
                </div> }
            </div>
        </div>
        { showConfirmationPopUp && <ConfirmationPopUp label={selectedAction===APPROVER_ACTIONS_LIST[0]? 'Are you sure you want to approve?':((selectedAction===APPROVER_ACTIONS_LIST[1])?'Are you sure you want to send for rework?':((selectedAction===TASK_ACTIONS_LIST[0])?'Are you sure you want to submit for review?':'Are you sure you want to submit for approval?'))} isCompleted={false} inProgressLabel="In progress" completedLabel={selectedAction===APPROVER_ACTIONS_LIST[0]? 'Approved Successfully?':((selectedAction===APPROVER_ACTIONS_LIST[1])?'Rejected Successfully':((selectedAction===TASK_ACTIONS_LIST[0])?'Submitted for Review Successfully':'Submitted for Approval Successfully'))} handleClose={()=>{setShowConfirmationPopUp(false)}} handleSubmit={(event)=>{handleAction(selectedAction, event)}}/> }
        { showConfigureFields && <div className="connector-setup__overlay">
            <div className="connector-setup__overlay--box">
                    <FieldConfigPopup value={connectorData[paramData?.current?.type][paramData?.current?.index]?.accordionFields} handleSubmit={(event)=>{setShowConfigureFields(false);handleSelectedValue(event,paramData?.current?.type,paramData?.current?.index,'accordionFields', true)}} handleClosePopUp={()=>{setShowConfigureFields(false)}}/>
            </div>
        </div> }
        { (showVersionHistory || showAudit) && <div className="connector-setup__overlay">
                <div className="connector-setup__overlay--content">
                    { showAudit && <AuditComponent auditData={auditsData} showLoadMoreAuditsOption={showLoadMoreAuditsOption.current} handleClosePopUp={()=>{setShowAudit(false);setAuditsData([])}} handleLoadMore={()=>{handleLoadMoreAuditsData()}}/> }
                    { showVersionHistory && <VersionHistory data={versionHistoryData?.data?.connectorVersionHistory?.data} currentVersionData={connectorContext?.selectedConnectorDetail} handleClosePopUp={()=>{setShowVersionHistory(false)}} handleSelectedVersion={handleSelectedVersion} handleCurrentVersion={handleSelectedVersion}/> }
                </div>
        </div> }
      </div>
    )
}
