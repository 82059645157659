import './style.scss';

export default function TextArea(props){
    const { label, placeholder, value, mandatory, onChange, id, error, errorMessage, maxlength } = props;
    return (
        <div className="textarea">
            { <label>{label}{label && mandatory && <sup>*</sup>}</label> }
            <div>
                <textarea placeholder={placeholder} value={value} id={id} name={id} onChange={onChange} maxLength={maxlength}></textarea>
            </div>
            <span className="textarea__error">
                { error && <>{errorMessage}</> }
            </span>
        </div>
    )
}