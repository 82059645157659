import { Images } from "assets/Images"

export const INDESIGN_ACTIONS = [

]

export const TABLE_ACTIONS = [
    {
        label: 'EDIT',
        img: Images.edit,
        value: 'Edit'
    },
    {
        label: 'COPY',
        img: Images.copy,
        value: 'Copy'
    },
    {
        label: 'DELETE',
        img: Images.delete,
        value: 'Delete'
    }   
]

export const CONFIG_SETTINGS_TABS_LIST = [
    "Basic",
    "Deviation",
    "Document",
    "Dependency",
    "Validations",
    "Actions",
    "SLA",
    "Queries",
    "Screens"
]

export const ACTION_TABS = [ "Notification","Webhook" ];

export const FLOW_SETTINGS_TABS = [ "Dedupe", "Deviation", "Navigation", "Re-initiate", "Link section", "SLA" ];
