import { createSlice } from "@reduxjs/toolkit";

export const toaster = createSlice({
    name: "toaster",
    initialState: {
        data: {
            showToaster: false,
            message: null,
            isError: false
        }
    },
    reducers: {
        toasterReducer: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const { toasterReducer } = toaster.actions;