import './style.scss';
import { SelectMenu, TextBox, Button } from 'components/generic';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { METHOD_OPTIONS, TYPE_OPTIONS, CONNECTOR_INITIAL_VALUES } from '../../config';
import { useQuery } from '@tanstack/react-query';
import * as api from 'actions';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';
import { toasterReducer } from 'redux/reducers/toaster.reducer';
import { useDispatch } from 'react-redux';
import { Images } from 'assets/Images';
import { useState, useContext } from 'react';
import { ConnectorContext } from 'providers';
import { useEffect } from 'react';
import { decrypt } from 'utils/crypto';
import { isEmpty } from 'lodash';

export default function AddConnectorPopup(props){
    const { handleClosePopUp, actionType } = props;
    let connectorValues = {};
    const dispatch = useDispatch();
    const connectorContext = useContext(ConnectorContext);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required")
            .required("Required")
            .min(5, 'Name should be min 5 to Max 40 characters')
            .max(40,'Name should be min 5 to Max 40 characters')
            .matches(/^[a-zA-Z0-9\s]+$/, "Special characters are not allowed"),
        providerName: Yup.string()
            .required("Required")
            .min(5, 'Provider name should be min 5 to Max 40 characters')
            .max(40,'Provider name should be min 5 to Max 40 characters'),
        type: Yup.string().required("Required"),
        httpMethod: Yup.string().required("Required"),
        description: Yup.string()
            .required("Required")
            .min(50, 'Description should be min 50 characters')
            .max(100, 'Description should be min 50 characters')
    });

    const [ successState, setSuccessState ] = useState(false);
    
    const handleCreateConnectorOnSuccess = (response) => {
        if(response?.code !== 200){
            dispatch(toasterReducer({showToaster: true, message: response?.message, isError: true}));
        } else {
            setTimeout(() => {
                handleClosePopUp();
            }, 1000);
            setSuccessState(true);
        }
    }

    const { refetch: handleAddNewConnector, isFetching } = useQuery(['ADD_NEW_CONNECTOR'], ()=> api.postMethodCall(ADMIN_API_AXIOS,'/v2/connectors/create',connectorValues)
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (data)=>{handleCreateConnectorOnSuccess(data)},
    })

    const { refetch: handleCopyConnector } = useQuery(['ADD_COPY_CONNECTOR'], ()=> api.postMethodCall(ADMIN_API_AXIOS,`/v2/connectors/${connectorContext.selectedConnectorDetail?.connectorId}/copy`,connectorValues)
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (data)=>{handleCreateConnectorOnSuccess(data)},
    })

    const handleButtonClick = (values) => {
        let reqObj = values;
        if(connectorContext.selectedConnectorDetail){
            reqObj = { ...connectorContext.selectedConnectorDetail,...values };
            delete reqObj.version;
            delete reqObj.status;
            delete reqObj.connectorId;
        }
        connectorValues = reqObj;
        if(actionType === "COPY"){
            handleCopyConnector();
        } else {
            handleAddNewConnector();
        }
    }

    const handleDropdownChange = (value, key, setFieldValue) => {
        setFieldValue([key],value);
    }

    return (
      <div className="add-connector">
            { !isFetching && !successState && <Formik initialValues={CONNECTOR_INITIAL_VALUES} validationSchema={validationSchema} onSubmit={(values)=>{handleButtonClick(values)}}>
            {formik => {
                const { values, errors, isValid, dirty, handleChange, handleSubmit, touched, handleBlur, setFieldValue } = formik;
                return (
                    <Form>
                        <div>                      
                            <header>Define Connector</header>
                            <div>
                                <div className="add-connector__row">
                                    <TextBox type="text" label="Name" value={values['name']} onChange={handleChange} onBlur={handleBlur} id="name" mandatory={true} errorMessage={errors['name']} error={touched['name'] && errors?.hasOwnProperty('name')} maxlength="40"/>
                                    <TextBox type="text" label="Provider Name" value={values['providerName']} onChange={handleChange} id="providerName" mandatory={true} errorMessage={errors['providerName']} error={touched['providerName'] && errors?.hasOwnProperty('providerName')} maxlength="40"/>
                                    <div className="add-connector__group--dropdown">
                                        <SelectMenu label="Type" value={values['type']} options={TYPE_OPTIONS} onBlur={handleBlur} mandatory={true} onChange={(event)=>{handleDropdownChange(event,'type',setFieldValue)}} errorMessage={errors['type']} error={touched['type'] && errors?.hasOwnProperty('type')}/>
                                        <SelectMenu label="Method" value={values['httpMethod']} options={METHOD_OPTIONS} onBlur={handleBlur} mandatory={true} onChange={(event)=>{handleDropdownChange(event,'httpMethod',setFieldValue)}} errorMessage={errors['httpMethod']} error={touched['httpMethod'] && errors?.hasOwnProperty('httpMethod')}/>
                                    </div>
                                    <div className="add-connector__group--description">
                                        <TextBox type="text" label="Description" value={values['description']} onChange={handleChange} onBlur={handleBlur} id="description" mandatory={true} errorMessage={errors['description']} error={touched['description'] && errors?.hasOwnProperty('description')} maxlength="100"/>
                                    </div>
                                </div>
                                {/* <div className="add-connector__row">
                                    <div className="add-connector__group">
                                        <span><strong>Department : </strong>{userDetails?.departmentList?.[0]?.name}</span>
                                    </div>
                                    <div className="add-connector__group">
                                        <span><strong>Team : </strong>{userDetails?.teamList?.[0]?.name}</span>
                                    </div>
                                </div> */}
                            </div>
                            <footer>
                                <div className="add-connector__note"><strong>Note: </strong>Name, Provider Name, Type, Method and Description cannot be changed once added</div>
                                <div className="add-connector__actions">
                                    <Button label="CANCEL" handleClick={handleClosePopUp}/>
                                    <Button label="SAVE" secondary={true} handleClick={(e) => {handleSubmit(e)}} disabled={!(dirty && isValid)} />
                                </div>
                            </footer>
                        </div>
                    </Form>
                )}}
            </Formik> }
            { isFetching && !successState && <>
                    <div className="confirm-popup__group">
                        <img src={Images.shortloader} alt="" className="confirm-popup__loader"/>
                        <h5>{!isEmpty(connectorContext.selectedConnectorDetail)?'Copy':'Add'} in progress</h5>
                    </div>
            </> }
            { !isFetching && successState && <>
                <div className="confirm-popup__group">
                    <img src={Images.successtick} alt=""/>
                    <h5>{!isEmpty(connectorContext.selectedConnectorDetail)?'Copied':'Added'} successfully</h5>
                </div>
            </> }
      </div>
    )
}
