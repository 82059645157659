import "./style.scss";
import { Images } from "assets/Images";
import { Checkbox, SelectMenu } from "components/generic";

export function DocumentConfig(){
    return (
        <div className="document-config">
            <div className="document-config__col">
                <header>
                    <span><img src={Images.documentIcon} alt=""/></span>
                    <span>Document</span>
                </header>
                <div className="document-config__description">
                    If checking of document status is enabled, system would not allow to complete the task if the document
                    status at task matches with the given value below. This is helpful in use cases where team reviews 
                    documents submitted by a person and it needs to be vetted further 
                </div>
                <div>
                    <Checkbox label="Enable mandatory check during completion of task"/>
                </div>
                <div className="document-config__select">
                    <SelectMenu label="Document Status" placeholder="Enter values"/>
                </div>
            </div>
            <div className="document-config__col">
                <span>
                    <img src={Images.documentPlaceholder} alt=""/>
                </span>
            </div>
        </div>
    )
}