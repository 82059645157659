import "./style.scss";
import { Images } from "assets/Images";
import { TextArea, ToggleSwitch, SelectMenu, Button, Checkbox } from "components/generic";
import { Formik, Form } from 'formik';
import { MICROFLOW_SETTINGS_INITIAL_VALUES } from "../../config";
import { isObject } from "lodash";
import { useQuery } from '@tanstack/react-query';
import * as api from 'actions';
import * as apicall from 'actions/graphql';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';
import { useRef } from "react";
import { useDispatch } from 'react-redux';
import { toasterReducer } from 'redux/reducers/toaster.reducer';
import { useState } from "react";
import { map } from "lodash";

export function MicroflowSettings({ closeOverlay, initialValues, microflowId, handleSave, disabled, microflowVersion, updateModifiedTs}){

    const microflowDataRef = useRef(null);
    const microflowRefData = useRef(null);
    const currentValueRef = useRef(null);
    const dispatch = useDispatch();
    const [ microflowListingData, setMicroflowListingData ] = useState([]);
    const [ formValue, setFormValue ] = useState(null);
    const [ responseFields, setResponseFields ] = useState([]);

    const handleSelectedValue = (event, key, nestedKey, setFieldValue) => {
        const value = isObject(event)?event?.target?.value:event;
        if(nestedKey){
            setFieldValue(`${key}.${nestedKey}`,value);
        } else {
            setFieldValue([key],value);
        }
    }

    const handleSaveSettings = (values) => {
        const reqBody = structuredClone(values);
        currentValueRef.current = values;
        if(reqBody?.linkedMicroflow?.enabled && reqBody?.linkedMicroflow?.microflow){
            microflowRefData?.current?.forEach(microflowVal=>{
                if(microflowVal?.name === reqBody?.linkedMicroflow?.microflow) {
                    reqBody.linkedMicroflow.microflow = { microflowId: microflowVal?.microflowId, version: microflowVal?.version };
                }
            })
        }
        microflowDataRef.current = reqBody;
        updateMicroflow();
    }

    
    const populateMicroflowData = (response) => {
        const data = [];
        const microflowDropdownData = [];
        const val = {...MICROFLOW_SETTINGS_INITIAL_VALUES,...initialValues};
        response?.data?.microflowConfigsDetails?.data?.forEach(microflowData=>{
            if(val.linkedMicroflow.microflow?.microflowId === microflowData?.microflowId) {
                val.linkedMicroflow.microflow = microflowData?.name;
            }
            data.push({microflowId: microflowData?.microflowId, version: microflowData?.version, name: microflowData?.name});
            microflowDropdownData.push({label: microflowData?.name, value: microflowData?.name});
        });
        microflowRefData.current = data;
        setMicroflowListingData(microflowDropdownData);
        // if(val.linkedMicroflow.microflow) {
        //     val.linkedMicroflow.microflow = {};
        // }
        setFormValue({...MICROFLOW_SETTINGS_INITIAL_VALUES,...initialValues});
    }

    const handleUpdateOnSuccess = (response) => {
        dispatch(toasterReducer({showToaster: true, message: response?.message, isError: response?.code!==200}));
        if(response?.code===200) {
            handleSave(currentValueRef.current);
            updateModifiedTs(response?.data?.modifiedTS)
        }
    }

    const populateResponseFields = (response) => {
        setResponseFields(response?.data?.microflowConfigsDetails?.data?.[0]?.responseData || []);
    }

    const handleSelectedResponseArray = (event, value, responseField, key, setFieldValue) => {
        if(event.target.checked){
            responseField.push(value);
        } else {
            const index = responseField.findIndex(fieldVal=>fieldVal===value);
            responseField.splice(index,1);
        }
        setFieldValue(key,responseField);
    }

    const { refetch: updateMicroflow } = useQuery(['UPDATE_MICROFLOW'], ()=> api.putMethodCall(ADMIN_API_AXIOS,`v2/microflow/${microflowId}`,{
        updateData: microflowDataRef?.current,
        modifiedTS: initialValues?.modifiedTS
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleUpdateOnSuccess
    })

    useQuery(['FETCH_MICROFLOW_LISTING_DATA'], ()=> apicall.fetchPublishedMicroflows(`/v2/graphql`), { enabled: true, refetchOnWindowFocus: false, onSuccess: (data)=>{populateMicroflowData(data)} });

    useQuery(['FETCH_RESPONSE_FIELDS'], ()=> apicall.fetchMicroflowResponseMap('v2/graphql',microflowId), {  enabled: true, refetchOnWindowFocus: false, onSuccess: (data)=>{populateResponseFields(data)} });

    return (
        <div className="microflow-settings">
            { formValue && <Formik initialValues={formValue} onSubmit={(values)=>{handleSaveSettings(values)}}>
            {formik => {
                const { values, errors, isValid, dirty, handleChange, handleSubmit, touched, handleBlur, setFieldValue } = formik;
                return (
                    <Form>
                        <header>
                            <span className="microflow-settings__text">Microflow Settings</span>
                            <div className="config-fields__save">
                                { !disabled && <><Button label="SAVE" secondary={true} handleClick={e=>{handleSubmit(e)}}></Button> 
                                <span className="config-fields__seperator"></span> </>}
                                <span><img src={Images.close} alt="" onClick={closeOverlay}/></span>
                            </div>
                        </header>
                        {/* <div className="microflow-settings__group">
                            <div>
                                <span><img src={Images.department} alt=""/></span>
                                <div className="microflow-settings__group--col">
                                    <span className="microflow-settings__group--header">Department</span>
                                    <span className="microflow-settings__group--text">{userData?.departmentList?.[0]?.name}</span>
                                </div>
                            </div>
                            <div>
                                <span><img src={Images.team} alt=""/></span>
                                <div className="microflow-settings__group--col">
                                    <span className="microflow-settings__group--header">Team</span>
                                    <span className="microflow-settings__group--text">{userData?.teamList?.[0]?.name} </span>
                                </div>
                            </div>
                        </div> */}
                        <div className="microflow-settings__main">
                            <section style={{pointerEvents: disabled?'none':''}}>
                                <div className="microflow-settings__row">
                                    <label>Initiate Message</label>
                                    <ToggleSwitch checked={values?.["initiateMessage"]?.enabled} onChange={(event)=>{handleSelectedValue(event, 'initiateMessage',"enabled",setFieldValue)}}/>
                                </div>
                                { values?.["initiateMessage"]?.enabled && <div className="microflow-settings__row">
                                    <TextArea value={values?.["initiateMessage"]?.message} placeholder="Enter the message" onChange={(event)=>{handleSelectedValue(event,'initiateMessage','message',setFieldValue)}}  id="initiateMessage" maxlength="255"/>
                                </div> }
                                <div className="microflow-settings__row">
                                    <label>Success Message</label>
                                    <ToggleSwitch checked={values?.["successMessage"]?.enabled} onChange={(event)=>{handleSelectedValue(event, 'successMessage',"enabled",setFieldValue)}}/>
                                </div>
                                { values?.["successMessage"]?.enabled && <div className="microflow-settings__row">
                                    <TextArea value={values?.["successMessage"]?.message} placeholder="Enter the message" onChange={(event)=>{handleSelectedValue(event,'successMessage','message',setFieldValue)}} id="successMessage" maxlength="255"/>
                                </div> }
                                <div className="microflow-settings__row">
                                    <label>Error Message</label>
                                    <ToggleSwitch checked={values?.["errorMessage"]?.enabled} onChange={(event)=>{handleSelectedValue(event, 'errorMessage',"enabled",setFieldValue)}}/>
                                </div>
                                { values?.["errorMessage"]?.enabled && <div className="microflow-settings__row">
                                    <TextArea value={values?.["errorMessage"]?.message} placeholder="Enter the message" onChange={(event)=>{handleSelectedValue(event,'errorMessage','message',setFieldValue)}} id="errorMessage" maxlength="255"/>
                                </div> }
                                <div className="microflow-settings__row">
                                    <label>Enable Polling</label>
                                    <ToggleSwitch checked={values?.["enablePolling"]?.enabled} onChange={(event)=>{handleSelectedValue(event, 'enablePolling',"enabled",setFieldValue)}}/>
                                </div>
                                { values?.["enablePolling"]?.enabled && <div className="microflow-settings__row">
                                    <TextArea value={values?.["enablePolling"]?.triesCount} placeholder="No. of retries(0 to 3)" onChange={(event)=>{handleSelectedValue(event,'enablePolling','triesCount',setFieldValue)}} id="enablePolling" maxlength="1"/>
                                </div> }
                                { values?.["enablePolling"]?.enabled && <div className="microflow-settings__row">
                                    <TextArea value={values?.["enablePolling"]?.sleepTime} placeholder="Sleep time in s(1 to 3s)" onChange={(event)=>{handleSelectedValue(event,'enablePolling','sleepTime',setFieldValue)}} id="enablePolling" maxlength="1"/>
                                </div> }
                                <div className="microflow-settings__row">
                                    <label>Block The Screen</label>
                                    <ToggleSwitch checked={values?.["screenBlocking"]} onChange={(event)=>{handleSelectedValue(event, 'screenBlocking',null,setFieldValue)}}/>
                                </div>
                                <div className="microflow-settings__row">
                                    <label>Is Async</label>
                                    <ToggleSwitch checked={values?.["isAsync"]} onChange={(event)=>{handleSelectedValue(event, 'isAsync',null,setFieldValue)}}/>
                                </div>
                                <div className="microflow-settings__row">
                                    <label>Linking Polling Microflow</label>
                                    <ToggleSwitch checked={values?.["linkedMicroflow"]?.enabled} onChange={(event)=>{handleSelectedValue(event,'linkedMicroflow',"enabled",setFieldValue)}}/>
                                </div>
                                { values?.["linkedMicroflow"]?.enabled && <div className="microflow-settings__row">
                                <SelectMenu value={values["linkedMicroflow"]?.microflow} options={microflowListingData} onChange={(event)=>{handleSelectedValue(event,'linkedMicroflow','microflow',setFieldValue)}}/>
                                </div> }
                                <div className="microflow-settings__row">
                                    <label>Notify In Consumer Journey</label>
                                    <ToggleSwitch checked={values?.["consumerNotification"]} onChange={(event)=>{handleSelectedValue(event,'consumerNotification',null,setFieldValue)}}/>
                                </div>
                                <div className="microflow-settings__row">
                                    <label>Notify In User Journey</label>
                                    <ToggleSwitch checked={values?.["userNotification"]} onChange={(event)=>{handleSelectedValue(event,'userNotification',null,setFieldValue)}}/>
                                </div>
                                { (values?.["consumerNotification"] || values?.["userNotification"]) && <div className="add-microflow__row">
                                    <TextArea value={values["popupHeading"]} placeholder="Popup Heading" onChange={(event)=>{handleSelectedValue(event,'popupHeading',null,setFieldValue)}} onBlur={handleBlur} id="popupHeading" maxlength="255"/>
                                </div> }
                                <div className="microflow-settings__row">
                                    <label>Config Response Fields To Exclude</label>
                                    <ToggleSwitch checked={values?.["excludeResponse"]} onChange={(event)=>{handleSelectedValue(event,'excludeResponse',null,setFieldValue)}}/>
                                </div>
                                { values?.["excludeResponse"] && <div className="microflow-settings__row">
                                        <div className="microflow-settings__box">
                                            <div>
                                                <input type="text" placeholder="Search for"/>
                                                <span className="microflow-settings__box--img"><img src={Images.search} alt=""/></span>
                                            </div>
                                            <div className="microflow-settings__container">
                                                {map(responseFields,(responseField,index)=>
                                                    <div className="microflow-settings__container--row" key={index}>
                                                        <span>{responseField}</span>
                                                        <Checkbox checked={values?.excludeResponseMap?.includes(responseField)} onChange={(event)=>{handleSelectedResponseArray(event, responseField,values?.excludeResponseMap, "excludeResponseMap", setFieldValue)}}/>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                </div> }
                                <div className="microflow-settings__row">
                                    <label>Config Response Fields To Save DB</label>
                                    <ToggleSwitch checked={values?.["saveToDB"]} onChange={(event)=>{handleSelectedValue(event,'saveToDB',null,setFieldValue)}}/>
                                </div>
                                { values?.["saveToDB"] && <div className="microflow-settings__row">
                                        <div className="microflow-settings__box">
                                            <div>
                                                <input type="text" placeholder="Search for"/>
                                                <span className="microflow-settings__box--img"><img src={Images.search} alt=""/></span>
                                            </div>
                                            <div className="microflow-settings__container">
                                                {map(responseFields,(responseField,index)=>
                                                    <div className="microflow-settings__container--row" key={index}>
                                                        <span>{responseField}</span>
                                                        <Checkbox checked={values?.saveToDBMap?.includes(responseField)} onChange={(event)=>{handleSelectedResponseArray(event, responseField,values?.saveToDBMap, "saveToDBMap", setFieldValue)}}/>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                </div> }
                                { <div className="add-microflow__row">
                                    <TextArea value={values["callbackWebhookUrl"]} placeholder="Callback Webhook Url" onChange={(event)=>{handleSelectedValue(event,'callbackWebhookUrl',null,setFieldValue)}} onBlur={handleBlur} id="callbackWebhookUrl" maxlength="255"/>
                                </div> }
                            </section>
                        </div>
                    </Form>
                )}}
            </Formik> }
        </div>
    );
}