import './style.scss';
import { Images } from 'assets/Images';
import * as moment from 'moment';
import { map } from 'lodash';

export default function VersionHistory({ data, handleClosePopUp, currentVersionData, handleSelectedVersion, handleCurrentVersion }){

    return (
        <div className="version-history">
            <header>
                <div className="version-history__group">
                    <span><img src={Images.audit2} alt=""/></span>
                    <h3>Version History</h3>
                </div>
                <span><img src={Images.close} alt="" onClick={()=>{handleClosePopUp(true)}}/></span>
            </header>
            <div className="version-history__body">
                <h6 onClick={()=>{handleCurrentVersion('')}}>Current version v {currentVersionData?.version}</h6>
                <div>
                    {map(data,(rowData,ind)=>
                        <div className="version-history__row" key={ind} onClick={()=>{handleSelectedVersion(rowData)}}>
                            <div className="version-history__group">
                                <div className="version-history__line-group">
                                    <div className="version-history__circle"></div>
                                    { data.length!==ind+1 && <div className="version-history__line"></div> }
                                </div>
                                <div className="version-history__logo">{rowData?.status?.charAt(0)}</div>
                                <div className="version-history__data">
                                    <div className="version-history__data--name">Version history V {rowData?.version||rowData?.version}</div>
                                    <div className="version-history__data--status">{rowData?.status}</div>
                                </div>
                            </div>
                            <div className="version-history__data--status">{moment(rowData?.modifiedTS).format('MM-DD-YYYY HH:mm')}</div>
                        </div>
                    )}
                </div>
            </div>

        </div>
    )
}
