import "./style.scss";
import { Images } from "assets/Images";
import { Button, ImageContainer, ConfirmationPopUp } from "components/generic";
import { AddIntegration } from "../add-integration";
import React, { useEffect, useRef, useState, useContext } from "react";
import { StepContainer } from "../step-container";
import { MicroflowSetUpContext } from 'providers';
import { ConfigFields } from "../config-fields";
import { TASK_ACTIONS_LIST, APPROVER_ACTIONS_LIST } from "config";
import { STEP_CONTAINER_TYPES } from "../../config";
import { useQuery } from '@tanstack/react-query';
import * as api from 'actions';
import * as apicall from 'actions/graphql';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';
import { decrypt } from 'utils/crypto';
import { useDispatch } from 'react-redux';
import { MicroflowContext } from 'providers';
import { toasterReducer } from 'redux/reducers/toaster.reducer';
import { MicroflowSettings } from "../microflow-settings";
import { isNumber } from "lodash";
import * as shortid from 'shortid';
import { MicroflowRules } from "../rules";
import VersionHistory from '../../../../generic/version-history/index.js';
import AuditComponent from 'components/generic/audit/index.js';
import { UiRange } from "components/generic/range";
import { DependencyMap } from "../dependency-map";

export function MicroflowSetup({ handleNavigateToListing }){

    const [ stepsData, setStepsData ] = useState([]);
    const [ microflowData, setMicroflowData ] = useState();
    const [ showSelectionOverlay, setShowSelectionOverlay ] = useState(null);
    const [ showMicroflowSettings, setShowMicroflowSettings ] = useState(null);
    const [ selectedContainer, setSelectedContainer ] = useState(null);
    const [ selectedAction, setSelectedAction ] = useState(null);
    const [ showConfigureRules, setShowConfigureRules ] = useState(false);
    const [ showVersionHistory, setShowVersionHistory ] = useState(false);
    const [ showAudit, setShowAudit ] = useState(false);
    const [ rangeValue, setRangeValue ] = useState(100);
    const [ showDependencyMap, setShowDependencyMap ] = useState(false);
    const [ showConfirmationPopUp, setShowConfirmationPopUp ] = useState(false);
    const [ showDeleteConfirmationPopup, setShowDeleteConfirmationPopup ] = useState(false);
    const [ actionList, setActionList ] = useState(TASK_ACTIONS_LIST);
    const parentContainerRef = useRef(null);
    const myRef = useRef(null);
    const microflowDataRef = useRef(null);
    const selectedUserDetail = useRef(null);
    const selectedContainerRef = useRef(null);
    const deleteContainerRef = useRef(null);
    const dispatch = useDispatch();
    const microflowContext = useContext(MicroflowContext);
    const currentVersionRef = useRef(null);
    const updatedMicroflowRef = useRef(null);
    const selectedVersionDataRef = useRef(null);
    const roles = decrypt(localStorage.getItem('_ud'))?.roles;
    const [ auditsData, setAuditsData ] = useState([]);
    const auditsPageNumber = useRef(1);
    const showLoadMoreAuditsOption = useRef(true);
    const disableAction = useRef(false);

    useEffect(()=>{
        microflowContext.selectedMicroflowDetail.steps && setStepsData(microflowContext.selectedMicroflowDetail.steps);
        setMicroflowData(microflowContext.selectedMicroflowDetail);
        if(microflowContext.selectedMicroflowDetail?.status === 'Pending'){
            // if(roles?.includes("CHECKER")) {
                setSelectedAction(APPROVER_ACTIONS_LIST[0]);
                setActionList(APPROVER_ACTIONS_LIST);
            // }
        } else if(microflowContext.selectedMicroflowDetail?.status === 'Draft' || microflowContext.selectedMicroflowDetail?.status === 'Rework'){    
            // if(roles?.includes("MAKER")) {
                setSelectedAction(TASK_ACTIONS_LIST[0]);
                setActionList(TASK_ACTIONS_LIST);
            // }
        } 
    },[]);

    /**
   * generate step id
   * @param prefix prefix string
   */
    const generateStepId = (prefix) => {
        return `${prefix ? `${prefix}_` : ''}${shortid.generate()}`;
    }

    const handleSelectedRow = (value, type) => {
        let blockData = structuredClone(value);
        updatedMicroflowRef.current = blockData;
        const data = parentContainerRef.current?.data || stepsData;
        const stepId = generateStepId(parentContainerRef.current?.parentStepId);
        if(blockData?.type === "RULE"){
            blockData = { isEnabled: true, type: 'RULE', entities: [blockData], stepId, passedEvent: [], failedEvent: [] };
        } else if(blockData?.name === "Goto"){
            blockData = { isEnabled: true, type: 'GOTO', entities: [blockData], stepId, passedEvent: [], failedEvent: [] };
        } else {
            if(type === "CONNECTORS") {
                blockData = { isEnabled: true, type: 'CONNECTORS', entities: [blockData], stepId };
            } else {
                //blockData = { isEnabled: true, type: 'ACTION', entities: [{name: blockData?.name, description: blockData?.description, type: blockData?.type}], stepId };
                blockData = { isEnabled: true, type: 'ACTION', entities: [blockData], stepId };
            }
        }
        if(isNumber(parentContainerRef.current?.index)){
            if(parentContainerRef.current?.position === "TOP"){
                data.splice(parentContainerRef.current?.index, 0, blockData);
            } else {
                if(parentContainerRef.current?.data[parentContainerRef.current?.index]?.type === "RULE"){
                    parentContainerRef.current?.data[parentContainerRef.current?.index]?.passedEvent.unshift(blockData);
                } else {
                    data.splice(parentContainerRef.current?.index+1, 0, blockData);
                }
            }
        } else {
            data.push(blockData);
        }
        setStepsData(stepsData);
        setShowSelectionOverlay(false);
        setTimeout(() => {
            microflowDataRef.current = blockData;
            if(isNumber(parentContainerRef.current?.index)){
                updateMicroflowWithParams();
            } else {
                updateMicroflow();
            }
        }, 100);
    }

    const handleCreateContainer = (parentContainerData, index, position, parentStepId) => {
        parentContainerRef.current = { data: parentContainerData, index, position, parentStepId };
        setShowSelectionOverlay(true);
    }

    const handleSubmitAction = (value) => {
        setShowConfirmationPopUp(true);
    }
    
    const handleAction = (actionName, data) => {
        switch(actionName){
            case TASK_ACTIONS_LIST[0]:
            case TASK_ACTIONS_LIST[1]:
                submitMicroflow();
                break;
            case APPROVER_ACTIONS_LIST[0]:
                approveMicroflow();
                break;
            case APPROVER_ACTIONS_LIST[1]:
                selectedUserDetail.current = data;
                rejectMicroflow();
                break;
            default:
                break;
        }
    }

    const handleLoadMoreAuditsData = () => {
        auditsPageNumber.current++;
        fetchAuditsData();
    }

    const handleSelectedVersion = (versionData) => {
        if(!versionData) { 
            setMicroflowData(microflowContext.selectedMicroflowDetail);
            setStepsData(microflowContext.selectedMicroflowDetail.steps);
        } else {
            selectedVersionDataRef.current = versionData;
            fetchMicroflowDataBasedOnId();
        }
        setShowVersionHistory(false);
    }

    const handleFetchAuditsData = (response) => {
        if(response?.data?.microflowAudits?.data?.length===0){
            showLoadMoreAuditsOption.current = false;
        }
        setAuditsData([...auditsData,...response?.data?.microflowAudits?.data]);
        setShowAudit(true);
    }

    const handleActionOnSuccess = (response) => {
        dispatch(toasterReducer({showToaster: true, message: response?.message, isError: response?.code!==200}));
        if(response.code === 200){
            microflowContext.setShowMicroflowDetails(false);
        }
    }

    const handleApproveActionOnSuccess = (response) => {
        dispatch(toasterReducer({showToaster: true, message: response?.message, isError: response?.code!==200}));
        if(response.code === 200){
            setShowConfirmationPopUp(false);
            currentVersionRef.current = response.data.version;
            disableAction.current = true;
            setShowDependencyMap(true);
            //microflowContext.setShowMicroflowDetails(false);
        }
    }

    const handleUpdateOnSuccess = (response) => {
        if(response?.data?.steps?.entities?.[0]?.nodeId && updatedMicroflowRef.current){
            updatedMicroflowRef.current.nodeId = response?.data?.steps?.entities?.[0]?.nodeId;
        }
        microflowData.modifiedTS = response?.data?.modifiedTS || "";
        updatedMicroflowRef.current = null;
        // setStepsData(structuredClone(stepsData));
        //dispatch(toasterReducer({showToaster: true, message: response?.message, isError: response?.code!==200}));
    }

    const populateMicroflowData = (response) => {
        setMicroflowData(structuredClone(response?.data));
        setStepsData(structuredClone(response?.data?.steps));
    }

    const handleDeleteOnSuccess = (response) => {
        dispatch(toasterReducer({showToaster:true, message: response?.message, isError: response?.code !== 200}));
        setShowDeleteConfirmationPopup(false);
        updatedMicroflowRef.current = null;
    }

    const handleDeleteContainer = (containerData,index, stepContainers) => {
        deleteContainerRef.current = {
            containerData, index, stepContainers
        }
        setShowDeleteConfirmationPopup(true);
    }

    const deleteContainerAction = (inputFields) => {
        const { containerData,index, stepContainers } = inputFields;
        stepContainers.splice(index,1);
        updatedMicroflowRef.current = containerData;
        deleteContainer();
        setMicroflowData(structuredClone(microflowData));
    }

    const { refetch: fetchMicroflowDataBasedOnId } =useQuery(['FETCH_SELECTED_MICROFLOW_DATA'], ()=> apicall.fetchMicroflowDetails('v2/graphql',selectedVersionDataRef?.current?.microflowId,selectedVersionDataRef?.current?.version, selectedVersionDataRef?.current?.status),
    {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: populateMicroflowData
    })

    const { refetch: submitMicroflow } = useQuery(['SUBMIT_MICROFLOW'], ()=> api.postMethodCall(ADMIN_API_AXIOS,`v2/microflow/${microflowData?.microflowId}/${microflowData?.version}/submit`,{
        modifiedTS: microflowData?.modifiedTS,
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleActionOnSuccess
    })

    const { refetch: deleteContainer } = useQuery(['DELETE_CONTAINER'], ()=> api.deleteMethodCall(ADMIN_API_AXIOS,`v2/microflow/${microflowData?.microflowId}/${microflowData?.version}/${updatedMicroflowRef.current?.entities?.[0]?.nodeId}/delete`,{
        modifiedTS: microflowData?.modifiedTS,
        deleteNode: {
            stepId: updatedMicroflowRef.current.stepId,
            ruleDepth: updatedMicroflowRef.current.ruleDepth
        }
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleDeleteOnSuccess
    })

    const { refetch: updateMicroflow } = useQuery(['UPDATE_MICROFLOW'], ()=> api.putMethodCall(ADMIN_API_AXIOS,`v2/microflow/${microflowData?.microflowId}`,{
        modifiedTS: microflowData?.modifiedTS,
        updateData: {
            steps: microflowDataRef?.current
        }
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleUpdateOnSuccess
    })

    const { refetch: updateMicroflowWithParams } = useQuery(['UPDATE_MICROFLOW_WITH_PARAMS'], ()=> api.putMethodCall(ADMIN_API_AXIOS,`v2/microflow/${microflowData?.microflowId}?action=INSERT`,{
        modifiedTS: microflowData?.modifiedTS,
        updateData: {
            steps: microflowDataRef?.current
        }
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleUpdateOnSuccess
    })

    const { data: versionHistoryData, refetch: fetchVersionHistoryDetails } =useQuery(['FETCH_VERSION_HISTORY'], ()=> apicall.fetchMicroflowVersionHistoryDetails('v2/graphql',microflowData?.microflowId), 
    {
        enabled: false,
        refetchOnWindowFocus: false,
    })

    const { refetch: fetchAuditsData } =useQuery(['FETCH_AUDITS_MICRFOLOW_DATA'], ()=> apicall.fetchMicroflowAudits(`/v2/graphql`,microflowData?.microflowId, auditsPageNumber.current),
    {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleFetchAuditsData
    })

    const { refetch: approveMicroflow } = useQuery(['APPROVE_MICROFLOW'], ()=> api.postMethodCall(ADMIN_API_AXIOS,`v2/microflow/${microflowData?.microflowId}/${microflowData?.version}/approve`,{
        modifiedTS: microflowData?.modifiedTS
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleApproveActionOnSuccess
    })

    const handleCreateDraft = (response) => {
        dispatch(toasterReducer({showToaster:true, message: response?.message, isError: response?.code !== 200}));
        handleNavigateToListing();
    }

    const fetchInputOuputField = (selectedContainer) => {
        selectedContainerRef.current = structuredClone(selectedContainer);
        fetchInputOutputFields();
    }

    const fetchInputOuputFieldData = (response) => {
        const data = response?.data?.microflowConfigsDetails?.data?.[0];
        if(data?.responseMap) {
            data.responseMap = data.responseMap?.map(responseField=>{
                if(responseField.hasOwnProperty("value")){
                    return responseField;
                } else {
                    return { key: "", value: responseField.key };
                }
            });
        }
        setSelectedContainer(((data||[])));
    }

    const handleSaveSettings = (microflowVal) => {
        setMicroflowData({ ...microflowData, ...microflowVal});
        setShowMicroflowSettings(false);
    }

    const handleUpdateModifiedTs = (modifiedTS) => {
        setMicroflowData({ ...microflowData, modifiedTS: modifiedTS});
    }

    const saveRule = (response) => {
        selectedContainer.entities[0].id = response?.configId;
        selectedContainer.entities[0].name = response?.name;
        selectedContainer.entities[0].description = response?.description;
        setStepsData(structuredClone(stepsData));
        setSelectedContainer(null);
        microflowDataRef.current = selectedContainer;
        setTimeout(()=>{
            updateMicroflow();
        },100);
    }

    const { refetch: createDraft } = useQuery(['CREATE_DRAFT'], ()=> api.postMethodCall(ADMIN_API_AXIOS,`/v2/microflow/${microflowData?.microflowId}/${microflowData?.version}/create-draft?status=Published`,{
        modifiedTS: microflowData?.modifiedTS
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleCreateDraft
    })

    const { refetch: fetchInputOutputFields } =useQuery(['FETCH_INPUT_OUTPUT_FIELDS'], ()=> apicall.fetchNodeDetails('v2/graphql',microflowData?.microflowId,microflowData?.version,selectedContainerRef.current?.entities?.[0]?.nodeId), 
    {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: fetchInputOuputFieldData
    })

    const { refetch: rejectMicroflow } = useQuery(['REJECT_MICROFLOW'], ()=> api.postMethodCall(ADMIN_API_AXIOS,`v2/microflow/${microflowData?.microflowId}/${microflowData?.version}/sendback`,{
        // comments: selectedUserDetail?.current,
        modifiedTS: microflowData?.modifiedTS
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleActionOnSuccess
    })

    return (
        <MicroflowSetUpContext.Provider value={{handleCreateContainer, handleSelectedRow, selectedContainer, setSelectedContainer, showConfigureRules, setShowConfigureRules, handleDeleteContainer, fetchInputOuputField}}>
            <div className="microflow-setup">
                <div className="microflow-setup__body">
                    <div className="microflow-setup__header">
                        <div className="microflow-setup__header--backoption">
                            <img src={Images.backicon} alt="" className="microflow-setup__backicon" onClick={handleNavigateToListing}/>
                            <span className="microflow-setup__header--text">Microflow <strong>/ {microflowData?.name}</strong></span>
                        </div>
                        <div className="microflow-setup__action">
                            { (microflowData?.status==='Submitted'|| microflowData?.status==='Pending'||microflowData?.status==='Published') && <span className="microflow-setup__readonly">
                                <span><img src={Images.readonly} alt=""/></span>
                                <span className="microflow-setup__readonly--text">Read only mode</span>
                            </span> }
                            <span><img src={Images.globalSettings} alt="" onClick={()=>{setShowMicroflowSettings(true)}}/></span>
                            <span><img src={Images.audit} alt="" onClick={()=>{fetchAuditsData();}}/></span>
                            <span  className="microflow-setup__version" onClick={()=>{fetchVersionHistoryDetails();setShowVersionHistory(true);}}>V {microflowData?.version}</span>
                            { (microflowData?.version===microflowContext?.selectedMicroflowDetail?.version && microflowData?.status==='Published') && <Button label={'CREATE DRAFT'} handleClick={createDraft} disabled={microflowData?.status!=='Published'}/> }
                             { !disableAction.current && microflowData?.version===microflowContext?.selectedMicroflowDetail?.version && microflowData?.status!=='Published' && selectedAction && stepsData?.length>0 && <Button label={selectedAction} secondary={true} optionList={actionList} isMultiple={actionList?.length>1} handleClick={(event)=>{handleSubmitAction(event)}} handleSelectedOption={(event)=>{setSelectedAction(event);handleSubmitAction(event);}}/> }
                        </div>
                    </div>
                    <div className="microflow-setup__main">
                        <div className="microflow-setup__section" style={{zoom: `${rangeValue}%`}}>
                            { stepsData?.length===0 && <div className="microflow-setup__emptydata">
                                <ImageContainer label="Click to get started" placeholderImg={Images.addNew} handleContainerClick={()=>{setShowSelectionOverlay(true)}} enableCursor={true} />
                            </div> }
                                { stepsData?.length>0 && <div className="microflow-setup__region" ref={myRef}>
                                    {/* <div className="microflow-setup__mytest"> */}
                                        <StepContainer stepContainers={stepsData} stepContainerType={STEP_CONTAINER_TYPES.DEFAULT} stepPointerStr="" parentStepId="" disabled={(microflowData?.status==='Submitted' || microflowData?.status==='Pending'|| microflowData?.status==='Published')}/>
                                    {/* </div> */}
                                </div>}
                        </div>
                    </div>
                </div>
                {selectedContainer && <div className="microflow-setup__overlay">
                    <div className="microflow-setup__overlay--content">
                        <ConfigFields selectedEntity={selectedContainer} closeOverlay={()=>{setSelectedContainer(null)}} microflowData={microflowData} containerData={selectedContainerRef.current} nodeId={selectedContainerRef.current?.entities?.[0]?.nodeId} disabled={(microflowData?.status==='Submitted' || microflowData?.status==='Pending'|| microflowData?.status==='Published')}/>
                    </div>
                </div>}
                { showSelectionOverlay && <div className="microflow-setup__overlay">
                    <div className="microflow-setup__overlay--content">
                        <AddIntegration closeOverlay={()=>{setShowSelectionOverlay(false)}} handleSelectedRow={handleSelectedRow}/>
                    </div>
                </div> }
                { showMicroflowSettings && <div className="microflow-setup__overlay">
                    <div className="microflow-setup__overlay--content">
                        <MicroflowSettings disabled={(microflowData?.status==='Submitted' || microflowData?.status==='Pending'|| microflowData?.status==='Published')} closeOverlay={()=>{setShowMicroflowSettings(false);}} handleSave={(microflowVal)=>{handleSaveSettings(microflowVal)}} initialValues={structuredClone(microflowData)} microflowId={microflowData?.microflowId} microflowVersion={microflowData?.version} updateModifiedTs={(modifedTS)=>{handleUpdateModifiedTs(modifedTS)}}/>
                    </div>
                </div> }
                { showConfigureRules && <div className="microflow-setup__overlay">
                    <div className="microflow-setup__overlay--content">
                        <MicroflowRules closeOverlay={()=>{setShowConfigureRules(false);setSelectedContainer(null);}} rulesData={selectedContainer} saveRule={saveRule} disabled={(microflowData?.status==='Submitted' || microflowData?.status==='Pending'|| microflowData?.status==='Published')}/>
                    </div>
                </div> }
                { showDependencyMap && <div className="microflow-setup__overlay">
                    <div className="microflow-setup__overlay--content">
                        <DependencyMap microflowId={microflowData?.microflowId} microflowVersion={currentVersionRef.current} closeOverlay={()=>{setShowDependencyMap(false)}}/>
                    </div>
                </div> }
                <div className="microflow-setup__range">
                    <UiRange rangeValue={rangeValue} setRangeValue={setRangeValue}/>
                </div>
                { showDeleteConfirmationPopup && <ConfirmationPopUp label={(deleteContainerRef?.current?.containerData?.type==="RULE")?"This will delete current node and all its child. Do you want to proceed?":"Are you sure you want to delete the current node?"} isCompleted={false}  handleClose={()=>{setShowDeleteConfirmationPopup(false)}} handleSubmit={(event)=>{deleteContainerAction(deleteContainerRef.current)}}/> }
                { showConfirmationPopUp && <ConfirmationPopUp label={selectedAction===APPROVER_ACTIONS_LIST[0]? 'Are you sure you want to approve?':((selectedAction===APPROVER_ACTIONS_LIST[1])?'Are you sure you want to send for rework?':((selectedAction===TASK_ACTIONS_LIST[0])?'Are you sure you want to submit for review?':'Are you sure you want to submit for approval?'))} showTextBox={false} isCompleted={false} inProgressLabel="In progress" completedLabel={selectedAction===APPROVER_ACTIONS_LIST[0]? 'Approved Successfully?':((selectedAction===APPROVER_ACTIONS_LIST[1])?'Rejected Successfully':((selectedAction===TASK_ACTIONS_LIST[0])?'Submitted for Review Successfully':'Submitted for Approval Successfully'))} handleClose={()=>{setShowConfirmationPopUp(false)}} handleSubmit={(event)=>{handleAction(selectedAction,event)}}/> }
                { (showVersionHistory || showAudit) && <div className="microflow-setup__overlay">
                <div className="microflow-setup__overlay--content">
                    { showAudit && <AuditComponent auditData={auditsData} showLoadMoreAuditsOption={showLoadMoreAuditsOption.current} handleClosePopUp={()=>{setShowAudit(false);setAuditsData([])}} handleLoadMore={()=>{handleLoadMoreAuditsData()}}/> }
                    { showVersionHistory && <VersionHistory data={versionHistoryData?.data?.microflowVersionHistory?.data} currentVersionData={microflowContext.selectedMicroflowDetail} handleClosePopUp={()=>{setShowVersionHistory(false)}} handleSelectedVersion={handleSelectedVersion} handleCurrentVersion={handleSelectedVersion}/> }
                </div>
                </div> }
            </div>
        </MicroflowSetUpContext.Provider>
    )
}