import { Button, TextBox } from "components/generic";
import { isObject, map } from "lodash";
import { useEffect, useState } from "react";
import { Images } from "assets/Images";

export function DocumentArrayPopup({ requestField, closeOverlay }){

    const [ formValue, setFormValue ] = useState((requestField?.value));

    useEffect(()=>{
        if(!requestField?.value) {
            setFormValue([{name:""}]);
        } else {
            setFormValue(JSON.parse(formValue));
        }
    },[]);

    const handleSelectedValue = (event, key, value) => {
        event = isObject(event)?event?.target?.value:event;
        value[key] = event;
        setFormValue(structuredClone(formValue));
    }

    const handleSave = () => {
        requestField.value = JSON.stringify(formValue);
        closeOverlay();
    }

    const handleAddNewRow = () => {
        const value = structuredClone(formValue);
        if(value?.length<=10){
            value.push({name:""});
            setFormValue(value);
        }
    }

    const handleDeleteRow = (index) => {
        const value = structuredClone(formValue);
        value.splice(index,1);
        setFormValue(structuredClone(value));
    }

    return (
        <div className="action-popup">
            <div className="action-popup__main">
                <header>DOCUMENT ARRAY</header>
                { <section>
                    <h4 className="action-popup__seperator--bottom">Documents</h4>
                    <div className="action-popup__rowgroup">
                        {map(formValue,((field,index)=>
                            <div key={index} className="action-popup__rowgroup">
                                <div className="action-popup__box"><TextBox placeholder="Name" type="text" value={field["key"]} onChange={(event)=>{handleSelectedValue(event,"name",field)}}/></div>
                                <span className="action-popup__seperator--bottom"><img src={Images.delete} alt="" onClick={()=>{handleDeleteRow(index)}}/></span>
                                <div className="action-popup__btn action-popup__seperator--top" onClick={()=>{handleAddNewRow()}}>
                                    <img src={Images.addrow} alt=""/>
                                    <span>Add More</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="action-popup__btn action-popup__seperator--top" onClick={()=>{handleAddNewRow()}}>
                        <img src={Images.addrow} alt=""/>
                        <span>Add More</span>
                    </div>
                </section> }
            </div>
            <footer>
                <div className="action-popup__actions">
                    <Button label="CANCEL" handleClick={closeOverlay}/>
                    <Button label="SAVE" secondary={true} handleClick={handleSave}/>
                </div>
            </footer>
        </div>
    )
}