import { Button, TextBox } from "components/generic";
import { isObject, map } from "lodash";
import React, { useEffect, useState } from "react";
import { Images } from "assets/Images";

export function DataArrayPopup({ requestField, closeOverlay }){

    const [ formValue, setFormValue ] = useState((requestField?.value));

    useEffect(()=>{
        if(!requestField?.value) {
            setFormValue({keys:[],multidataKeys:[]});
        } else {
            setFormValue(JSON.parse(formValue));
        }
    },[]);

    const handleSelectedValue = (event, key, value) => {
        event = isObject(event)?event?.target?.value:event;
        value[key] = event;
        setFormValue(structuredClone(formValue));
    }

    const handleSave = () => {
        requestField.value = JSON.stringify(formValue);
        closeOverlay();
    }

    const handleAddNewRow = (type,index) => {
        const value = structuredClone(formValue);
        if(type === "keys") {
            value.keys.push({key:""});
        } else if(type === "multidataKey"){
            value.multidataKeys[index].keys.push({key:""},{key:""},{key:""},{key:""},{key:""});
        } else {
            value.multidataKeys.push({
            "fieldkey": "",
            "templateId": "",
            "sectionIndex": "",
            "entryIndex": "",
            "recordReferenceKey": "",
            "keys": []
            });
        }
        setFormValue(value);
    }

    const handleDeleteRow = (index, type, subIndex, isNestedKey) => {
        const value = structuredClone(formValue);
        if(isNestedKey){
            value[type]?.[subIndex]?.keys?.splice(index,1);
        } else {
            value[type].splice(index,1);
        }
        setFormValue(structuredClone(value));
    }

    return (
        <div className="action-popup">
            <div className="action-popup__main">
                <header>DATA ARRAY</header>
                { <section>
                    <h4 className="action-popup__seperator--bottom">Keys</h4>
                    <div className="action-popup__rowgroup">
                        {map(formValue.keys,((field,index)=>
                            <div key={index} className="action-popup__rowgroup">
                                <div className="action-popup__box"><TextBox placeholder="Key" type="text" value={field["key"]} onChange={(event)=>{handleSelectedValue(event,"key",field)}}/></div>
                                <span className="action-popup__seperator--bottom"><img src={Images.delete} alt="" onClick={()=>{handleDeleteRow(index,"keys")}}/></span>
                            </div>
                        ))}
                    </div>
                    <div className="action-popup__btn action-popup__seperator--bottom" onClick={()=>{handleAddNewRow("keys")}}>
                        <img src={Images.addrow} alt=""/>
                        <span>Add Keys</span>
                    </div>
                    <h4 className="action-popup__seperator--bottom">Copy a record from multidata to a multidata or individual keys</h4>
                    <div>
                        {map(formValue.multidataKeys,((field,index)=>
                            <React.Fragment key={index}>
                                <div style={{width:"fit-content"}}>
                                    <div key={index} className="action-popup__rowgroup">
                                        <div className="action-popup__box"><TextBox placeholder="Multidata*" type="text" value={field["fieldkey"]} onChange={(event)=>{handleSelectedValue(event,"fieldkey",field)}}/></div>
                                        <div className="action-popup__box"><TextBox placeholder="Template ID*" type="text" value={field["templateId"]} onChange={(event)=>{handleSelectedValue(event,"templateId",field)}}/></div>
                                        <div className="action-popup__box"><TextBox placeholder="Section Index" type="text" value={field["sectionIndex"]} onChange={(event)=>{handleSelectedValue(event,"sectionIndex",field)}}/></div>
                                        <div className="action-popup__box"><TextBox placeholder="Entry Index" type="text" value={field["entryIndex"]} onChange={(event)=>{handleSelectedValue(event,"entryIndex",field)}}/></div>
                                        <div className="action-popup__box"><TextBox placeholder="ReferenceKey" type="text" value={field["recordReferenceKey"]} onChange={(event)=>{handleSelectedValue(event,"recordReferenceKey",field)}}/></div>
                                        <span className="action-popup__seperator--bottom"><img src={Images.delete} alt="" onClick={()=>{handleDeleteRow(index,"multidataKeys")}}/></span>
                                        <div className="action-popup__btn action-popup__seperator--bottom" onClick={()=>{handleAddNewRow("multidataKey",index)}}>
                                            <img src={Images.addrow} alt=""/>
                                            <span>Add Keys</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:"fit-content",flexWrap:"wrap"}} className="action-popup__col">
                                    {map(field?.keys,(key,inde)=>
                                        <React.Fragment key={inde}>
                                            <div className="action-popup__box" key={inde}><TextBox placeholder="Key" type="text" value={field["key"]} onChange={(event)=>{handleSelectedValue(event,"key",key)}}/></div>
                                            <span className="action-popup__seperator--bottom"><img src={Images.delete} alt="" onClick={()=>{handleDeleteRow(inde,"multidataKeys",index,true)}}/></span>
                                        </React.Fragment>
                                    )}
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="action-popup__btn" onClick={()=>{handleAddNewRow("multidata")}}>
                        <img src={Images.addrow} alt=""/>
                        <span>Add Multidata</span>
                    </div>
                </section> }
            </div>
            <footer>
                <div className="action-popup__note"><strong>Note: </strong>Above keys will be resolved at server level (not from screen). Hence, the keys should be saved before invoking the action. Design your process accordingly.</div>
                <div className="action-popup__actions">
                    <Button label="CANCEL" handleClick={closeOverlay}/>
                    <Button label="SAVE" secondary={true} handleClick={handleSave}/>
                </div>
            </footer>
        </div>
    )
}