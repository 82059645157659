import './style.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Images } from 'assets/Images';
import { toasterReducer } from 'redux/reducers/toaster.reducer';

export default function Toaster({message, isError}){
    const dispatch = useDispatch();
    
    useEffect(() => {
        setTimeout(() => {
            dispatch(toasterReducer({ isToaster: false }))
        }, 1500);
    }, []);
    
    return (
        <div className="toaster">
            <div>
                <span>
                    { !isError && <img src={Images.success} alt=""/> }
                    { isError && <img src={Images.error} alt=""/> }
                </span>
                <span className="toaster__msg">{message}</span>
            </div>
            <span className="toaster__close" onClick={()=>dispatch(toasterReducer({ isToaster: false }))}><img src={Images.crossicon} alt=""/></span>
        </div>
    )
}