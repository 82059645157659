import './style.scss';
import { useState } from 'react';
import { Images } from 'assets/Images';

export default function Search(props){
    const { widgetName, searchFunction } = props;
    const [value, setValue] = useState('');

    const handleInput = (e) => {
        setValue(e.target.value);
    }
    
    const handleKeyPress = (e) => {
        e.key === 'Enter' && searchFunction(value, widgetName);
    }

    return (
        <div className="search">
            <input type="text" placeholder="Search" autoComplete="off" onKeyPress={handleKeyPress} onChange={handleInput}/>
            <img src={Images.search} alt="" onClick={()=>searchFunction(value, widgetName)}/>
        </div>
    )
}