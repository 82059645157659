import './style.scss';
import { Button, TextBox, ToggleSwitch, Radio, Checkbox, TextArea, SelectMenu } from 'components/generic';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { useQuery } from '@tanstack/react-query';
import * as api from 'actions';
import * as apicall from 'actions/graphql';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';
import { toasterReducer } from 'redux/reducers/toaster.reducer';
import { useDispatch } from 'react-redux';
import { Images } from 'assets/Images';
import { useState, useContext, useRef } from 'react';
import { MicroflowContext } from 'providers';
import { isEmpty, isObject } from 'lodash';
import { MICROFLOW_INITIAL_VALUES } from '../../config';

export default function AddMicroflowPopup(props){
    const { handleClosePopUp, actionType } = props;
    const [ microflowListingData, setMicroflowListingData ] = useState([]);
    const microflowRefData = useRef(null);
    const [ currentPageNumber, setCurrentPageNumber ] = useState(1);
    let microflowValues = {};
    const dispatch = useDispatch();
    const microflowContext = useContext(MicroflowContext);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Required")
            .required("Required")
            .min(5, 'Name should be min 5 to Max 40 characters')
            .max(40,'Name should be min 5 to Max 40 characters')
            .matches(/^[a-zA-Z0-9\s]+$/, "Special characters are not allowed"),
        description: Yup.string()
            .required("Required")
            .min(50,'Description should be min 50 to Max 100 characters')
            .max(100,'Description should be min 50 to Max 100 characters'),
        isAsync: Yup.string()
            .required("Required"),
        popupHeading: Yup.string()
        .ensure().when('consumerNotification', {
            is: true,
            then: Yup.string().required()
            .min(5,'Popup Heading should be min 5 to Max 40 characters')
            .max(40,'Popup Heading should be min 5 to Max 40 characters'),
        }).when('userNotification', {
            is: true,
            then: Yup.string().required()
        })
    });

    const [ successState, setSuccessState ] = useState(false);
    
    const handleCreateMicroflowOnSuccess = (response) => {
        if(response?.code !== 200){
            dispatch(toasterReducer({showToaster: true, message: response?.message, isError: true}));
            handleClosePopUp();
        } else {
            setTimeout(() => {
                handleClosePopUp();
            }, 1000);
            setSuccessState(true);
        }
    }

    const populateMicroflowData = (response) => {
        const data = [];
        const microflowDropdownData = [];
        response?.data?.microflowConfigsDetails?.data?.forEach(microflowData=>{
            data.push({microflowId: microflowData?.microflowId, version: microflowData?.version, name: microflowData?.name});
            microflowDropdownData.push({label: microflowData?.name, value: microflowData?.name});
        });
        microflowRefData.current = data;
        setMicroflowListingData(microflowDropdownData);
    }

    const { refetch: getMicroflowListingData } = useQuery(['FETCH_MICROFLOW_LISTING_DATA'], ()=> apicall.fetchPublishedMicroflows(`/v2/graphql`), {  enabled: false, refetchOnWindowFocus: false, onSuccess: (data)=>{populateMicroflowData(data)} });

    const { refetch: handleAddNewMicroflow, isFetching } = useQuery(['ADD_NEW_MICROFLOW'], ()=> api.postMethodCall(ADMIN_API_AXIOS,`/v2/microflow`,microflowValues)
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (data)=>{handleCreateMicroflowOnSuccess(data)},
    })

    const { refetch: handleCopyNewMicroflow } = useQuery(['COPY_NEW_MICROFLOW'], ()=> api.postMethodCall(ADMIN_API_AXIOS,`/v2/microflow/${microflowContext.selectedMicroflowDetail?.microflowId}/${microflowContext.selectedMicroflowDetail?.version}/copy`,microflowValues)
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: (data)=>{handleCreateMicroflowOnSuccess(data)},
    })

    const handleButtonClick = (values) => {
        if(currentPageNumber === 2){
            let reqObj = values;
            if(reqObj.initiateMessage?.enabled && !reqObj.initiateMessage?.message ){
                dispatch(toasterReducer({showToaster: true, message: 'Initiate Message cant be empty', isError: true}));
                return ;
            }
            if(reqObj.successMessage?.enabled && !reqObj.successMessage?.message ){
                dispatch(toasterReducer({showToaster: true, message: 'Success Message cant be empty', isError: true}));
                return ;
            }
            if(reqObj.errorMessage?.enabled && !reqObj.errorMessage?.message ){
                dispatch(toasterReducer({showToaster: true, message: 'Error Message cant be empty', isError: true}));
                return ;
            }
            if(reqObj.enablePolling?.enabled && !reqObj.enablePolling?.triesCount ){
                dispatch(toasterReducer({showToaster: true, message: 'Tries count cant be empty', isError: true}));
                return ;
            }
            if(reqObj.enablePolling?.enabled && !reqObj.enablePolling?.sleepTime ){
                dispatch(toasterReducer({showToaster: true, message: 'Sleep Time cant be empty', isError: true}));
                return ;
            }
            if(values?.linkedMicroflow?.enabled && values?.linkedMicroflow?.microflow){
                microflowRefData?.current?.forEach(microflowVal=>{
                    if(microflowVal?.name === values?.linkedMicroflow?.microflow) {
                        values.linkedMicroflow.microflow = { microflowId: microflowVal?.microflowId, version: microflowVal?.version };
                    }
                })
            }
            if(values?.linkedMicroflow?.enabled && !values?.linkedMicroflow?.microflow?.microflowId){
                dispatch(toasterReducer({showToaster: true, message: 'Please select a microflow', isError: true}));
                return ;
            }
            if(!reqObj?.consumerNotification && !reqObj?.userNotification) {
                reqObj.popupHeading = "";
            }
            if(microflowContext.selectedMicroflowDetail){
                reqObj.copiedFromMicroflowName = microflowContext.selectedMicroflowDetail.name;
                reqObj.copiedFromMicroflowId = microflowContext.selectedMicroflowDetail.microflowId;
                reqObj.copiedFromVersion = microflowContext.selectedMicroflowDetail.version;
                reqObj = { ...microflowContext.selectedMicroflowDetail, ...reqObj };
                delete reqObj.version;
                delete reqObj.status;
                delete reqObj.microflowId;
            }
            microflowValues = reqObj;
            if(actionType === "COPY"){
                handleCopyNewMicroflow();
            } else {
                handleAddNewMicroflow();
            }
        } else {
            setCurrentPageNumber(currentPageNumber+1);
            getMicroflowListingData();
        }
    }

    const handleSelectedValue = (event, key, setFieldValue, nestedKey, isCheckbox) => {
        let value = isObject(event)?event?.target?.value:event;
        if(isCheckbox){
            value = event?.target?.checked;
        }
        if(nestedKey){
            setFieldValue(`${key}.${nestedKey}`,value);
        } else {
            setFieldValue([key],value);
        }
    }

    return (
      <div className="add-microflow">
            { !isFetching && !successState && <Formik initialValues={MICROFLOW_INITIAL_VALUES} validationSchema={validationSchema} onSubmit={(values)=>{handleButtonClick(values)}}>
            {formik => {
                const { values, errors, isValid, dirty, handleChange, handleSubmit, touched, handleBlur, setFieldValue } = formik;
                return (
                    <Form>
                        <div className="add-microflow__main">     
                            <div className="add-microflow__group">
                                <header>Define Microflow</header>
                                <div className="add-microflow__body">
                                    { currentPageNumber===1 && <>
                                        <div><TextBox type="text" label="Name" value={values['name']} onChange={handleChange} onBlur={handleBlur} id="name" mandatory={true} errorMessage={errors['name']} error={touched['name'] && errors?.hasOwnProperty('name')} maxlength="40"/></div>
                                        <div><TextBox type="text" label="Description" value={values['description']} onChange={handleChange} onBlur={handleBlur} id="description" mandatory={true} errorMessage={errors['description']} error={touched['description'] && errors?.hasOwnProperty('description')} maxlength="100"/></div>
                                        <div className="add-microflow__row">
                                            <div style={{flex:1}}><Radio inputList={[{label:"Sync", value:false},{label:"Async",value:true}]} value={values["isAsync"]} id="isAsync" handleInput={(event)=>{handleSelectedValue((event?.target?.value=== "true"),"isAsync",setFieldValue)}} label="Type Of Microflow" name="Type Of Microflow" mandatory={true}/></div>
                                            <div className="add-microflow__checkboxgroup">
                                                <Checkbox label="Enable Notification (Consumer Journey)" checked={values["consumerNotification"]} onChange={(event)=>{handleSelectedValue(event,"consumerNotification",setFieldValue,null,"checkbox")}}/>
                                                <Checkbox label="Enable Notification (User Journey)" checked={values["userNotification"]} onChange={(event)=>{handleSelectedValue(event,"userNotification",setFieldValue,null,"checkbox")}}/>
                                            </div>
                                        </div>
                                        { (values["consumerNotification"] || values["userNotification"]) && <TextBox type="text" label="Popup heading" value={values['popupHeading']} onChange={handleChange} onBlur={handleBlur} id="popupHeading" maxlength="40" mandatory={true} errorMessage={errors['popupHeading']} error={touched['popupHeading'] && errors?.hasOwnProperty('popupHeading')}/> }
                                        {/* <div className="add-microflow__row">
                                            <div className="add-microflow__group">
                                                <span className="add-microflow__group--key">Department</span>
                                                <span className="add-microflow__group--value">{userDetails?.departmentList?.[0]?.name}</span>
                                            </div>
                                            <div className="add-microflow__group">
                                                <span className="add-microflow__group--key">Team</span>
                                                <span className="add-microflow__group--value">{userDetails?.teamList?.[0]?.name}</span>
                                            </div>
                                        </div> */}
                                    </> }
                                    { currentPageNumber===2 && <>
                                        <div className="add-microflow__row">
                                            <label>Initiate Message</label>
                                            <ToggleSwitch checked={values["initiateMessage"]?.enabled} onChange={(event)=>{handleSelectedValue(event, 'initiateMessage',setFieldValue,"enabled")}}/>
                                        </div>
                                        { values["initiateMessage"]?.enabled && <div className="add-microflow__row">
                                            <TextArea value={values["initiateMessage"]?.message} placeholder="Enter the message" onChange={(event)=>{handleSelectedValue(event,'initiateMessage',setFieldValue,'message')}} onBlur={handleBlur} id="initiateMessage" maxlength="255"/>
                                        </div> }
                                        <div className="add-microflow__row">
                                            <label>Success Message</label>
                                            <ToggleSwitch checked={values["successMessage"]?.enabled} onChange={(event)=>{handleSelectedValue(event, 'successMessage',setFieldValue,"enabled")}}/>
                                        </div>
                                        { values["successMessage"]?.enabled && <div className="add-microflow__row">
                                            <TextArea value={values["successMessage"]?.message} placeholder="Enter the message" onChange={(event)=>{handleSelectedValue(event,'successMessage',setFieldValue,'message')}} onBlur={handleBlur} id="successMessage" maxlength="255"/>
                                        </div> }
                                        <div className="add-microflow__row">
                                            <label>Error Message</label>
                                            <ToggleSwitch checked={values["errorMessage"]?.enabled} onChange={(event)=>{handleSelectedValue(event, 'errorMessage',setFieldValue,"enabled")}}/>
                                        </div>
                                        { values["errorMessage"]?.enabled && <div className="add-microflow__row">
                                            <TextArea value={values["errorMessage"]?.message} placeholder="Enter the message" onChange={(event)=>{handleSelectedValue(event,'errorMessage',setFieldValue,'message')}} onBlur={handleBlur} id="errorMessage" maxlength="255"/>
                                        </div> }
                                        <div className="add-microflow__row">
                                            <label>Enable Polling</label>
                                            <ToggleSwitch checked={values["enablePolling"]?.enabled} onChange={(event)=>{handleSelectedValue(event, 'enablePolling',setFieldValue,"enabled")}}/>
                                        </div>
                                        { values["enablePolling"]?.enabled && <div className="add-microflow__row">
                                            <TextArea value={values["enablePolling"]?.triesCount} placeholder="No. of retries(0 to 3)" onChange={(event)=>{handleSelectedValue(event,'enablePolling',setFieldValue,'triesCount')}} onBlur={handleBlur} id="enablePolling" maxlength="1"/>
                                        </div> }
                                        { values["enablePolling"]?.enabled && <div className="add-microflow__row">
                                            <TextArea value={values["enablePolling"]?.sleepTime} placeholder="Sleep time in s(1 to 3s)" onChange={(event)=>{handleSelectedValue(event,'enablePolling',setFieldValue,'sleepTime')}} onBlur={handleBlur} id="enablePolling" maxlength="1"/>
                                        </div> }
                                        <div className="add-microflow__row">
                                            <label>Block The Screen</label>
                                            <ToggleSwitch checked={values["screenBlocking"]} onChange={(event)=>{handleSelectedValue(event, 'screenBlocking',setFieldValue)}}/>
                                        </div>
                                        <div className="add-microflow__row">
                                            <label>Linking Polling Microflow</label>
                                            <ToggleSwitch checked={values["linkedMicroflow"]?.enabled} onChange={(event)=>{handleSelectedValue(event, 'linkedMicroflow',setFieldValue,"enabled")}}/>
                                        </div>
                                        { values["linkedMicroflow"]?.enabled && <div className="add-microflow__row">
                                            <SelectMenu value={values["linkedMicroflow"]?.microflow} options={microflowListingData} onChange={(event)=>{handleSelectedValue(event,'linkedMicroflow',setFieldValue,'microflow')}}/>
                                        </div> }
                                        { <div className="add-microflow__row">
                                            <TextArea value={values["callbackWebhookUrl"]} placeholder="Callback Webhook Url" onChange={(event)=>{handleSelectedValue(event,'callbackWebhookUrl',setFieldValue)}} onBlur={handleBlur} id="callbackWebhookUrl" maxlength="255"/>
                                        </div> }
                                    </>}
                                </div>
                            </div>                
                            <footer>
                                <div className="add-microflow__note"><strong>Note: </strong>Microflow name and description cannot be changed once added</div>
                                <div className="add-microflow__actions">
                                    { currentPageNumber!==1 && <span className="add-microflow__actions--back" onClick={()=>{setCurrentPageNumber(currentPageNumber-1)}}>Back</span> }
                                    <Button label="CANCEL" handleClick={handleClosePopUp}/>
                                    <Button label={currentPageNumber!==2?"CONTINUE":"SAVE"} secondary={true} handleClick={(e) => {handleSubmit(e)}} disabled={!(dirty && isValid)} />
                                </div>
                            </footer>
                        </div>
                    </Form>
                )}}
            </Formik> }
            { isFetching && !successState && <>
                <div className="confirm-popup__group">
                    <img src={Images.shortloader} alt="" className="confirm-popup__loader"/>
                    <h5>{!isEmpty(microflowContext?.selectedMicroflowDetail)?'Copy':'Add'} in progress</h5>
                </div>
            </> }
            { !isFetching && successState && <>
                <div className="confirm-popup__group">
                    <img src={Images.successtick} alt=""/>
                    <h5>{!isEmpty(microflowContext?.selectedMicroflowDetail)?'Copied':'Added'} successfully</h5>
                </div>
            </> }
      </div>
    )
}
