import './style.scss';
import { Button, SelectMenu, TextBox } from 'components/generic';
import { Images } from 'assets/Images';
import { map, isObject, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { ROW_KEYS, ACCORDION_OPTIONS_TYPES } from '../../config';

export default function FieldConfigPopup({ value, handleClosePopUp, handleSubmit }){

    const [ accordionFields, setAccordionFields ] = useState([]);
    const [ errorData, setErrorData ] = useState({});

    useEffect(()=>{
        setAccordionFields(JSON.parse(JSON.stringify(value)));
    },[]);

    const addNewRow = () => {
        setAccordionFields([...accordionFields,...[{...(ROW_KEYS.response)}]])
    }

    const deleteRow = (index) => {
        const data = [ ...accordionFields ];
        data.splice(index,1);
        const errorObj = {};
        data?.forEach((field,ind)=>{
            if(!field.key){
                errorObj[ind] = "Mandatory Field";
            }
        })
        setErrorData(errorObj);
        setAccordionFields(data);
    }

    const handleSubmitData = (accordionFields) => {
        const errorObj = {};
        accordionFields?.forEach((field,ind)=>{
            if(!field.key){
                errorObj[ind] = "Mandatory Field";
            }
        })
        setErrorData(errorObj);
        if(isEmpty(errorObj)){
            handleSubmit(accordionFields);
        }
    }

    const handleSelectedValue = (event, index, key) => {
        if(isObject(event)) event = event?.target?.value;
        const data = [ ...accordionFields ];
        data[index][key] = event;
        setAccordionFields(data);
    }

    return (
        <div className="field-config">
            <div className="field-config__main">
                <header>Configure fields</header>
                <div className="field-config__body">
                    {map(accordionFields, (accordionField, ind)=>
                        <div className="field-config__group" key={ind}>
                            <TextBox placeholder={'Request key '+(ind+1)+'*'} type="text" value={accordionField?.key} onChange={(event)=>{handleSelectedValue(event,ind,'key')}} errorMessage={errorData?.[ind]} error={errorData?.[ind]}/>
                            <div className="field-config__select">
                                <SelectMenu options={ACCORDION_OPTIONS_TYPES} value={accordionField?.fieldType}  onChange={(event)=>{handleSelectedValue(event,ind,'fieldType')}}/>
                            </div>
                            <span><img src={Images.deleterow} alt="" onClick={()=>{deleteRow(ind)}}/></span>
                        </div>
                    )}
                    <div className="field-config__btn" onClick={addNewRow}>
                        <img src={Images.addrow} alt=""/>
                        <span>Add Field</span>
                    </div>
                </div>
            </div>
            <footer>
                <div className="field-config__actions">
                    <Button label="CANCEL" handleClick={handleClosePopUp}/>
                    <Button label="SAVE" secondary={true} handleClick={(e) => {handleSubmitData(accordionFields)}}/>
                </div>
            </footer>
        </div> 
    )
}
