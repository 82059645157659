import { useState } from "react";
import { TASK_ACTIONS_LIST, APPROVER_ACTIONS_LIST } from "config";
import { Button } from "components/generic";
import { Images } from "assets/Images";
import "./style.scss";
import { FlowSettings } from "../flow-settings";
import { useNavigate } from "react-router-dom";

export function ConfigTask(){

    const navigate = useNavigate();
    const [ actionList, setActionList ] = useState(TASK_ACTIONS_LIST);
    const [ selectedAction, setSelectedAction ] = useState(APPROVER_ACTIONS_LIST[0]);
    const [ showSettings, setShowSettings ] = useState(false);

    const handleNavigateBack = () => {
        navigate(`/indesign/listing`);
    }

    return (
        <>
            <div className="config-task__header">
                <div className="config-task__header--backoption">
                    <img src={Images.backicon} alt="" className="config-task__backicon" onClick={handleNavigateBack}/>
                    <span className="config-task__header--text">Flow <strong>/ adsads</strong></span>
                </div>
                <div className="config-task__action">
                    <span><img src={Images.globalSettings} alt="" onClick={()=>{setShowSettings(true)}}/></span>
                    <span><img src={Images.audit} alt=""/></span>
                    <span className="microflow-setup__version">V 0.1</span>
                    <Button label={'CREATE DRAFT'} />
                    <Button label={selectedAction} secondary={true} optionList={actionList} isMultiple={true}/>
                </div>
            </div>
            { showSettings && <div className="flow-config__overlay">
                <div className="flow-config__overlay--box">
                    <FlowSettings closeOverlay={()=>{setShowSettings(false)}}/>
                </div>
            </div> }
        </>
    )
}