import { Images } from "assets/Images"

export const MICROFLOW_TABLE_HEADERS = [
    {
        key: 'microflowName',
        label: 'Name',
        sort: true,
        showBurstIcon: true,
        width: "30%"
    },
    {
        key: 'microflowVersion',
        label: 'Version',
        dataListKey: 'versions'
    },
    {
        key: 'createdBy.username',
        label: 'Created by',
        isNestedKey: true
    },
    {
        key: 'modifiedTS',
        label: 'Modified date',
        isDate: true
    }
]

export const MICROFLOW_TABLE_HEADERS_EXPANDED_VIEW = [
    {
        key: 'microflowName',
        label: 'Name',
        sort: true,
        showBurstIcon: true
    },
    {
        key: 'microflowVersion',
        label: 'Version',
        dataListKey: 'versions'
    },
    {
        key: 'createdBy.username',
        label: 'Created by',
        isNestedKey: true
    },
    {
        key: 'modifiedTS',
        label: 'Modified date',
        isDate: true
    },
    {
        key: 'workflowName',
        label: 'Workflow',
        dataListKey: 'workflows'
    },
    {
        key: 'stepName',
        label: 'Step Name',
        dataListKey: 'steps'
    },
    {
        key: 'sectionName',
        label: 'Section',
        dataListKey: 'sections',
        fieldkey: 'sectionId' 
    },
    {
        key: 'componentName',
        label: 'Sub Section',
        dataListKey: 'components',
        fieldkey: 'componentId'
    },
    {
        key: 'mappedComponentName',
        label: 'Component',
        dataListKey: 'mappedComponents'
    }
]

export const WORKFLOW_TABLE_HEADERS = [
    {
        key: 'workflowName',
        label: 'Name',
        sort: true,
        showBurstIcon: true
    },
    {
        key: 'isProcess',
        label: 'Flow type'
    },
    {
        key: 'createdBy.username',
        label: 'Created by',
        isNestedKey: true
    },
    {
        key: 'modifiedTS',
        label: 'Modified date',
        isDate: true
    }
]

export const WORKFLOW_TABLE_HEADERS_EXPANDED_VIEW = [
    {
        key: 'workflowName',
        label: 'Name',
        sort: true,
        showBurstIcon: true
    },
    {
        key: 'isProcess',
        label: 'Flow type'
    },
    {
        key: 'createdBy.username',
        label: 'Created by',
        isNestedKey: true
    },
    {
        key: 'modifiedTS',
        label: 'Modified date',
        isDate: true
    },
    {
        key: 'microflowName',
        label: 'Microflow',
        dataListKey: 'microflows'
    },
    {
        key: 'microflowVersion',
        label: 'Version',
        dataListKey: 'versions'
    },
    {
        key: 'stepName',
        label: 'Step name',
        dataListKey: 'steps'
    },
    {
        key: 'sectionName',
        label: 'Section',
        dataListKey: 'sections'
    },
    {
        key: 'mappedComponentName',
        label: 'Sub section',
        dataListKey: 'mappedComponents'
    },
    {
        key: 'mappedComponentName',
        label: 'Component',
        dataListKey: 'mappedComponents'
    }
]

export const CONNECTOR_TABLE_HEADERS = [
    {
        key: 'connectorName',
        label: 'Name',
        sort: true
    },
    {
        key: 'connectorVersion',
        label: 'Version',
        dataListKey: 'connectorVersions'
    },
    {
        key: 'createdBy.username',
        label: 'Created by',
        isNestedKey: true
    },
    {
        key: 'modifiedTS',
        label: 'Modified date',
        isDate: true
    }
]

export const CONNECTOR_TABLE_HEADERS_EXPANDED_VIEW = [
    {
        key: 'connectorName',
        label: 'Name',
        sort: true,
    },
    {
        key: 'connectorVersion',
        label: 'Version',
        dataListKey: 'connectorVersions'
    },
    {
        key: 'createdBy.username',
        label: 'Created by',
        isNestedKey: true
    },
    {
        key: 'modifiedTS',
        label: 'Modified date',
        isDate: true
    },
    {
        key: 'microflowName',
        label: 'Microflow',
        dataListKey: 'microflows'
    }
]

export const MICROFLOW_WORKFLOW_HEADERS = [
    {
        type: 'number'            
    },
    {
        key: 'workflowName',
        label: 'Workflow name'
    },
    {
        type: 'isProcess',
        label: 'Flow type'
    },
]

export const MICROFLOW_DETAILS_HEADERS = [
    {
        type: 'number'            
    },
    {
        key: 'stepName',
        label: 'Step name'
    },
    {
        key: 'sectionName',
        label: 'Section'
    },
    {
        key: 'componentName',
        label: 'Sub section'
    },
    {
        key: 'mappedComponentName',
        label: 'Component'
    },
    {
        key: 'triggerPoint',
        label: 'Trigger point'
    },
    {
        key: 'value',
        label: 'Label'
    },
    {
        key: 'key',
        label: 'Field key'
    },
]

export const WORKFLOW_HEADERS = [
    {
        type: 'number'            
    },
    {
        key: 'stepName',
        label: 'Step name'
    },
    {
        key: 'sectionName',
        label: 'Section'
    },
    {
        key: 'componentName',
        label: 'Sub section'
    },
    {
        key: 'mappedComponentName',
        label: 'Component'
    },
    {
        key: 'triggerPoint',
        label: 'Trigger point'
    },
    {
        key: 'value',
        label: 'Label'
    },
    {
        key: 'key',
        label: 'Field key'
    },
    {
        key: 'microflowName',
        label: 'Microflow name'
    },
    {
        key: 'microflowVersion',
        label: 'Version'
    }
]

export const MICROFLOW_WORKFLOW_POPUP = {
    label: 'Microflow details',
    value: [
        {
            key: 'microflowName',
            label: 'Microflow name'
        },
        {
            key: 'microflowVersion',
            label: 'Version'
        }
    ]
}

export const MICROFLOW_DETAILS_POPUP = {
    label: 'Microflow details',
    value: [
        {
            key: 'workflowName',
            label: 'Workflow name'
        },
        {
            type: 'isProcess',
            label: 'Flowtype'
        },
        {
            key: 'microflowName',
            label: 'Microflow name'
        },
        {
            key: 'microflowVersion',
            label: 'Version'
        }
    ]
}

export const WORKFLOW_POPUP = {
    label: 'Workflow details',
    value: [
        {
            key: 'workflowName',
            label: 'Workflow name'
        },
        {
            type: 'isProcess',
            label: 'Flowtype'
        }
    ]
}

export const INTEGRATION_TABS_LIST = ["Action", "Connectors"];

export const MICROFLOW_ACTION_LIST = [
    {
        label: 'COPY',
        img: Images.copy
    },
    {
        label: 'ADD_ABOVE',
        img: Images.forward
    },
    {
        label: 'ADD_BELOW',
        img: Images.backward
    },
    {
        label: 'EDIT',
        img: Images.edit
    },
    {
        label: 'DELETE',
        img: Images.delete
    }
]

export const MICROFLOW_RULE_ACTION_LIST = [
    {
        label: 'COPY',
        img: Images.copy
    },
    {
        label: 'ADD_ABOVE',
        img: Images.forward
    },
    {
        label: 'ADD_BELOW',
        img: Images.backward
    },
    {
        label: 'EDIT_RULE',
        img: Images.edit
    },
    {
        label: 'DELETE',
        img: Images.delete
    }
]

export const INPUT_OUPUT_FIELDS_TAB_LIST = [ "Input fields", "Output fields" ];

export const MICROFLOW_INITIAL_VALUES = { 
    name: '', 
    description: '',
    isAsync: false,
    initiateMessage: { enabled: false, message: "" },
    successMessage: { enabled: false, message: "" },
    errorMessage: { enabled : false, message : "" },
    callbackWebhookUrl: "",
    consumerNotification: false,
    userNotification: false,
    enablePolling : {
        enabled : false,
        triesCount : "",
        sleepTime : ""
    },
    screenBlocking: false,
    linkedMicroflow : {
        enabled : false,
        microflow : {}
    },
    saveToDBMap: [],
    saveToDB : false,
    excludeResponse: false,
    excludeResponseMap : [],
    popupHeading: ''
};

export const MICROFLOW_SETTINGS_INITIAL_VALUES = { 
    isAsync: false,
    initiateMessage: { enabled: false, message: "" },
    successMessage: { enabled: false, message: "" },
    errorMessage: { enabled : false, message : "" },
    callbackWebhookUrl: "",
    consumerNotification: false,
    enablePolling : {
        enabled : false,
        triesCount : "",
        sleepTime : ""
    },
    screenBlocking: false,
    linkedMicroflow : {
        enabled : false,
        microflow : {}
    },
    userNotification: false,
    saveToDBMap : [],
    excludeResponse: false,
    saveToDB: false,
    excludeResponseMap : [],
    popupHeading: ''
};

export const MICROFLOW_LISTING_HEADERS = [
    {
        key: 'name',
        label: 'Name',
        sort: true,
        width: "13%"
    },
    {
        key: '',
        label: '',
        width: "4%",
        description: true,
    },
    {
        key: 'microflowId',
        label: 'Microflow Id',
        sort: false,
        width: "20%"
    },
    {
        key: 'version',
        label: 'Version',
        width: "5%"
    },
    {
        key: 'responseType',
        label: 'Response Type',
        sort: false,
        width: "9%"
    },
    {
        key: 'modifiedBy.username',
        label: 'Modified By',
        isNested: true,
        width: "10%"
    },
    {
        key: 'modifiedTS',
        label: 'Modified on',
        isDate: true,
        sort: false,
        width: "8%"
    },
    {
        key: 'status',
        label: 'Status',
        width: '10%'
    },
    {
        key: '',
        label: 'Action',
        width: "8%"
    }
]

export const MICROFLOW_DRAFT_LISTING_HEADERS = [
    {
        key: 'name',
        label: 'Name',
        sort: true,
        width: "13%"
    },
    {
        key: '',
        label: '',
        width: "4%",
        description: true,
    },
    {
        key: 'microflowId',
        label: 'Microflow Id',
        sort: false,
        width: "20%"
    },
    {
        key: 'version',
        label: 'Version',
        width: "5%"
    },
    {
        key: 'responseType',
        label: 'Response Type',
        sort: false,
        width: "9%"
    },
    {
        key: 'modifiedBy.username',
        label: 'Modified By',
        isNested: true,
        width: "10%"
    },
    {
        key: 'modifiedTS',
        label: 'Modified on',
        isDate: true,
        sort: false,
        width: "8%"
    },
    {
        key: 'status',
        label: 'Status',
        width: '10%',
    },
    {
        key: '',
        label: 'Action',
        width: "8%"
    }
]

export const MICROFLOW_TABLE_ACTIONS = [
    {
        label: 'EDIT',
        img: Images.edit,
        value: 'Edit'
    },
    {
        label: 'COPY',
        img: Images.copy,
        value: 'Copy'
    },
    {
        label: 'DELETE',
        img: Images.delete,
        value: 'Delete'
    }   
]

export const MICROFLOW_TABLE_PUBLISHED_ACTIONS = [
    {
        label: 'EDIT',
        img: Images.edit,
        value: 'Edit'
    },
    {
        label: 'COPY',
        img: Images.copy,
        value: 'Copy'
    } 
]

export const MICROFLOW_TABLE_REVIEW_ACTION = [ 
    {
        label: 'EDIT',
        img: Images.edit,
        value: 'Edit'
    },
 ]

export const STEP_CONTAINER_TYPES = {
    DEFAULT: 'default',
    PASSED: 'passed',
    FAILED: 'failed'
};

export const APPLICANT_TYPES_FOR_RULES = [
    { value: "Skip for primary", label: "Skip for primary" },
    { value: "Skip for co-applicant", label: "Skip for co-applicant" }
]

export const OPERATOR_TYPES = [
    { value: "AND", label: "AND" },
    { value: "OR", label: "OR" }
]

export const FIELD_TYPES = [
    { value: "Product Fields", label: "Product Fields" },
    { value: "Documents", label: "Documents" },
    { value: "User Properties", label: "User Properties" },
    { value: "Lookups", label: "Lookups" },
]

export const ASSIGNMENT_TYPES = [
    { value: "equal", label: "===" },
    { value: "notEqual", label: "!==" },
    { value: "doesnotContain", label: "Does not Contain" },
    { value: "contains", label: "Contains" },
    { value: "in", label: "In" },
    { value: "notIn", label: "Not In" },
]

export const USER_PROPERTIES = [
    {
      type: "USER_PROPERTIES",
      key: "user_firstName",
      keyType: 'property',
      value: "First Name"
    }, {
      type: "USER_PROPERTIES",
      key: "user_lastName",
      keyType: 'property',
      value: "Last Name"
    }, {
      type: "USER_PROPERTIES",
      key: "user_username",
      keyType: 'property',
      value: "Username"
    }, {
      type: "USER_PROPERTIES",
      keyType: 'property',
      key: "user_email",
      value: "Email"
    }, {
      type: "USER_PROPERTIES",
      keyType: 'property',
      key: "user_teams",
      value: "Teams"
    }, {
      type: "USER_PROPERTIES",
      keyType: 'property',
      key: "user_roles",
      value: "Roles"
    },
    {
        type: "USER_PROPERTIES",
        keyType: 'attributes',
        key: "user_attributes.mobile",
        value: "Mobile"
    }
];

export const LookupFieldKey = {
    CATEGORY: "category",
    SUB_CATEGORY: "sub_category",
}

export const LookupOutputTypes = {
    DEFAULT: "ACTION_LOOKUP_DEFAULT_MATCH",
    LOOKUP: "ACTION_LOOKUP_ALL_MATCH"
}

export const LookupTypesOptions = [
    {
        value: LookupOutputTypes.DEFAULT,
        label: 'Lookup First Match (Autocomplete)'
    },
    {
        value: LookupOutputTypes.LOOKUP,
        label: 'Lookup All Match (Textbox, Dropdown, Radio)'
    }
];