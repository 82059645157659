import { Button, TextBox } from "components/generic";
import { isObject, map } from "lodash";
import { useEffect, useState } from "react";

export function FieldsCopyPopup({ requestField, closeOverlay }){

    const [ formValue, setFormValue ] = useState((requestField?.value));
    const initialArray = [
        {key: ""},{key: ""},{key: ""},{key: ""},{key: ""},
        {key: ""},{key: ""},{key: ""},{key: ""},{key: ""},
        {key: ""},{key: ""},{key: ""},{key: ""},{key: ""},
        {key: ""},{key: ""},{key: ""},{key: ""},{key: ""},
        {key: ""},{key: ""},{key: ""},{key: ""},{key: ""},
        {key: ""},{key: ""},{key: ""},{key: ""},{key: ""},
    ];

    useEffect(()=>{
        if(!requestField?.value) {
            setFormValue(initialArray);
        } else {
            setFormValue(JSON.parse(formValue));
        }
    },[]);

    const handleSelectedValue = (event, key, value) => {
        event = isObject(event)?event?.target?.value:event;
        value[key] = event;
        setFormValue(structuredClone(formValue));
    }

    const handleSave = () => {
        requestField.value = JSON.stringify(formValue);
        closeOverlay();
    }


    return (
        <div className="action-popup">
            <div className="action-popup__main">
                <header>FIELDS TO BE COPIED</header>
                { <section>
                    <h4>You can map up to 30 keys as given below for each selected co-applicant.System will consider the same key for each selected co-applicant</h4>
                    <h5>For example, if salaryIncome is mapped to Key 1 and 2 co-applicants are selected, then system will copy salaryIncome from both of the co-applicants</h5>
                    <h4>Key Name</h4>
                    {map(formValue,((field,index)=>
                        <div className="action-popup__row" key={index}>
                             <div className="action-popup__text">
                                Key {index+1}
                             </div>
                            <div className="action-popup__item">
                                <div className="action-popup__textbox"><TextBox placeholder="Key" type="text" onChange={(event)=>{handleSelectedValue(event,"key",field)}} value={field?.key}/></div>
                            </div>
                        </div>
                    ))}
                </section> }
            </div>
            <footer>
                <div className="action-popup__note"><strong>Note: </strong>Above keys will be resolved at server level (not from screen). Hence, the keys should be saved before invoking the action. Design your process accordingly.</div>
                <div className="action-popup__actions">
                    <Button label="CANCEL" handleClick={closeOverlay}/>
                    <Button label="SAVE" secondary={true} handleClick={handleSave}/>
                </div>
            </footer>
        </div>
    )
}