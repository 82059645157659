export const handleOutsideClick = (ref, handler) => {
    const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
            return;
        }
        handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
    };
}

export const truncate = (value, length) => {
    if(!length) length = 40;
    if(value?.length>length){
        return value?.substring(0,length-3)+'...';
    }
    return value;
}