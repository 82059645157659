import "./style.scss";
import { Images } from "assets/Images";
import classNames from "classnames";
import { isArray, map } from "lodash";
import { useEffect, useState, useRef } from "react";
import { INPUT_OUPUT_FIELDS_TAB_LIST } from "../../config";
import { useQuery } from '@tanstack/react-query';
import * as api from 'actions';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';
import { Checkbox, TextBox, Button, ToggleSwitch } from "components/generic";
import { toasterReducer } from "redux/reducers/toaster.reducer";
import { useDispatch } from "react-redux";
import { MicroflowActions } from "../../config/microflowAction";
import { ActionPopup } from "../microflow-popup-components/action-popup";
import { isObject } from "lodash";
import { ValuationPopup } from "../microflow-popup-components/valuation-popup";
import { PopulateMultidataPopup } from "../microflow-popup-components/populate-multidata-popup";
import { FieldsCopyPopup } from "../microflow-popup-components/fields-copy";
import { FilterCoapplicantsPopup } from "../microflow-popup-components/filter-coapplicants-popup";
import { DocumentArrayPopup } from "../microflow-popup-components/document-array-popup";
import { DataArrayPopup } from "../microflow-popup-components/data-array-popup";
import { LookupPopup } from "../microflow-popup-components/lookup-popup";

export function ConfigFields({ closeOverlay, selectedEntity, microflowData, disabled, containerData, nodeId }){

    const [ selectedTab, setSelectedTab ] = useState(INPUT_OUPUT_FIELDS_TAB_LIST[0]);
    const [ fieldsData, setFieldsData ] = useState(selectedEntity);
    const [ actionType, setActionType ] = useState("");
    const [ showOverlay, setShowOverlay ] = useState(false);
    const [ showLookupPopup, setShowLookupPopup ] = useState(false);
    const overlayRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(()=>{
        map(MicroflowActions,(action,ind)=>{
            if(action.name === containerData?.entities?.[0]?.name){
                if((!action.requestMap || action.requestMap?.length === 0) && action.responseMap ) {
                    setSelectedTab(INPUT_OUPUT_FIELDS_TAB_LIST[1]);
                }
                setActionType(action?.type);
                if(isArray(fieldsData) && fieldsData?.length === 0){
                    setFieldsData(action);
                    if(action.type === "ACTION_LOOKUP"){
                        setShowLookupPopup(true);
                    }
                }
            }
        });
        if((!fieldsData.requestMap || fieldsData.requestMap?.length === 0) && fieldsData.responseMap ) {
            setSelectedTab(INPUT_OUPUT_FIELDS_TAB_LIST[1]);
        }
    },[]);

    const handleChange = (event, key, field, isCheckbox) => {
        if(isCheckbox) {
            event = event?.target?.checked;
            field['static'] = event;
        } else {
            event = isObject(event)?event?.target?.value:event;
            field[key] = event;
        }
        setFieldsData(structuredClone(fieldsData));
    }

    const handleActionOnSuccess = (response) => {
        dispatch(toasterReducer({showToaster: true, message: response?.message, isError: response.code!==200}));
        closeOverlay();
    }

    const addNewRow = (responseMap) => {
        responseMap.push({key: "", value: "", static: false});
        setFieldsData(structuredClone(fieldsData));
    }

    const handleSaveEntity = () => {
        let mandatoryField = false;
        if( actionType === 'ADDITIONAL_ATTRIBUTES') {
            fieldsData.responseMap.forEach((data) => {
                if(data.key.length === 0) {
                    mandatoryField = true;
                }
            })
            if(!mandatoryField) {
                fieldsData.requestMap = JSON.parse(JSON.stringify(fieldsData.requestMap));
                fieldsData.requestMap.forEach((data) => {
                    data.key = data.value
                })
            } else {
                dispatch(toasterReducer({showToaster: true, message:"Fields are missing in Additional fields", isError: true}));
            }
        } else if(actionType === 'GET_APPLICATION_DETAILS_V4') {
            if(!fieldsData.requestMap[0].value || !(fieldsData.requestMap[0].value.toLowerCase()==='primary'|| fieldsData.requestMap?.[0].value.toLowerCase()==='all' || fieldsData.requestMap?.[0].value.toLowerCase()==='current')) {
                dispatch(toasterReducer({showToaster: true, message:"Applicant type should be either primary or all", isError: true}));
                mandatoryField = true;
            }
        } else if(actionType === 'CREATE_CHILD_APPLICATION_V4') {
            if(!fieldsData.requestMap[0].value) {
                dispatch(toasterReducer({showToaster: true, message:"Target product is mandatory", isError: true}));
                mandatoryField = true;
            }
        } else if(actionType === 'UPDATE_APPLICATIONS_V4') {
            if(!fieldsData.requestMap[0].value) {
                dispatch(toasterReducer({showToaster: true, message:"Target applicationId is mandatory", isError: true}));
                mandatoryField = true;
            }
        } else if(actionType === 'LOCK_MULTIDATA') {
            if(!fieldsData.requestMap[0].value && !fieldsData.requestMap[1].value) {
                dispatch(toasterReducer({showToaster: true, message:"Template Data and multidataFieldKey is mandatory", isError: true}));
                mandatoryField = true;
            }
            else if(!fieldsData.requestMap[0].value) {
                dispatch(toasterReducer({showToaster: true, message:"Template Data is mandatory", isError: true}));
                mandatoryField = true;
            }else if(!fieldsData.requestMap[1].value) {
                dispatch(toasterReducer({showToaster: true, message:"multidataFieldKey is mandatory", isError: true}));
                mandatoryField = true;
            }
        } else if(actionType === 'POPULATE_MULTIDATA') {
            if(!fieldsData.requestMap[0].value && !fieldsData.requestMap[1].value) {
                dispatch(toasterReducer({showToaster: true, message:"applicationId and TemplateId is mandatory", isError: true}));
                mandatoryField = true;
            }
            else if(!fieldsData.requestMap[0].value) {
                dispatch(toasterReducer({showToaster: true, message:"applicationId Data is mandatory", isError: true}));
                mandatoryField = true;
            }else if(!fieldsData.requestMap[1].value) {
                dispatch(toasterReducer({showToaster: true, message:"TemplateId is mandatory", isError: true}));
                mandatoryField = true;
            }else if(!fieldsData.requestMap[2].value) {
                dispatch(toasterReducer({showToaster: true, message:"Object Values are mandatory", isError: true}));
                mandatoryField = true;
            }
        } else if(actionType === 'PRIORITIZE_TASKS') {
            if(!fieldsData.requestMap[0].value && !fieldsData.requestMap[1].value && !fieldsData.requestMap[2].value) {
                dispatch(toasterReducer({showToaster: true, message:"Task name, priority and application id is mandatory", isError: true}));
                mandatoryField = true;
            }
            else if(!fieldsData.requestMap[0].value) {
                dispatch(toasterReducer({showToaster: true, message:"Task name is mandatory", isError: true}));
                mandatoryField = true;
            }
            else if(!fieldsData.requestMap[1].value) {
                dispatch(toasterReducer({showToaster: true, message:"Priority is mandatory", isError: true}));
                mandatoryField = true;
            }else if(!fieldsData.requestMap[2].value) {
                dispatch(toasterReducer({showToaster: true, message:"Application ID is mandatory", isError: true}));
                mandatoryField = true;
            }
        } else if(actionType === 'SAVE_TO_MULTIDATA') {
            if(!fieldsData.requestMap[0].value && !fieldsData.requestMap[1].value) {
                dispatch(toasterReducer({showToaster: true, message:"Fields and multidataRefkey is mandatory", isError: true}));
                mandatoryField = true;
            }
        } else if(actionType === 'POPULATE_COAPPLICANT_DATA_IN_MULTIDATA') {
            if(!fieldsData.requestMap[4].value) {
                dispatch(toasterReducer({showToaster: true, message:"Incremental is mandatory", isError: true}));
                mandatoryField = true;
            }  
        } else if(actionType === 'EXTRACT_MULTIDATA_FIELDS') {
            if(!fieldsData.requestMap[0].value && !fieldsData.requestMap[1].value && !fieldsData.requestMap[1].value && !fieldsData.requestMap[2].value) {
                dispatch(toasterReducer({showToaster: true, message:"Mandatory field is missing ", isError: true}));
                mandatoryField = true;
            }  
        } else if(actionType === 'MINIMUM_VALUATION') {
            if(!fieldsData.requestMap[0].value || !fieldsData.requestMap[1].value || !fieldsData.requestMap[2].value || !fieldsData.requestMap[3].value) {
                dispatch(toasterReducer({showToaster: true, message:"Mandatory field is missing", isError: true}));
                mandatoryField = true;
            }  
        }
        if(!mandatoryField){
            saveEntity();
        }
    }

    const handleDeleteRow = (responseMap, index) => {
        responseMap.splice(index,1);
        setFieldsData(structuredClone(fieldsData));
    }

    const handleSelectedValue = (event, value, key, isCheckbox) => {
        if(isCheckbox){
            value[key] = event?.target?.checked;
        } else {
            value[key] = event?.target?.value;
        }
        setFieldsData(structuredClone(fieldsData));
    }

    const fetchReqestResponseData = (data) => {
        setFieldsData({...fieldsData,...data})
    }

    const { refetch: saveEntity } = useQuery(['SAVE_ENTITY'], ()=> api.putMethodCall(ADMIN_API_AXIOS,`v2/microflow/${microflowData?.microflowId}/${microflowData.version}/${nodeId}`,{
        updateData: {
            requestMap: fieldsData?.requestMap,
            responseMap: fieldsData?.responseMap,
            parameterMap: fieldsData?.parameterMap,
        },
        modifiedTS: microflowData?.modifiedTS
    })
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleActionOnSuccess
    })

    return (
        <div className="config-fields">
        <header>
            <span className="config-fields__text">{fieldsData?.name}</span>
            <div className="config-fields__save">
                { !disabled && <>
                        <Button label="SAVE" secondary={true} handleClick={handleSaveEntity}></Button>
                        <span className="config-fields__seperator"></span>
                </>}
                <span><img src={Images.close} alt="" onClick={closeOverlay}/></span>
            </div>
        </header>
        <section>
            <div className="config-fields__tabs">
                { fieldsData?.requestMap?.length>0 && <span className={classNames({"config-fields__tabs--highlight": selectedTab===INPUT_OUPUT_FIELDS_TAB_LIST[0]})}  onClick={()=>setSelectedTab(INPUT_OUPUT_FIELDS_TAB_LIST[0])} style={{flex: fieldsData?.responseMap?.length===0?"inherit":""}}>{INPUT_OUPUT_FIELDS_TAB_LIST[0]}</span> }
                { (fieldsData?.responseMap?.length>0 || actionType==="ADDITIONAL_ATTRIBUTES") && <span className={classNames({"config-fields__tabs--highlight": selectedTab===INPUT_OUPUT_FIELDS_TAB_LIST[1]})} onClick={()=>setSelectedTab(INPUT_OUPUT_FIELDS_TAB_LIST[1])} style={{flex: fieldsData?.requestMap?.length===0?"inherit":""}}>{INPUT_OUPUT_FIELDS_TAB_LIST[1]}</span> }
            </div>
            { fieldsData?.requestMap?.length>0 && actionType!=="ADDITIONAL_ATTRIBUTES" && selectedTab===INPUT_OUPUT_FIELDS_TAB_LIST[0] && <div className="config-fields__main">
                <div className="config-fields__headers">
                    <span>Fields</span>
                    <span>Static</span>
                </div>
                {map(fieldsData?.requestMap,(requestField,ind)=>
                    <div className="config-fields__row" key={ind} style={{pointerEvents: disabled?"none":""}}>
                        <div className="config-fields__row--group">
                            { requestField?.key!=="completeConsumerStep" && <TextBox type="text" label={requestField?.key} mandatory={requestField?.required} value={requestField['value']} onChange={(event)=>{handleChange(event,"value",requestField)}}/> }
                            { ((requestField?.key==="Body" && actionType==="CALL_WEBHOOK")||((requestField?.key==="filterCoApplicant"||requestField?.key==="fieldsToBeCopied") && actionType==="POPULATE_COAPPLICANT_DATA_IN_MULTIDATA")||(requestField?.key==="mapFields" && actionType==="MINIMUM_VALUATION")||((requestField?.key==="filterCoApplicant"||requestField?.key==="fieldsToBeCopied") && actionType==="POPULATE_COAPPLICANT_DATA")||(requestField?.key==="object" && actionType==="POPULATE_MULTIDATA")||((requestField?.key==="documentArray"||requestField?.key==="dataArray") && actionType==="GET_APPLICATION_DETAILS_V4")) && <span className="config-fields__row--add"><img src={Images.addrow} alt="" onClick={()=>{overlayRef.current=requestField;setShowOverlay(requestField?.key)}}/></span> }
                            { (requestField?.key==="completeConsumerStep" && <ToggleSwitch label="completeConsumerStep" checked={requestField['value']?.toString()==="true"} onChange={(event)=>{handleChange(event, 'value', requestField)}}/> )}
                        </div>
                        <div>
                            { (requestField?.key!=="completeConsumerStep") && <Checkbox checked={requestField['static']} onChange={(event)=>{handleChange(event,requestField?.key,requestField,"checkbox")}}/> }
                        </div>
                    </div>
                )}
            </div>}
            { (fieldsData?.responseMap?.length>0 || actionType==="ADDITIONAL_ATTRIBUTES") && selectedTab===INPUT_OUPUT_FIELDS_TAB_LIST[1] && <div className="config-fields__main">
                { actionType === "ADDITIONAL_ATTRIBUTES" && <>
                    <div className="config-fields__static">Static</div>
                    {map(fieldsData?.responseMap,(responseField,inde)=>
                        <div className="config-fields__row" key={inde} style={{pointerEvents: disabled?"none":""}}>
                            <TextBox value={responseField?.key} mandatory={responseField?.required} type="text" onChange={(event)=>{handleSelectedValue(event,responseField,"key")}}/>
                            <span className="config-fields__colon">:</span>
                            <TextBox value={responseField?.value}  mandatory={responseField?.required}  type="text" onChange={(event)=>{handleSelectedValue(event,responseField,"value")}}/>
                            <Checkbox checked={responseField?.static} type="text" onChange={(event)=>{handleSelectedValue(event,responseField,"static","checkbox")}}/>
                            <span><img src={Images.delete} alt="" onClick={()=>{handleDeleteRow(fieldsData?.responseMap,inde)}}/></span>
                        </div>
                    )}
                    <div className="config-fields__btn" onClick={()=>{addNewRow(fieldsData?.responseMap)}} style={{pointerEvents: disabled?"none":""}}>
                        <img src={Images.blueadd} alt=""/>
                        <span>Add Field</span>
                    </div>
                </>}
                { actionType !== "ADDITIONAL_ATTRIBUTES" && <>
                    {map(fieldsData?.responseMap,(responseField,ind)=>
                    <div className="config-fields__row" key={ind} style={{alignItems:"end"}}>
                        <span className="config-fields__header">{responseField?.value}</span>
                        <div className="config-fields__row--group" style={{pointerEvents: disabled?"none":""}}>
                            <TextBox type="text" value={responseField['key']} onChange={(event)=>{handleChange(event,"key",responseField)}}  mandatory={responseField?.required} />
                        </div>
                    </div>
                )}
                </>}
            </div>}
        </section>
        { showOverlay && <div className="config-fields__overlay">
            <div className="config-fields__overlay--content align">
                { showOverlay === "Body" && <ActionPopup closeOverlay={()=>{setShowOverlay(false)}} requestField={overlayRef.current}/> }
                { showOverlay === "mapFields" && <ValuationPopup closeOverlay={()=>{setShowOverlay(false)}} requestField={overlayRef.current}/> }
                { showOverlay === "filterCoApplicant" && <FilterCoapplicantsPopup closeOverlay={()=>{setShowOverlay(false)}} requestField={overlayRef.current}/> }
                { showOverlay === "fieldsToBeCopied" && <FieldsCopyPopup closeOverlay={()=>{setShowOverlay(false)}} requestField={overlayRef.current}/> }
                { showOverlay === "object" && <PopulateMultidataPopup closeOverlay={()=>{setShowOverlay(false)}} requestField={overlayRef.current}/> }
                { showOverlay === "documentArray" && <DocumentArrayPopup closeOverlay={()=>{setShowOverlay(false)}} requestField={overlayRef.current}/> } 
                { showOverlay === "dataArray" && <DataArrayPopup closeOverlay={()=>{setShowOverlay(false)}} requestField={overlayRef.current}/> } 
            </div>
        </div>}
        { showLookupPopup && <div className="config-fields__overlay">
            <div className="config-fields__overlay--content align">
                <LookupPopup closeOverlay={()=>{setShowLookupPopup(false)}} fetchReqestResponseData={fetchReqestResponseData}/>
            </div>
        </div> }
    </div>
    )
}