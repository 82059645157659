import { Images } from 'assets/Images';
import { Pagination, Search, ConfirmationPopUp, ImageContainer, TabsListing } from 'components/generic';
import { useState, useContext, useRef } from 'react';
import AddConnectorPopup from '../add-connector-popup';
import Filter from '../filter';
import Table from '../table';
import './style.scss';
import { CONNECTOR_TABLE_HEADERS, CONNECTOR_TABLE_ACTIONS, CONNECTOR_TABLE_REVIEW_ACTION, CONNECTOR_ACTIONS, CONNECTOR_TABLE_PUBLISHED_ACTIONS, CONNECTOR_TABLE_DRAFT_HEADERS } from '../../config';
import { ConnectorContext } from 'providers';
import { TABS_LIST } from 'config';
import { useQuery } from '@tanstack/react-query';
import * as api from 'actions';
import * as apicall from 'actions/graphql';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';
import { toasterReducer } from 'redux/reducers/toaster.reducer';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { decrypt } from 'utils/crypto';

export default function ConnectorListing({ selectedTab, setSelectedTab }){
    const [ showAddConnectorPopUp, setShowAddConnectorPopUp ] = useState(false);
    const [ showConfirmationPopup, setShowConfirmationPopup ] = useState(false);
    const [ showFilterPopup, setShowFilterPopup ] = useState(false);
    const [ connectorListingData, setConnectorListingData ] = useState([]);
    const [ completeDeleteAction, setCompleteDeleteAction ] = useState(false);
    const connectorContext = useContext(ConnectorContext);
    const [ widgetData, setWidgetData ] = useState((window.screen.height>1500)?{
        currentPageNumber: 1,
        searchString: '',
        recordsPerPage: 12,
        sort: null
    }:((window.screen.height>1000)?{
        currentPageNumber: 1,
        searchString: '',
        recordsPerPage: 6,
        sort: null
    }:{
        currentPageNumber: 1,
        searchString: '',
        recordsPerPage: 6,
        sort: null
    }));
    const [ totalRecords, setTotalRecords ] = useState(0);
    const roles = decrypt(localStorage.getItem('_ud'))?.roles;
    const dispatch = useDispatch();
    const actionRef = useRef("");

    const handleSelectedAction = (actionName, index) => {
        switch(actionName){
            case 'EDIT':
                connectorContext.setSelectedConnectorDetail(connectorListingData[index]);
                connectorContext.setShowConnectorDetails(true);
                break;
            case 'COPY':
                actionRef.current = "COPY";
                connectorContext.setSelectedConnectorDetail(connectorListingData[index]);
                setShowAddConnectorPopUp(true);
                break;
            case 'DELETE':
                connectorContext.setSelectedConnectorDetail(connectorListingData[index]);
                setShowConfirmationPopup(true);
                break;
            case 'Download Template':
                downloadTemplate();
                break;
            case 'Filter':
                setShowFilterPopup(true);
                break;
            default:
                break;
        }
    }

    const handleDeleteOnSuccess = (response) => {
        if(response.code === 200){
            setCompleteDeleteAction(true);
            getConnectorListingData();
            getConnectorListingCount();
            dispatch(toasterReducer({showToaster: true, message: response?.message, isError: false}));
        } else {
            setShowConfirmationPopup(false);
            dispatch(toasterReducer({showToaster: true, message: response?.message, isError: true}));
        }
    }

    const handleDownload = (response) => {
        const blob = new Blob([response],  {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"});
        saveAs(blob, 'tempalte.xlsx');
    }

    const populateConnectorData = (response) => {
        setConnectorListingData(response?.data?.connector?.data ?? []);
    }

    const populateConnectorCount = (response) => {
        setTotalRecords(response?.data?.connector?.count ?? 0);
    }

    const handleSearch = (searchString) => {
        setWidgetData({...widgetData, searchString: searchString, currentPageNumber: 1});
    }

    const handlePagination = (pageNumber) => {
        setWidgetData({...widgetData, currentPageNumber: pageNumber})
    }

    const handleSort = () => {
        setWidgetData({...widgetData, sort: { name: widgetData?.sort?.name?-widgetData?.sort?.name:-1}})
    }

    const handleCloseAddConnectorPopup = () => {
        actionRef.current="";
        setShowAddConnectorPopUp(false);
        getConnectorListingData();
        getConnectorListingCount();
    }

    const { refetch: getConnectorListingData } = useQuery(['FETCH_CONNECTOR_LISTING_DATA', widgetData, selectedTab], ()=> apicall.getConnectorListingData('/v2/graphql',widgetData, selectedTab, false), { refetchOnWindowFocus: false, onSuccess: (data)=>populateConnectorData(data) })

    const { refetch: getConnectorListingCount } = useQuery(['FETCH_CONNECTOR_LISTING_COUNT', widgetData, selectedTab], ()=> apicall.getConnectorListingData('/v2/graphql',widgetData, selectedTab, true), { refetchOnWindowFocus: false, onSuccess: (data)=>populateConnectorCount(data) })

    const { refetch: downloadTemplate } = useQuery(['DOWNLOAD_TEMPLATE'], ()=> api.getMethodCall(ADMIN_API_AXIOS,'/v2/connectors/download-template'), { refetchOnWindowFocus: false, enabled: false, onSuccess: handleDownload });

    const { refetch: handleDelete, isDeleteActionFetching } = useQuery(['DELETE_CONNECTOR'], ()=> api.deleteMethodCall(ADMIN_API_AXIOS,`/v2/connectors/${connectorContext?.selectedConnectorDetail?.connectorId}/delete`), { refetchOnWindowFocus: false, enabled:false, onSuccess: handleDeleteOnSuccess });

    return (
        <div className="connector-listing">
            <header>
                <span><img src={Images.connectorIcon} alt=""/></span>
                <h4>Connector</h4>
                <Search searchFunction={handleSearch}/>
            </header>
            <div className="connector-listing__body">
                <div>
                    <TabsListing tabList={TABS_LIST} selectedTab={selectedTab} setSelectedTab={setSelectedTab} actionsList={CONNECTOR_ACTIONS} handleSelectedAction={handleSelectedAction}/>
                    { totalRecords > 0 && <>
                        <div className="connector-listing__table"><Table headers={selectedTab==='Draft'?CONNECTOR_TABLE_DRAFT_HEADERS:CONNECTOR_TABLE_HEADERS} actionsList={selectedTab==='Review'?CONNECTOR_TABLE_REVIEW_ACTION:(selectedTab==='Published'?CONNECTOR_TABLE_PUBLISHED_ACTIONS:CONNECTOR_TABLE_ACTIONS)} handleSelectedAction={handleSelectedAction} data={connectorListingData} selectedTab={selectedTab} handleSort={handleSort} widgetData={widgetData}/></div>
                    </> }
                </div>
                { totalRecords===0 && <ImageContainer label={widgetData?.searchString?'Currently, there are no connector with this name.':'Currently, there are no connector for you. Click on add button to create new one.'} placeholderImg={Images.nodata}/> }
                { totalRecords>0 && <Pagination {...widgetData} totalRecords={totalRecords} handlePagination={handlePagination}/> }
                { showFilterPopup && <div className="connector-listing__filter">
                    <span className="connector-listing__filter--close" onClick={()=>{setShowFilterPopup(false)}}><img src={Images.close2} alt=""/></span>
                    <Filter/>
                </div> }
            </div>
            { <span className="connector-listing__addbtn" onClick={()=>{connectorContext.setSelectedConnectorDetail({});setShowAddConnectorPopUp(true)}}><img src={Images.addBtn} alt=""/></span> }
            {showAddConnectorPopUp && <div className="connector-listing__popup">
                <div className="connector-listing__popup--content">
                    <AddConnectorPopup actionType={actionRef?.current} handleClosePopUp={()=>{handleCloseAddConnectorPopup()}}/>
                </div>
            </div>}
            { showConfirmationPopup && <ConfirmationPopUp label="Are you sure you want to delete connector?" isCompleted={completeDeleteAction} isInProgress={isDeleteActionFetching} inProgressLabel="Delete in progress" completedLabel="Deleted successfully" handleClose={()=>{setShowConfirmationPopup(false);setCompleteDeleteAction(false)}}  handleSubmit={()=>{handleDelete()}}/> }
        </div>
    )
}
