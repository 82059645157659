import './style.scss';
import { Images } from 'assets/Images';
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import * as moment from 'moment';
import { Tooltip } from 'components/generic';
import { truncate, handleOutsideClick } from 'utils';
import { useDispatch } from 'react-redux';
import { toasterReducer } from 'redux/reducers/toaster.reducer';

export default function Table({ headers, data, actionsList, handleSelectedAction, selectedTab, widgetData, handleSort }){

    const [ hoverData, setHoverData ] = useState(null);
    const [ showDescription, setShowDescription ]= useState(null);
    const dispatch = useDispatch();
    const ref = useRef();
    const clickRef = useRef(true);
    useOnClickOutside(ref, () => {
        if(showDescription){
            clickRef.current = false;
            return setShowDescription(false);
        } else {
            clickRef.current = true;
        }
    });

    function useOnClickOutside(ref, handler) {
        useEffect(() => {
            handleOutsideClick(ref, handler)
        }, [ref, handler]);
    }

    const handleCopy = (textData) => {
        navigator.clipboard.writeText(textData);
        dispatch(toasterReducer({showToaster: true, message: "Copied to clipboard Successfully", isError: false}));
    }

    const destructure = (value, nestedKey) => {
        const nestedKeysArrary = nestedKey.split('.');
        nestedKeysArrary?.forEach(key=>{
            if(value) value = value[key];
        })
        return value;
    }

    const handleDescription = (key) => {
        if(showDescription === key){
            setShowDescription(null);
        } else {
            if(clickRef.current) setShowDescription(key);
        }
        clickRef.current = true;
    }

    const tableHeader = (
        <thead>
            <tr>
                {map(headers,(header,ind)=>
                    { return (header.label!=='Status' || selectedTab!=='Published') && <th key={ind} style={{width: header?.width}}>
                        <span>{header?.label}</span>
                        { header?.sort && <span className="microflow-table__sort"><img src={Images.sort} alt="" className={classNames({"microflow-table__sort--rotate":widgetData?.sort?.name===1})}  onClick={handleSort}/></span> }
                    </th> } 
                )}
            </tr>
        </thead>
    );

    const tableData = (
        <tbody>
            {map(data,(el,inde)=>
                <tr key={inde}>
                    {map(headers,(header,ind)=>
                        <React.Fragment key={ind}>
                            { header.description && <td><span ref={ref}  className="microflow-table__description"><img src={Images.description} alt="" onClick={()=>{handleDescription(header?.key+inde)}}/></span>
                            { showDescription===(header?.key+inde) && <span className="microflow-table__tooltip" style={{width:"200px"}}><Tooltip background="#1F1E29" value={el['description']}/></span> }</td>}
                            { header.key && header.key!=='responseType' && header.label!=='Status' && header.label!=='Action' && !header.isDate && !header.isNested&& <td>
                                <span onMouseEnter={()=>setHoverData(header?.key+inde)} onMouseLeave={()=>setHoverData(null)}>{truncate(el[header?.key],40)||'-'}
                                { hoverData===(header?.key+inde) && header.key==='microflowId' && <span className="microflow-table__copy"><img src={Images.copy} alt="" onClick={()=>{handleCopy(el[header?.key])}}/></span> }
                                </span>
                                { hoverData===(header?.key+inde) && el[header?.key]?.length>40 && <span className="microflow-table__tooltip"><Tooltip value={el[header?.key]}/></span> }
                            </td> }
                            { header.key && header.key!=='responseType' && header.label==='Status' && selectedTab!=='Published' && <td>
                                <span className={classNames({"microflow-table__status":true, "draft": el[header.key]==='Draft',"submitted":el[header.key]==='Submitted',"rework": el[header.key]==='Rework',"pending": el[header.key]==='Pending'})}>{el[header?.key]}</span>
                            </td> }
                            { header.label==='Action' && <td>
                                {map(actionsList, (action,indexx)=>
                                    <span className="microflow-table__action" key={indexx}><img src={action.img} alt="" onClick={()=>handleSelectedAction(action.label,inde)}/></span>
                                )}
                            </td> }
                            { header.isDate && <td>
                                <span>{ moment(el[header?.key]).format('MM-DD-YYYY HH:mm') }</span>
                            </td>}
                            { header.isNested && <td><span>{destructure(el,header?.key)||'-'}</span></td>}
                            { header.key==='responseType' && <td><span>{el['isAsync']?'Async':'Sync'}</span></td>}
                        </React.Fragment>
                    )}
                </tr>
            )}
        </tbody>
    );

    return (
      <div className="microflow-table">
        <table>
            {tableHeader}
            {tableData}
        </table>
      </div>
    )
}
