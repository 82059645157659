export const MicroflowActionTypes = {
  GOTO : "GOTO",
  RULE : "RULE",
  PREVIEW_FILE : "PREVIEW_FILE",
  GENERATE_PDF : "GENERATE_PDF",
  GET_VALUE : "GET_VALUE",
  FETCH_APPLICATIONS : "FETCH_APPLICATIONS",
  GENERATE_REPORT : "GENERATE_REPORT",
  SEND_MAIL : "SEND_MAIL",
  UPDATE_APPLICATIONS : "UPDATE_APPLICATIONS",
  ACTION_LOOKUP : "ACTION_LOOKUP",
  FETCH_TASKS : "FETCH_TASKS",
  MARK_AS_COMPLETE : "MARK_AS_COMPLETE",
  IMPORT_CASES_EXCEL : "IMPORT_CASES_EXCEL",
  IMPORT_DATASET_EXCEL : "IMPORT_DATASET_EXCEL",
  DOWNLOAD_FLOW_DATA_TEMPLATE : "DOWNLOAD_FLOW_DATA_TEMPLATE",
  GET_APPLICATION_DETAILS : "GET_APPLICATION_DETAILS",
  CREATE_APPLICATION : "CREATE_APPLICATION",
  COMPLETE_STEP : "COMPLETE_STEP",
  APPLICATION_CANCEL : "APPLICATION_CANCEL",
  GENERATE_OTP : "GENERATE_OTP",
  VERIFY_OTP : "VERIFY_OTP",
  SEND_OTP : "SEND_OTP",
  MERGE_DOCUMENT : "MERGE_DOCUMENT",
  DOCUMENT_COPY : "DOCUMENT_COPY",
  AGGREGATE_FIELDS : "AGGREGATE_FIELDS",
  MERGE_DATA : "MERGE_DATA",
  DYNAMIC_RESUME_URL_GENERATOR : "DYNAMIC_RESUME_URL_GENERATOR",
  UAM_ADD_USER : "UAM_ADD_USER",
  NAME_MATCH : "NAME_MATCH",
  ADDRESS_MATCH : "ADDRESS_MATCH",
  UAM_GET_GROUPS_BY_NAME : "UAM_GET_GROUPS_BY_NAME",
  UAM_GET_USER_DETAILS : "UAM_GET_USER_DETAILS",
  UAM_UPDATE_USER_DETAILS : "UAM_UPDATE_USER_DETAILS",
  BULK_CONVERT_APPN_FROM_PRODUCT_TO_PRODUCT : "BULK_CONVERT_APPN_FROM_PRODUCT_TO_PRODUCT",
  FETCH_CUSTOMER_APPLICATION_COUNT : "FETCH_CUSTOMER_APPLICATION_COUNT",
  FETCH_CUSTOMER_QUERIES_COUNT : "FETCH_CUSTOMER_QUERIES_COUNT",
  REJECT_APPLICATION : "REJECT_APPLICATION",
  GENERATE_PDF_V4 : "GENERATE_PDF_V4",
  MERGE_DOCUMENT_V4 : "MERGE_DOCUMENT_V4",
  CREATE_APPLICATION_V4 : "CREATE_APPLICATION_V4",
  UPLOAD_BASE64 : "UPLOAD_BASE64",
  DOWNLOAD_BASE64 : "DOWNLOAD_BASE64",
  FETCH_FILES : "FETCH_FILES",
  SAVE_TO_DB : "SAVE_TO_DB",
  COVERT_ACCORDION_TO_MULTIDATA : "COVERT_ACCORDION_TO_MULTIDATA",
  POPULATE_COAPPLICANT_DATA_TO_PRIMARY : "POPULATE_COAPPLICANT_DATA_TO_PRIMARY",
  EPOCH_TO_UTC : "EPOCH_TO_UTC",
  CALL_WEBHOOK : "CALL_WEBHOOK",
  SET_DATE : "SET_DATE",
  SEND_SMS : "SEND_SMS",
  ADDITIONAL_ATTRIBUTES : "ADDITIONAL_ATTRIBUTES",
  SHORT_URL_GENERATOR : "SHORT_URL_GENERATOR",
  POPULATE_COAPPLICANT_DATA : "POPULATE_COAPPLICANT_DATA",
  POPULATE_COAPPLICANT_DATA_IN_MULTIDATA : "POPULATE_COAPPLICANT_DATA_IN_MULTIDATA",
  GET_APPLICATION_DETAILS_V4 : "GET_APPLICATION_DETAILS_V4",
  CREATE_CHILD_APPLICATION_V4 : "CREATE_CHILD_APPLICATION_V4",
  UPDATE_APPLICATIONS_V4 : "UPDATE_APPLICATIONS_V4",
  LOCK_MULTIDATA : "LOCK_MULTIDATA",
  POPULATE_MULTIDATA : "POPULATE_MULTIDATA",
  WRITE_PDF_V4 : "WRITE_PDF_V4",
  PRIORITIZE_TASKS : "PRIORITIZE_TASKS",
  SAVE_TO_MULTIDATA : "SAVE_TO_MULTIDATA",
  EXTRACT_MULTIDATA_FIELDS : "EXTRACT_MULTIDATA_FIELDS",
  SEND_BACK_CHILD_APPLICATION : "SEND_BACK_CHILD_APPLICATION",
  MINIMUM_VALUATION : "MINIMUM_VALUATION"
}
