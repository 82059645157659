import './style.scss';
import Switch from "react-switch";

export default function ToggleSwitch({ id, onChange, checked, disabled, label }){
    
    return (
        <div className="toggle">
            <Switch id={id} disabled={disabled} uncheckedIcon={false} checked={checked} onChange={(event)=>{onChange(event)}} boxShadow="0px 0px 4px 0px #00000040" 
            checkedIcon={false} height={15} width={30} handleDiameter={18} offColor="#9E9E9E" onColor="#8A92C0" offHandleColor="#FAFAFA" onHandleColor="#27368A" activeBoxShadow="0px 0px 4px 0px #00000040"/>
            { label && <label>{label}</label> }
        </div>
    )
}