import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import './style.scss';
import { Images } from 'assets/Images';
import * as api from 'actions';
import { encrypt } from 'utils/crypto';
import { ADMIN_API_AXIOS } from 'utils/axios-interceptor';


export default function LandingPage()  {
    const { REACT_APP_LOGIN_URL, REACT_APP_REDIRECT_URL } = process.env;
    const loginObj = { auth_code: '', redirect_uri: REACT_APP_REDIRECT_URL }
    const location = useLocation();
    const navigate = useNavigate();

    const loginOnSuccess = (response) => {
        response?.data?.access_token && localStorage.setItem('_ac', response?.data?.access_token);
        response?.data?.refresh_token && localStorage.setItem('_rc', response?.data?.refresh_token);
        response?.data?.sessionid && localStorage.setItem('_sc', response?.data?.sessionid);
        fetchUserDetails();
    }

    const handleUserDetailsOnSuccess = (response) => {
        let roleDetails = response?.data?.roles;
        roleDetails = roleDetails.find(role=>role.applicationId==="ADMIN_PORTAL");
        roleDetails = roleDetails?.roles?.map(role=>role?.name);
        const attributes = response?.data?.attributes ? response?.data?.attributes : [];
        const userDetails = { departmentList: response?.data?.departments, teamList: response?.data?.teams, attributes, roles: roleDetails, userId: response?.data?.userId };
        localStorage.setItem('_ud', encrypt(userDetails));
        if (response?.code === 200) {
            navigate('/home');
        }
    }

    const loginOnError = (response) => {
        console.log(response);
    }

    const { refetch: fetchLogin } = useQuery(['LOGIN'], ()=> api.postMethodCall(ADMIN_API_AXIOS,'/v4/auth/login', loginObj)
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: loginOnSuccess,
        onError: loginOnError
    })

    const { refetch: fetchUserDetails } = useQuery(['FETCH_USER_DETAILS'], ()=> api.getMethodCall(ADMIN_API_AXIOS,'/v4/user-details')
    , {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess: handleUserDetailsOnSuccess,
        onError: loginOnError
    })

    useEffect(() => {
        if(localStorage.getItem('_ac')){
            navigate('/home');
            return ;
        }
        if (location.search.includes('?state')) {
            const params = new URLSearchParams(location.search);
            loginObj.auth_code = params.get('code');
            fetchLogin();
        } else {
            window.location.href = REACT_APP_LOGIN_URL;
        }
    }, [location]);

    return (
        <div className="login">
            <div className="login__col">
                <div className="login__loading">
                    <img src={Images.login} alt=""/>
                    <h6>Hold on, we are trying to retrieve your data</h6>
                </div>
            </div>
        </div> 
    )
}