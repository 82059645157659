import './style.scss';
import { useEffect, useState } from 'react';
import { TABS_LIST } from 'config';
import { Listing } from './components/listing';
import { useParams } from 'react-router-dom';
import { FlowConfig } from './components/flow-config';

export default function IndesignPage(){
    const params = useParams();
    const [ selectedTab, setSelectedTab ] = useState(TABS_LIST[0]);
    const [ flowId, setFlowId ] = useState(null); 


    useEffect(()=>{
        setFlowId(params?.id);
    });

    return (
       <>
            { !flowId && <Listing selectedTab={selectedTab} setSelectedTab={setSelectedTab}/> }
            { flowId && <FlowConfig/> }
       </>
    )
}
